import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export default function useError() {
    const navigate = useNavigate();

    const handleError = useCallback((referenceCode, status, message) => {
        navigate(
            `/error?referenceCode=${referenceCode}&status=${status}&message=${encodeURIComponent(message)}&showAdditionalInfo=true`
        );
        console.error(`Error ${status}: ${message}`);
    }, [navigate]);

    return handleError;
}
