
import { Helmet } from "react-helmet";
import SettingsPage from "../components/Account/SettingsPage";
import Header from "../components/Header/Header";

const NotFoundPage = () => {
    return (
        <>
            <Helmet>
                <title>Account Settings</title>
            </Helmet>
            <Header/>
            <main>
                <SettingsPage/>
            </main>
        </>
    )
}

export default NotFoundPage