import Header from "../../components/Header/Header";
import React from "react";
import Classes from "../scss/NotFoundPage.module.scss";
import { Helmet } from "react-helmet";

const DataSubjectAccessRequestForm = () => {
    return (
        <>
            <Helmet>
                <title>Data Subject Access Request</title>
            </Helmet>
            <Header/>
            <main className={Classes.customMain}>
                <div className={Classes.pageWrapper}>
                    <div><strong>Data Subject Access Request</strong></div>
                    <br/>
                    <hr/>
                    <div>We are working on developing a online form for Data Subject Access Request. In the meantime, Contact <a href="mailto:support@myoverlap.com">support@myoverlap.com</a> for assistance.</div>
                </div>
            </main>
        </>
    )
}

export default DataSubjectAccessRequestForm;