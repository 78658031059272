export const AnalyticsButtonEvent = {
    // user clicking copy to clipboard button to copy/share a link
    LINK_COPY: "linkCopy",
    // user clicking QR code button to copy/share a link
    LINK_QR_SHARE: "linkQrCode",
    // user clicking shareVia button to copy/share a link
    LINK_SHARE_VIA: "linkShareVia"
};

export const AnalyticsApiEvent = {
    // user attempted to create a meeting
    MEETING_CREATION: "meetingCreation",
    MEETING_CREATION_WITH_DESCRIPTION: "meetingWithDescription",
    MEETING_AVAILABILITY_EDIT: "meetingAvailEdit",
};