import { Helmet } from 'react-helmet';
import AccountMenu from '../components/Account/AccountMenu';
import UserInfo from '../components/Account/UserInfo';
import Header from "../components/Header/Header";

const NotFoundPage = () => {
    return (
        <>
            <Helmet>
                <title>My Account</title>
            </Helmet>
            <Header/>
            <main>
                <UserInfo/>
                <AccountMenu/>
            </main>
        </>
    )
}

export default NotFoundPage