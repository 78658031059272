import FormAlertTemplate from './FormAlertTemplate';

function FormAlert(props) {
    return (
        <FormAlertTemplate borderColor={'#E1261A'} backgroundColor={'#F9E4E3'}>
            {props.children}
        </FormAlertTemplate>
    )
}

export default FormAlert;