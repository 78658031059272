import React from 'react';
import LongButton from '../Button/LongButton';

const LoginBtn = (props) => {
  return (
    <React.Fragment>
      <LongButton {...props}>
        Log In
      </LongButton>
    </React.Fragment>
  )
}

export default LoginBtn