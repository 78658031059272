import Classes from './scss/NotFoundPage.module.scss';
import Header from "../components/Header/Header";
import BubbleBackground from '../components/Ui/BubbleBackground/BubbleBackground';
import { Link, useSearchParams } from "react-router-dom";
import { getReasonPhrase } from 'http-status-codes';
import { useState, useRef } from 'react';
import PortalMask from '../HOC/PortalMask';
import toast, { Toaster } from 'react-hot-toast';
import { Helmet } from 'react-helmet';

function GenericErrorPage(props) {
    /**
     * Props:
     * - status (the html status code for the error)
     * - message (additional information about the error)
     * - referenceCode (a code that lets the devs see where the code came from)
     * - showAdditionalInfo
     */

    const [showReportModal, setShowReportModal] = useState(false);
    const reportInputRef = useRef(null);

    // eslint-disable-next-line no-unused-vars
    const [searchParams, setSearchParams] = useSearchParams();

    // get status code and text
    const status = props.statusCode ?? searchParams.get("status") ?? "418";
    let statusText;
    try {
        statusText = getReasonPhrase(status);
    } catch (e) {
        statusText = "¯\\_(ツ)_/¯";
    }

    // get additional info
    const showAdditionalInfo = props.showAdditionalInfo ?? searchParams.get("showAdditionalInfo") ?? false;
    const message = props.message ?? searchParams.get("message") ?? "No additional information provided.";
    const referenceCode = props.referenceCode ?? searchParams.get("referenceCode") ?? "########";

    function submitReport() {
        setShowReportModal(false); // close modal
        toast.success("Report submitted. Thanks for your feedback!", {
            duration: 2000,
        });

        // TODO: Update this to be more resilient
        const recipient = "karthik@myoverlap.com";
        const subject = "Overlap Error Report!";
        const bodyString = `
A user encountered an error on Overlap. Details:

Status Code: ${status}
Status Text: ${statusText}

System Message: ${message}
Reference Code: ${referenceCode}

User Message:
${reportInputRef.current.value}
        `;
        const body = encodeURIComponent(bodyString);
        window.open(`mailto:${recipient}?subject=${subject}&body=${body}`);
    }

    return (
        <>
            <Helmet>
                <title>{`Error ${status} - ${statusText}`}</title>
            </Helmet>
            <Header/>
            <BubbleBackground/>
            <main className={Classes.customMain}>
                <div className={Classes.wrapperWrapper}>
                    <div className={Classes.spacer}></div>
                    <div className={Classes.pageWrapper}>
                        <div className={Classes.contentWrapper}>
                            <div className={Classes.title}>{status}</div>
                            <div className={Classes.subtitle}>{statusText}</div>
                            <div className={Classes.message}>Sorry, but something's gone wrong. Try again later or report the error to our developers.</div>

                            {showAdditionalInfo &&
                                <div className={Classes.additionalInfoCard}>
                                    <div className={Classes.additionalInfoTitle}>Additional Info:</div>
                                    <div className={Classes.infoMessage}>{message}</div>
                                    <div className={Classes.infoMessage}>Reference Code: <b>{referenceCode}</b></div>
                                </div>
                            }

                            <Link to="/">
                                <div className={Classes.homeButton}>
                                    Go Home
                                </div>
                            </Link>
                            <div className={Classes.reportButton} onClick={() => {
                                setShowReportModal(true);
                            }}>Report</div>
                        </div>
                    </div>
                    <div className={Classes.spacer}></div>
                </div>
            </main>
            {showReportModal &&
                <PortalMask hide={() => setShowReportModal(false)}>
                    <div className={Classes.reportModal} onClick={(e) => e.stopPropagation()}>
                        <div className={Classes.reportModalTitle}>Report Error</div>
                        <div className={Classes.reportModalSubtitle}>Please provide a detailed description of what you were doing when the error occurred. Please include as much detail as possible. <b>Do not include any sensitive information!</b></div>
                        <textarea className={Classes.reportModalInput} placeholder="I was creating a new meeting titled 'Super Meeting' and clicked the submit button." ref={reportInputRef}></textarea>
                        <div className={Classes.reportModalButton} onClick={submitReport}>Submit</div>
                    </div>
                </PortalMask>
            }
            <Toaster
                position="bottom-center"
            />
        </>
    )
}

export default GenericErrorPage;