import { FiChevronRight } from 'react-icons/fi';
import Classes from './RowLinkButton.module.scss';

/**
 * A full width row with an icon on the left, text next to the icon, and a right arrow button on the right. The whole row is clickable.
 * Props:
 * - icon: The icon to display on the left.
 * - iconStyle: Additional classes to apply to the icon container (optional).
 * - text: The text to display.
 * - onClick: The function to call when the row is clicked.
 * 
 */
export default function RowLinkButton(props) {

    function handleOnClick(event) {

        if (props.onClick) {
            props.onClick(event);
        } else {
            event.stopPropagation();
            event.preventDefault();
        }
    }

    return (
        <div className={Classes.container} onClick={handleOnClick}>
            <div className={`${Classes.iconContainer} ${props.iconStyle ?? ''}`}>
                {props.icon}
            </div>
            <div className={Classes.text}>{props.text}</div>
            <div className={Classes.spacer}></div>
            <div className={Classes.arrowButton}>
                <FiChevronRight/>
            </div>
        </div>
    )
}