import ConfirmPopup from '../ConfirmPopup/ConfirmPopup';
import Classes from './DeletePopup.module.scss';

function DeletePopup(props) {
    /**
     * props:
     * - onCancel
     * - onDelete
     */

    return (
        <ConfirmPopup
            title="Delete meeting?"
            message="Are you sure you want to delete the meeting?"
            buttonText="Delete"
            buttonClass={Classes.deleteButton}
            onCancel={props.onCancel}
            onConfirm={props.onDelete}
        />
    )
}

export default DeletePopup;