import React from "react";
import Classes from "./FormProgressBar.module.scss";
import {FiChevronLeft} from "react-icons/fi";
import {useMeetingContext} from "../../../pages/NewMeetingPage";

const FormProgressBar = (props) => {

    const context = useMeetingContext();

    const getProgressBarWidth = () => {
        switch (context.state.activeStage) {
            case 0: return Classes.position0
            case 1: return Classes.position1
            case 2: return Classes.position2
            default: return Classes.position3
        }
    }
    
    return (
        <div className={Classes.progressBarContainer}>
            <div className={Classes.columnContainer}>
                <div className={Classes.stageNameRow}>
                    {context.stageNames.map((title, index) => {
                        return (
                            <span key={index} className={`${Classes.stageName} ${index === context.state.activeStage ? Classes.active : ''}`}>{title}</span>
                        )
                    })}
                </div>
                <div className={Classes.progressBarRow}>
                    <div className={Classes.dotsRow}>
                        {context.stageNames.map((title, index) => {
                            return (
                                <span className={Classes.dotContainer} key={index}>
                                    <span className={Classes.dot}></span>
                                </span>
                            )
                        })}
                    </div>
                    <div className={`${Classes.progressBar} ${getProgressBarWidth()}`}></div>
                </div>
            </div>
            { context.state.activeStage !== 0 &&
                <FiChevronLeft className={`${Classes.leftArrow}`} onClick={() => {
                    context.dispatch({type: "PREVIOUS_STAGE"});
                }}/>
            }
        </div>
    )
}

export default FormProgressBar;
