import { useEffect, useState } from 'react';
import Classes from './DefaultAvailability.module.scss'
import { useAuth } from 'context/authContext';
import FullPageLoader from 'components/loader/FullPageLoader';
import { FiChevronLeft } from 'react-icons/fi';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import DefaultButton from 'components/Ui/Button/DefaultButton';
import DefaultAvailabilityExplainerImage from 'assets/images/default_availability_explainer.svg';
import { applyDefaultAvailabilityToGridData, generateDefaultGrid, gridToDefaultAvailability } from 'utilities/dataProcessing';
import { AvailabilityContext } from 'components/AvailabilityCard/Subcomponents/AvailabilityContext';
import AvailabilityCard from 'components/AvailabilityCard/AvailabilityCard';
import useCreateAvailabilityContext from 'components/AvailabilityCard/Subcomponents/useCreateAvailabilityContext';
import toast from 'react-hot-toast';
import useError from 'utilities/error';
import { useUserSettings } from 'context/userSettingsContext';

export default function DefaultAvailability(props) {

    const authContext = useAuth();
    const navigate = useNavigate();
    const error = useError();
    const serverSettings = useUserSettings();
    const [queryParams,] = useSearchParams();
    
    const [defaultAvailabilityState, setDefaultAvailabilityState] = useState(false);
    const [showExplainer, setShowExplainer] = useState(null);
    /** @type {[import('../../utilities/dataProcessing').GridData, React.Dispatch<any>]}*/
    const [gridData, setGridData] = useState(undefined);

    async function handleSaveClicked(e) {
        e.preventDefault();

        // convert grid state into default availabilty object
        let defaultAvailability = gridToDefaultAvailability(gridData.intervalGrid, availabilityContext.selected, null);
        
        try {
            await serverSettings.setDefaultAvailability(defaultAvailability);
            toast.success('Default availability set');
            back();
        } catch (e) {
            toast.dismiss();
            toast.error('Something went wrong while trying to save your default availability.');
            console.error("Failed to set default availability: ", e);
        }
    }

    function back() {                      
        navigate(queryParams.get('return') ?? '/account/settings');
    }

    useEffect(() => {
        // get default availability from the server
        async function runAsync() {
            // update the default availability from the server
            try {
                setDefaultAvailabilityState(await serverSettings.defaultAvailability);
                setShowExplainer(await serverSettings.showDefaultAvailabilityExplainer);
            } catch (e) {
                error("DEATH GOOSE", 500, "Something went wrong while fetching your default availability.");
                console.error("Problem error that caused above redirect: ", e);
            }
        }

        runAsync();
    }, [authContext.currentUser, serverSettings, error])

    useEffect(() => {
        // whenever default availability changes, simply rerender the entire grid from scratch since it shouldn't happen after the page loads
        let gridData = generateDefaultGrid(30);
        gridData = applyDefaultAvailabilityToGridData(gridData, defaultAvailabilityState);
        setGridData(gridData);
    }, [defaultAvailabilityState])

    window.devRESETEXPLAINER = async () => {
        await serverSettings.setShowDefaultAvailabilityExplainer(true);
        setShowExplainer(true);
    }

    // const gridData = generateDefaultGrid(30);
    const availabilityContext = useCreateAvailabilityContext('EDIT', gridData);

    if (defaultAvailabilityState === undefined || showExplainer === null) {
        return (
            <FullPageLoader/>
        )
    }

    if (showExplainer || queryParams.get('DEVshowexplainer') === 'true') {
        return (
            <>
                <Helmet>
                    <title>{'Default Availability'}</title>
                </Helmet>
                <main className={Classes.noFooterMain}>
                    <div className={`${Classes.pageWrapper} ${showExplainer ? Classes.explainer : ''}`}>
                        <div className={Classes.backButtonRow}>
                            <div className={Classes.backButton} onClick={back}><FiChevronLeft/></div>
                            <div className={Classes.titleBox}>
                                <div className={Classes.title}>What is Default Availability?</div>
                            </div>
                            <div className={Classes.spacer}></div>
                        </div>

                        <div className={Classes.explainerContent}>
                            <div className={Classes.explainerText}>
                                Tell us when you're generally free, we'll auto-fill your polls accordingly
                            </div>
                            <div className={Classes.explainerImage}>
                                <img src={DefaultAvailabilityExplainerImage} alt="Default Availability"/>
                            </div>
                        </div>
                    </div>
                    <div className={Classes.buttonContainer}>
                            <div className={Classes.reassuranceText}>
                                Don't worry, you can still modify your availability for each Overlap poll before submitting
                            </div>
                            <DefaultButton
                                onClick={async () => {
                                    setShowExplainer(false);
                                    await serverSettings.setShowDefaultAvailabilityExplainer(false);
                                }}
                                text="Continue"
                                fullWidth={true}
                            />
                        </div>
                </main>
            </>
        )
    }

    return (
        <>
            <Helmet>
                <title>Default Availability</title>
            </Helmet>
            <AvailabilityContext.Provider value={availabilityContext}>
            <main className={Classes.noFooterMain}>
                <div className={Classes.pageWrapper}>
                    <div className={Classes.backButtonRow}>
                        <div className={Classes.backButton} onClick={back}><FiChevronLeft/></div>
                        <div className={Classes.titleBox}>
                            <div className={Classes.title}>Edit Default Availability</div>
                            <div className={Classes.subtitle}>Add your regular week's availability to fill out meeting polls faster</div>
                        </div>
                        <div className={Classes.spacer}></div>
                    </div>

                    <div className={Classes.availabilityCardContainer}>
                        { !gridData &&
                            <FullPageLoader/>
                        }
                        { gridData &&
                            <AvailabilityCard
                                data={gridData}
                                mode={'EDIT'}
                            />
                        }
                    </div>
                </div>
                <div className={`${Classes.buttonContainer} ${Classes.centerRight}`}>
                    <DefaultButton
                        onClick={handleSaveClicked}
                        text="Save"
                        hasShadow={true}
                        darker={true}
                    />
                </div>
            </main>
            </AvailabilityContext.Provider>
        </>
    )
}