import ChangeProfileInfo from '../components/Account/ChangeProfileInfo';
import UserInfo from '../components/Account/UserInfo';
import Header from "../components/Header/Header";

const NotFoundPage = () => {
    return (
        <>
            <Header/>
            <main>
                <UserInfo
                    showBackButton={true}
                    editMode={true}
                />
                <ChangeProfileInfo/>
            </main>
        </>
    )
}

export default NotFoundPage