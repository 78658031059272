import { DateTime } from 'luxon';
import { rawTimeZones } from "@vvo/tzdb";

/**
 * Returns the proper IANA name of the timezone the user is currently in,
 * i.e. "America/New_York", "Europe/London", etc.
 * @returns The IANA name of the user's timezone.
 */
export function getUserTimeZone() {
    return DateTime.local().zoneName;
}

/**
 * Takes in a timezone and returns the alternative name for that timezone.
 * @param {string} timezone - IANA timezone, e.g. "America/New_York"
 * @returns {string} - The alternative name for the timezone, e.g. "Eastern Time"
 */
export function getAlternativeName(timezone) {
    return rawTimeZones.filter(tz => tz.name === timezone).map(tz => tz.alternativeName)[0];
}

/**
 * Takes in a timezone and returns the abbreviation for that timezone.
 * @param {string} timezone - IANA timezone, e.g. "America/New_York"
 * @returns {string} - The abbreviation for the timezone, e.g. "EST"
 */
export function getAbbreviation(timezone) {
    return rawTimeZones.filter(tz => tz.name === timezone).map(tz => tz.abbreviation)[0];
}