import React, {useState, useEffect} from "react";
import Classes from "./MeetingList.module.scss";
import {useAuth} from "../../context/authContext";
import {constants} from "../../firebase";
import FullPageLoader from "../loader/FullPageLoader";
import {Link, useNavigate} from "react-router-dom";
import { FiMoreHorizontal, FiPlusSquare } from "react-icons/fi";
import DropDown from "../Ui/DropDown/DropDown";
import DropDownOption from "../Ui/DropDown/DropDownOption";
import { FiShare, FiEdit, FiUsers, FiTrash2, FiLogOut } from "react-icons/fi";
import useError from "../../utilities/error";
import DeletePopup from "../../components/Popup/Presets/DeletePopup";
import LeavePopup from "../../components/Popup/Presets/LeavePopup";
import { useUnhandledClick } from "../../utilities/clickHandling";
import SharePopup from "../Popup/SharePopup/SharePopup";
import MeetingHomePageImage from '../../assets/images/OverlapNoMeetingsGraphic.png';
import DefaultButton from "components/Ui/Button/DefaultButton";
import LongButton from "components/Ui/Button/LongButton";

const MeetingList = () => {
    // get information from url
    const searchQuery = new URLSearchParams(window.location.search);
    const recentlyJoinedMeetingID = searchQuery.get("joined");
    const devShowNone = searchQuery.get("devShowNone") === 'true';

    // get relavent information from context
    const authContext = useAuth();

    // define utility functions
    const error = useError();
    const navigate = useNavigate();

    // define state
    const [meetingResponse, setMeetingResponse] = useState(null);
    const [dropDownIndex, setDropDownIndex] = useState(-1); // set to -1 to indicate no dropdown is open, otherwise set to the index of the dropdown that is open
    const [showConfirmPopup, setShowConfirmPopup] = useState(null);

    // should be false when closed, an object containing the meeting url and the share text when open
    const [showSharePopup, setShowSharePopup] = useState(false);

    // fetch meeting list info from the server and populate it
    async function updateMeetings() {
        fetch(`${constants.functionsBaseUrl}/meeting`, {
            headers: {
                Authorization: `Bearer ${await authContext.currentUser.getIdToken()}`,
            },
        }).then(res => res.json()).then(res => {
            if (res.code === 200) {
                setMeetingResponse(res.data);
                return;
            }
            error('RACECAR', res.code, "Meeting list fetch returned an invalid response.");
        }).catch((e) => {
            console.error(e);
            error('FLEET', 500, "Failed to fetch meeting list.");
        });
    }

    // load meeting list on page load or when the user changes
    useEffect(() => {
        updateMeetings();
        // eslint-disable-next-line
    }, [authContext.currentUser]);

    // deletes a meeting with the specified meeting id
    async function deleteMeeting(meetingId) {
        // remove the popup and show loader
        setShowConfirmPopup(null);
        setMeetingResponse(null);
        
        fetch(`${constants.functionsBaseUrl}/meeting/${meetingId}`, {
            headers: {
                Authorization: `Bearer ${await authContext.currentUser.getIdToken()}`,
            },
            method: "DELETE"
        }).then(res => res.json()).then(res => {
            if (res.code === 200) {
                updateMeetings();
                return;
            }
            error('POMEGRANATE', res.code, "Meeting deletion returned an invalid response.");
        }).catch(err => {
            console.error(err);
            error('APPLE', 500, "An error occurred when deleting a meeting.");
        });
    }

    // leaves a meeting with the specified meeting id
    async function leaveMeeting(meetingId) {
        // remove the popup and show loader
        setShowConfirmPopup(null);
        setMeetingResponse(null);

        fetch(`${constants.functionsBaseUrl}/meeting/${meetingId}/participant/self`, {
            headers: {
                Authorization: `Bearer ${await authContext.currentUser.getIdToken()}`,
            },
            method: "DELETE"
        }).then(res => res.json()).then(res => {
            if (res.code === 200) {
                updateMeetings();
                return;
            }
            error('CODEBOOK', res.code, "Recieved an invalid response when attempting to leave a meeting.");
        }).catch(err => {
            console.error(err);
            error('FASTENER', 500, "An error occurred when leaving a meeting.");
        });
    }

    // if the user clicks outside of the page, close dropdowns
    useUnhandledClick(() => {
        setDropDownIndex(-1);
    })

    // RENDER
    // if there is no meeting list data, show a loading screen
    if (!meetingResponse) {
        return (
            <FullPageLoader message="Overlap is fetching the meeting list for you, It may take few seconds!"/>
        );
    }

    // if there are no meetings, show a message
    if (meetingResponse.length === 0 || devShowNone) {
        return (
            <div className={`${Classes.MeetingWrap} ${Classes.noMeetingsText}`}>
                <div className={Classes.titleBox}>
                    <div className={Classes.title}>Welcome {authContext.currentUser.displayName.split(" ")[0]}!</div>
                    <div className={Classes.subtitle}>No Meetings Created Yet</div>
                </div>
                <div className={Classes.imageContainer}>
                    <img src={MeetingHomePageImage} alt="People sitting on overlap bubbles, hanging out"/>
                </div>
                <DefaultButton
                    onClick={() => navigate("/newmeeting")}
                    text="Create a Meeting"
                />
                <div className={Classes.bottomSpacer}></div>
            </div>
        );
    }

    // get list of meetings that have been recently joined
    const recentlyJoinedMeetingInfo = recentlyJoinedMeetingID ? meetingResponse.find(meetingInfo => {
        return meetingInfo.id === recentlyJoinedMeetingID;
    }) : null;

    // render the meeting list
    return (
        <>
        <div className={Classes.MeetingWrap}>
            {/* header  */}
            <div className={Classes.MeetingHeader}>
                <div className={Classes.Title}>Meetings</div>
                <div className={Classes.Subtitle}>Tap a meeting for details</div>
                <LongButton className={Classes.NewMeetingButton} click={() => navigate("/newmeeting")}>
                    {<FiPlusSquare className={Classes.icon} />}
                </LongButton>
            </div>
            {/* meeting list  */}
            <div className={Classes.MeetingList}>
                {recentlyJoinedMeetingInfo &&
                    <div className="text-center rounded" style={{backgroundColor: "lightcoral"}}>
                        <p>You have recently enrolled your availability
                            in <strong>{recentlyJoinedMeetingInfo.title} </strong> 🎉, click <Link
                                to={`/meeting/${recentlyJoinedMeetingInfo.id}`}>here</Link> to
                            check the progress of that meeting.</p>
                    </div>
                }

                {meetingResponse.map((item, index) => {

                    const meetingInvite = `${window.location.origin}/t/invite?meeting=${item.id}`;

                    const isCreator = item.createdBy === authContext.currentUser.uid;

                    return (
                        <div key={index} className={Classes.Meeting} onClick={() => {
                            navigate(`/meeting/${item.id}`);
                        }}>
                            {/* member  */}
                            <div className={Classes.UserNum}>
                                <p>{item.mCount}</p>
                                <FiUsers className={Classes.User}/>
                            </div>

                            {/* name  */}
                            {/*<p className={Classess.Name}>{item.title}</p>*/}
                            <p className={Classes.Name}>{item.title}</p>

                            <div className={Classes.ElipsisButton} onClick={(event) => {
                                // if the dropdown is already open, close it
                                if (dropDownIndex === index) {
                                    setDropDownIndex(-1);
                                } else {
                                    setDropDownIndex(index);
                                }

                                event.stopPropagation(); // stop the event bubbling
                            }}>
                                <FiMoreHorizontal fontSize={25}/>
                            </div>
                            
                            { dropDownIndex === index &&
                                <div className={Classes.dropdownContainer} onClick={(event) => {
                                    event.stopPropagation();
                                }}>
                                    <DropDown>
                                        <DropDownOption
                                            text="Share"
                                            icon={<FiShare/>}
                                            onClick={() => {
                                                setShowSharePopup({
                                                    title: item.title,
                                                    url: meetingInvite
                                                })
                                                setDropDownIndex(-1);
                                            }}
                                        />
                                        <DropDownOption
                                            text="Edit"
                                            icon={<FiEdit/>}
                                            onClick={() => {navigate(`/editmeeting?meeting=${item.id}`)}}
                                        />
                                        {isCreator && <DropDownOption
                                            text="Delete"
                                            icon={<FiTrash2/>}
                                            onClick={() => {setDropDownIndex(-1); setShowConfirmPopup(["delete", item.id])}}
                                            className={Classes.deleteOption}
                                        />}
                                        {!isCreator && <DropDownOption
                                            text="Leave"
                                            icon={<FiLogOut/>}
                                            onClick={() => {setDropDownIndex(-1); setShowConfirmPopup(["leave", item.id])}}
                                            className={Classes.deleteOption}
                                        />}
                                    </DropDown>
                                </div>
                            }
                        </div>
                    );
                })}
            </div>
        </div>

        {/* Confirm Popup */}
        {showConfirmPopup && showConfirmPopup[0] === "delete" &&
            <DeletePopup
                onCancel={() => {setShowConfirmPopup(null)}}
                onDelete={() => deleteMeeting(showConfirmPopup[1])}
            />
        }
        {showConfirmPopup && showConfirmPopup[0] === "leave" &&
            <LeavePopup
                onCancel={() => {setShowConfirmPopup(null)}}
                onLeave={() => leaveMeeting(showConfirmPopup[1])}
            />
        }
        {showSharePopup !== false && 
            <SharePopup
                shareText={`You’re invited to join me for “${showSharePopup.title}”. Click here to add all times you’re available to meet.`}
                url={showSharePopup.url}
                onClose={() => setShowSharePopup(false)}
            />
        }
        </>
    );
};

export default MeetingList;
