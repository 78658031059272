import React from "react";
import {Form, Row, Col} from "react-bootstrap";
import MeetingStyle from "../../../../pages/scss/NewMeetingPage.module.scss";
import Classes from "./TimeRange.module.scss";
import TimeSelector from "./TimeSelector";

const TimeRange = (props) => {
    return (
        <div className={Classes.TimeRangeWrap}>
            <h1 className={MeetingStyle.title}>Adjust Time Range</h1>
            <Form className="shadow-card p-16 py-lg-16">
                <Form.Group>
                    <Row className={`g-3 ${Classes.Row}`}>
                        <Col className={Classes.InputWrap}>
                            <TimeSelector timeSegment={props.timeRange[0]} onTimeChange={(timeRange) => {
                                props.onTimeChange({timeRange: [timeRange, props.timeRange[1]]});
                            }}/>
                        </Col>
                        <Col className={Classes.InputWrap}>
                            <TimeSelector timeSegment={props.timeRange[1]} onTimeChange={(timeRange) => {
                                props.onTimeChange({timeRange: [props.timeRange[0], timeRange]});
                            }}/>
                        </Col>
                    </Row>
                </Form.Group>
            </Form>
            <p className="alert">{props.error}</p>
        </div>
    );
};

export default TimeRange;
