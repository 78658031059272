import Classes from './RightPopup.module.scss';
import PortalMask from "../../../HOC/PortalMask";

/**
 * Popup that appears full height at the right of the screen.
 * @param {*} props 
 * @returns 
 */
export default function RightPopup({onClose, noPadding, wrapperClass, noBlur=false, containerRef, children}) {

    return (
        <PortalMask noBlur={noBlur} hide={onClose}>
            <div className={`${Classes.popupContainer} ${noPadding ? Classes.noPadding : ''}`} onClick={e => e.stopPropagation()} ref={containerRef}>
                <div className={wrapperClass}>
                    {children}
                </div>
            </div>
        </PortalMask>
    )
}