
/**
 * Takes in two hex values and a number between 0 and 1 and interpolates between them. Returns the correct hex value. Used to decide shades of green in the overlap grid.
 * @author ChatGPT
 * @param {string} startHex The hex color of the start of the gradient
 * @param {string} endHex The hex color of the end of the gradient
 * @param {number} factor A number between 0 and 1 where 0 is startHex and 1 is endHex
 * @param {number} [exponent=2.5] The exponent to apply to the factor. Higher values will make the interpolation more biased towards the endHex.
 * @returns {string} The hex color that is the interpolation of startHex and endHex
 */
export function interpolateColor(startHex, endHex, factor) {
    // Convert a hex color to its RGB components
    function hexToRgb(hex) {
        const bigint = parseInt(hex.slice(1), 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;
        return [r, g, b];
    }

    // Convert RGB components to a hex color
    function rgbToHex(r, g, b) {
        return "#" + ((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1).toUpperCase();
    }

    // Extract RGB components from start and end hex colors
    const [r1, g1, b1] = hexToRgb(startHex);
    const [r2, g2, b2] = hexToRgb(endHex);

    // Rescale factor except when it's 1
    // const modifiedFactor = factor === 1 ? 1 : factor * 0.4;

    // Interpolate each RGB component
    let r = Math.round(r1 + factor * (r2 - r1));
    let g = Math.round(g1 + factor * (g2 - g1));
    let b = Math.round(b1 + factor * (b2 - b1));
    
    if (r < 0) r = 0;
    if (g < 0) g = 0;
    if (b < 0) b = 0;

    // Convert the interpolated RGB components back to hex
    return rgbToHex(r, g, b);
}