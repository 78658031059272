import { FiX } from 'react-icons/fi';
import Classes from './SelectablePersonBubble.module.scss';
import ProfilePicture from 'components/Utilities/ProfilePicture';

export default function SelectablePersonBubble({ image, name, onClick, className, style = {}, selected }) {
  return (
    <div className={`${Classes.bubble} ${className} ${selected ? Classes.selected : ''}`} onClick={onClick} style={style} title={name}>
        <ProfilePicture src={image} alt={name} className={Classes.image} />
        <div className={Classes.name}>{name}</div>
        <div className={Classes.spacer}></div>
        { selected &&
          <FiX className={Classes.closeIcon} size={16}/>
        }
    </div>
  )
}