import Header from "../components/Header/Header";
import Classes from './scss/NotFoundPage.module.scss';
import BubbleBackground from '../components/Ui/BubbleBackground/BubbleBackground';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const NotFoundPage = () => {
    return (
        <>
            <Helmet>
                <title>404 - Page Not Found</title>
            </Helmet>
            <Header/>
            <BubbleBackground/>
            <main className={Classes.customMain}>
                <div className={Classes.pageWrapper}>
                    <div className={Classes.contentWrapper}>
                        <div className={Classes.title}>404</div>
                        <div className={Classes.message}>Oops! We can't seem to find the page you're looking for.</div>
                        <Link to="/">
                            <div className={Classes.homeButton}>
                                Go Home
                            </div>
                        </Link>
                    </div>
                </div>
            </main>
        </>
    )
}

export default NotFoundPage