import { FiLoader, FiTrash2, FiX } from 'react-icons/fi';
import PortalMask from '../../HOC/PortalMask';
import Classes from './DeleteAccountPopup.module.scss';
import { toast } from 'react-hot-toast';
import {constants} from "../../firebase";
import {useAuth} from "../../context/authContext";
import useError from "../../utilities/error";
import {Link, useNavigate} from "react-router-dom";
import { useState } from 'react';

/**
 * A popup that allows the user to delete their account.
 * 
 * Props:
 * - onClose: a function that will be run whenever the popup is closed
 */
export default function DeleteAccountPopup(props) {
    const authContext = useAuth();
    const error = useError();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);

    async function handleDelete(e) {
        e.preventDefault();

        if (loading) return;

        toast.dismiss();
        setLoading(true);

        fetch(`${constants.functionsBaseUrl}/users/self/account`, {
            method: "DELETE",
            headers: {
                Authorization: `Bearer ${await authContext.currentUser.getIdToken()}`,
                "Content-Type": "application/json",
            }
        })
            .then((res) => res.json())
            .then((res) => {
                if (res.code === 200) {
                    toast.success('Account deleted.');
                    props.onClose();
                    setLoading(false);
                    return navigate('/logout');
                }
                error('RETIRE', res.code, 'Failed to delete account.');
            })
            .catch((err) => {
                console.error(err);
                setLoading(false);
                error('LEAVE', 500, 'Failed to delete account.');
            });
    }

    return (
        <PortalMask hide={props.onClose}>
            <div className={Classes.deleteAccountPopup} onClick={(e) => {e.stopPropagation()}}>
                <div className={Classes.topRow}>
                    <div className={Classes.icon}><FiTrash2/></div>
                    <div className={Classes.title}>Delete account</div>
                    <div className={Classes.spacer}></div>
                    <div className={Classes.backButton} onClick={props.onClose}><FiX/></div>
                </div>
                <div className={Classes.subtitle}>Can you please share why?</div>
                <textarea className={Classes.textArea} placeholder="Feedback (optional)"></textarea>
                <small>Keep in mind that you will not be able to re-activate your account or retrieve any of the content or information you have added.</small>
                <Link to={"/deletion-policy"} target={"_blank"}>Learn more about account deletion</Link>
                <div className={Classes.buttonRow}>
                    <div className={`${Classes.button} ${Classes.cancelButton}`} onClick={props.onClose}>Cancel</div>
                    <div className={`${Classes.button} ${Classes.deleteButton}`} onClick={handleDelete}>
                        { loading && <div className={Classes.loadingSpinner}><FiLoader/></div> }
                        { !loading && "Delete Account" }
                    </div>
                </div>
            </div>
        </PortalMask>
    )
}