import Classes from './MeetingBubble.module.scss';
import ProfilePicture from 'components/Utilities/ProfilePicture';

// this page is for the top bubble portion of the meeting intro popup
export default function MeetingBubble({ meetingInfo, gridInfo }) {
    const leader = gridInfo.members.find(user => user.role === 0);
    return (
        <div className={Classes.titleBubble}>
            <div className={Classes.topRow}>
                <div className={Classes.titleText}>{meetingInfo.title}</div>
            </div>
            <div className={Classes.personWrapper}>
                <div className={Classes.person}>
                    {leader && (
                        <div>
                            <ProfilePicture className={Classes.profilePicture} src={leader.profileUrl} alt="" />
                            <div className={Classes.textWrapper}>
                                <span className={Classes.basicText}> Led by </span>
                                <span className={Classes.usernameText}> {leader.displayName} </span>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}