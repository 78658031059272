import React, {useState} from "react";
import getLogo from "../utilities/getLogo";
import Classes from './scss/LoginSignUp.module.scss';
import {FiChevronRight, FiInfo} from "react-icons/fi";
import {Link, useNavigate} from "react-router-dom";
import {useAuth} from "../context/authContext";
import GoogleBtn from "../components/Ui/LoginWithBtn/Google/GoogleBtn";
import Header from "../components/Header/Header";
import {useSearchParams} from "react-router-dom";
import {Form} from "react-bootstrap";
import LineText from "../components/Ui/LineText/LineText";
import useError from "../utilities/error";
import FormAlert from "../components/Ui/FormAlert/FormAlert";
import { useVerify, verifyAll, getErrorMessageList } from '../utilities/verification';
// import FacebookBtn from "../components/Ui/LoginWithBtn/Facebook/FacebookBtn";
import SignInWithGoogleNotAvailabilePopup from "../components/Popup/BottomPopup/SignInWithGoogleNotAvailabilePopup";
import { Helmet } from "react-helmet";

const LoginPage = () => {
    const authContext = useAuth();
    const [searchParams] = useSearchParams();
    const error = useError();
    const [errorMessages, setErrorMessages] = useState([]);
    const [showBrowserPopup, setShowBrowserPopup] = useState(false);

    const navigate = useNavigate();

    const [emailRef, emailField] = useVerify();
    const [passwordRef, passwordField] = useVerify();

    emailField.notEmpty("Email is required.").validEmail("Email is invalid.");
    passwordField.notEmpty("Password is required.");

    const from = searchParams.get("from") ?? 'default';
    const redirectUrl = searchParams.get('redirect') ?? '/home';

    // is in app browser
    const standalone = window.navigator.standalone;
    const userAgent = window.navigator.userAgent.toLowerCase();
    const safari = /safari/.test( userAgent );
    const ios = /iphone|ipod|ipad/.test( userAgent );

    const isInAppBrowser = !standalone && !safari && ios;

    function updateErrorMessageList() {
        setErrorMessages(getErrorMessageList([emailField, passwordField]));
    }

    async function logIn(e) {
        e.preventDefault();
        // TODO : punch loader

        // do input verification
        // eslint-disable-next-line no-unused-vars
        let [verificationSuccess, errorMessages] = await verifyAll([emailField, passwordField]);
        if (!verificationSuccess) {
            updateErrorMessageList();
            return;
        }

        try {
            await authContext.logIn(emailField.value, passwordField.value);
            navigate(redirectUrl);
        } catch (e) {
            // is the code from firebase?
            if (e.code) {
                // sort through expected errors
                // eslint-disable-next-line default-case
                switch (e.code) {
                    case 'auth/user-not-found':
                        emailField.setInvalidState("No account found with that email.");
                        updateErrorMessageList();
                        return;
                    case 'auth/wrong-password':
                        passwordField.setInvalidState("Incorrect password.");
                        updateErrorMessageList();
                        return;
                }
            }

            // error is unknown and unexpected
            console.error(e);
            error('CONSOLE', 500, 'An error occurred while attempting to log in.');
        }
        // TODO : release loader
    }

    async function logInWithDelegate(delegate) {
        // TODO : punch loader
        try {
            await delegate();
        } catch (e) {
            // check if error has code issued by firebase
            if (e.code === 'auth/popup-closed-by-user') {
                // user closed the popup, don't freak out, it's okay
                return;
            }

            console.error(e);
            error('SCORPION', 500, 'An error occurred when awaiting delegate.');
        }
        // TODO : release loader
    }

    async function logInWithGoogle(e) {
        if (isInAppBrowser) {
            e.preventDefault();
            setShowBrowserPopup(true);
            return;
        }
        else await logInWithDelegate(authContext.logInUsingGoogle);
    }

    // async function logInWithFacebook(_) {
    //     await logInWithDelegate(authContext.logInUsingFacebook);
    // }

    return (
        <>
            <Helmet>
                <title>Log In to Overlap</title>
            </Helmet>
            <Header/>
            <main>

                <div className={Classes.formWrap}>
                    <div>
                        <div className={Classes.logoWrap}>
                            Welcome to
                            <img className={Classes.Logo} src={getLogo()} alt="Overlap Logo"/>
                        </div>
                    </div>
                    <div className={Classes.formContainer}>
                        {/* { isInAppBrowser && <FacebookBtn click={logInWithFacebook}/> } */}

                        <GoogleBtn click={logInWithGoogle}/>

                        <LineText className={Classes.lineText}>Or</LineText>

                        <Form className={Classes.loginForm}>
                            <Form.Group>
                                <Form.Control
                                    ref={emailRef}
                                    className=" round-element bg-light-gray-btn border-0 mb-3"
                                    type="email"
                                    placeholder="Email"
                                />
                                <Form.Control
                                    ref={passwordRef}
                                    className=" round-element bg-light-gray-btn border-0  "
                                    type="password"
                                    placeholder="Password"
                                />
                            </Form.Group>
                            { errorMessages.length > 0 &&
                                <Form.Group style={{marginTop: '16px'}}>
                                    <FormAlert>
                                        {errorMessages[0]}
                                    </FormAlert>
                                </Form.Group>
                            }
                            <Form.Group
                                className={Classes.bottomRow}>
                                <Link to={`/passreset`} className={`text-12px ${Classes.forgotPasswordText}`}>
                                    Forgot password?
                                </Link>
                                <Link>
                                    <button onClick={logIn} type="submit" className={`${Classes.loginButton}`}>
                                        Log in{" "}
                                        <FiChevronRight fontSize={20}/>
                                    </button>
                                </Link>
                            </Form.Group>
                        </Form>
                    </div>
                </div>
                <div className={Classes.signUpTextContainer}>
                    Don't have an account yet?
                    <Link to={`/signup?from=${from}&redirect=${encodeURIComponent(redirectUrl)}`} className={Classes.signUpButton}>Sign Up</Link>
                </div>

                { from === "recipient" &&
                    <div className={Classes.popupContainer}>
                        <div className={Classes.popup}>
                            <FiInfo fontSize={20}/>
                            <div className={Classes.popupText}>
                                Please Sign In or Sign Up to Continue
                            </div>
                        </div>
                    </div>
                }
            </main>
            { showBrowserPopup && 
                <SignInWithGoogleNotAvailabilePopup
                    onClose={() => setShowBrowserPopup(false)}
                />
            }
        </>
    );
};

export default LoginPage;
