import React, { useEffect, useState } from "react";
import "./Header.scss";
// import BrandLogo from "../../assets/images/logo.png";
import getLogo from "../../utilities/getLogo";
import {Navbar, NavItem, Nav} from "react-bootstrap";
import {Link} from "react-router-dom";
import {useAuth} from "../../context/authContext";
import { FiMenu } from "react-icons/fi";
import RightPopup from "components/Popup/RightPopup/RightPopup";

const Header = (props) => {
    const authContext = useAuth();

    const navUrl = [
        {title: "home", url: "home"},
        {title: "about us", url: "aboutus"},
        {title: "new meeting", url: "newmeeting"},
        {title: "account", url: "account"},
    ];

    const welcomeNavUrl = [
        {title: "about us", url: "aboutus"},
        {title: "log in", url: "login"},
        {title: "sign up", url: "signup"},
    ];

    const [showRightPopup,setShowRightPopup] = useState(false);

    //function for handling resize
    const handleResize = () => {
        if (window.innerWidth > 992) {
            setShowRightPopup(false);
        }
    }

    //event listener
    useEffect(() => {
        window.addEventListener('resize', handleResize);

        //initial check
        handleResize();
        
        return () => {
            window.removeEventListener('resize',handleResize);
        }
    })

    return (
        <header className={`header ${props.className || ''}`}>
            <Navbar className=" px-4 ">
                <div className=" w-100 d-flex justify-content-between align-items-center max-container ">
                    {/* <Link to={"/home"} className=" brand_logo d-lg-none ">
                        <div className=" ">
                            <img className=" w-100 " src={BrandLogo} alt="Brand Logo"/>
                        </div>
                    </Link> */}
                    <Link to={'/'} className=" navbar-brand me-0 ">
                        <img className=" w-100" src={getLogo()} alt="Brand Logo"/>
                    </Link>
                    {/* navbar  */}
                    {authContext.currentUser &&
                        <Nav className=" d-none d-lg-flex">
                            {navUrl.map((link, index) => {
                                return (
                                    <NavItem key={index}>
                                        <Link to={`/${link.url}`} className="text-capitalize nav-link">
                                            {link.title}
                                        </Link>
                                    </NavItem>
                                );
                            })}
                        </Nav>
                    }
                    {!(authContext.currentUser) &&
                        <>
                            <Nav className=" d-none d-lg-flex">
                                {welcomeNavUrl.map((link, index) => {
                                    return (
                                        <NavItem key={index}>
                                            <Link to={`/${link.url}`} className="text-capitalize nav-link">
                                                {link.title}
                                            </Link>
                                        </NavItem>
                                    );
                                })}
                            </Nav>
                            <div className="options">
                                <FiMenu className='menu' onClick={() => setShowRightPopup(!showRightPopup)}/>
                            </div>
                        </>
                    }
                    {showRightPopup && (
                        <RightPopup
                            onClose={() => setShowRightPopup(false)}
                            children = {
                                <div className="rightPopup">
                                    <FiMenu 
                                        className="insidemenu" 
                                        onClick={() => setShowRightPopup(!showRightPopup)}
                                    />
                                    {welcomeNavUrl.map((link, index) => {
                                        return (
                                            <NavItem key={index}>
                                                <Link to={`/${link.url}`} className="text-capitalize nav-link">
                                                    {link.title}
                                                </Link>
                                            </NavItem>
                                        );
                                    })}
                                </div>
                            }
                        />
                    )}
                </div>
            </Navbar>
        </header>
    );
};

export default Header;
