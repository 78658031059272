import { Link } from 'react-router-dom';
import Header from '../../Header/Header';
import Classes from '../../../pages/scss/NotFoundPage.module.scss';

export default function MeetingQuotaMet() {
    return (
        <>
            <Header/>
                <div className={Classes.pageWrapper}>
                    <div className={Classes.contentWrapper}>
                        <div className={Classes.title}>Meeting Limit Reached</div>
                        <div className={Classes.message}>You have reached the maximum number of meetings you can create. Please delete some meetings from the home page.</div>
                        <Link to="/">
                            <div className={Classes.homeButton}>
                                Home
                            </div>
                        </Link>
                    </div>
                </div>
        </>
    )
}