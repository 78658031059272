import React from "react";
import Classes from './MeetingName.module.scss';
import {useMeetingContext} from "../../../pages/NewMeetingPage";
import FormAlert from "../../Ui/FormAlert/FormAlert";

const MeetingName = (_props) => {
    const context = useMeetingContext(); // meeting context

    function handleNameInput(e) {
        context.dispatch({type: "MEETING_NAME_UPDATE", payload: {
            name: e.target.value,
            description: context.state.meetingDescription
        }})
    }

    function handleDescriptionInput(e) {
        context.dispatch({type: "MEETING_NAME_UPDATE", payload: {
            name: context.state.meetingTitle,
            description: e.target.value,
        }})
    }

    return (
        <div className={`${Classes.contentCard} `}>
            <div className={`${Classes.formCard}`}>
                <input
                    name="name"
                    type="text"
                    placeholder="Meeting Name"
                    onChange={handleNameInput}
                    onInput={handleNameInput}
                    onBlur={handleNameInput}
                    value={context.state.meetingTitle}
                    className={`${Classes.nameInput} ${Classes.formInput} ${context.state.revealErrors ? Classes.invalid : null}`}
                />
                <textarea
                    name="description"
                    type="text"
                    placeholder="Description (optional)"
                    onChange={handleDescriptionInput}
                    onInput={handleDescriptionInput}
                    onBlur={handleDescriptionInput}
                    value={context.state.meetingDescription}
                    className={`${Classes.descriptionInput} ${Classes.formInput}`}
                />
            </div>
            {context.state.revealErrors && (
                <div className={Classes.alertContainer}>
                    <FormAlert>
                        Please enter a meeting name
                    </FormAlert>
                </div>
            )}
        </div>
    );
};
export default MeetingName;
