import React from "react";
import Styles from "./FacebookBtn.module.scss";
import LongButton from "../../Button/LongButton";
import {FaFacebookSquare} from "react-icons/fa";



const FacebookBtn = (props) => {
    return (
        <LongButton
            click={props.click}
            className={` d-flex align-items-center justify-content-center bg-white ${Styles.googleBtn}`}
        >
            {" "}
            <FaFacebookSquare className=" me-2" size={20} /> Continue with
            Facebook
        </LongButton>
    );
};

export default FacebookBtn

