import FlexContainer from "components/Utilities/FlexContainer";
import OverlapText from "components/Utilities/OverlapText";
import PositionedContainer from "components/Utilities/PositionedContainer";
import { FiChevronDown, FiChevronUp, FiSearch, FiX } from "react-icons/fi";
import Classes from './FilterPopup.module.scss';
import { useEffect, useRef, useState } from "react";
import SmallButton from "components/Ui/Button/SmallButton";
import SelectablePersonBubble from "components/Ui/MeetingComponents/SelectablePersonBubble";
import IconSearchBar from "components/Ui/InputElements/IconSearchBar";
import { useOutsideClick } from "utilities/clickHandling";
import ProfilePicture from "components/Utilities/ProfilePicture";
import { useScrollSize } from "utilities/useSize";
import { useHeightSpacer } from "utilities/spacing";

export default function FilterPopup({ users, onClose, onFilterChange = (filtered) => {console.log('Filter change!', filtered)} }) {

    const MAXHEIGHT = 100;

    const [collapsed, setCollapsed] = useState(false); // when true, shows the filter collapsed view
    const [selectedUsers, setSelectedUsers] = useState(null); // state that stores all the users
    const [showMoreView, setShowMoreView] = useState(false); // when true, shows the full filter view
    const [searchText, setSearchText] = useState(''); // the text in the search bar

    const scrollViewRef = useRef(null); // reference to the scroll view
    const popupRef = useRef(null); // reference to the popup itself

    const scrollViewSize = useScrollSize(scrollViewRef); // size of the scroll view
    const overheight = scrollViewSize.height > MAXHEIGHT; // whether the scroll view is over the max height

    const spacerHeight = useHeightSpacer(popupRef, 16); // height of the bottom spacer (16px padding)

    // The use of the reference stops automatic updates back to the parent on the first render.
    // basically, onFilterChange updates are only sent when the user actually selects something,
    // not automatically
    const firstTimeRef = useRef(true);

    // on loading of the component, set the selected users to no users
    useEffect(() => {
        let selectedUsers = {}; // create a new empty object
        users.forEach(user => { // add each user to the object with a value of false
            selectedUsers[user.uid] = false;
        })
        setSelectedUsers(selectedUsers); // update the user state to display the new selections
    }, [users]);

    // when the selected users change, update the parent component
    useEffect(() => {
        if (selectedUsers === null) { return; }
        if (firstTimeRef.current) {
            firstTimeRef.current = false;
            return;
        }
        let filteredUsers = [];
        for (let uid in selectedUsers) {
            if (selectedUsers[uid]) {
                filteredUsers.push(uid);
            }
        }
        onFilterChange(filteredUsers);

    }, [selectedUsers, onFilterChange]);

    // const numOfUsers = selectedUsers ? Object.keys(selectedUsers).length : 0;
    const numOfFiltered = selectedUsers ? Object.values(selectedUsers).filter(selected => selected).length : 0;

    // when the user clicks outside the popup, close the popup or collapse it if there are users selected
    useOutsideClick(popupRef, () => {
        if (numOfFiltered === 0) {
            onClose();
        } else {
            setCollapsed(true);
        }
    })

    // call onClose when the component is unmounted
    useEffect(() => {
        return onClose;
    }, [onClose])

    // Called by the clear button to clear all selections
    function clearSelection() {
        let newSelected = {};
        for (let uid in selectedUsers) {
            newSelected[uid] = false;
        }
        setSelectedUsers(newSelected);
    }

    // Called by the select all button to select all users
    function selectAll() {
        let newSelected = {};
        for (let uid in selectedUsers) {
            newSelected[uid] = true;
        }
        setSelectedUsers(newSelected);
    }

    return (
        <>
            <div className={`${Classes.wrapperClass} ${collapsed ? '' : Classes.expanded}`} onClose={() => {
                setCollapsed(true);
            }} ref={popupRef}>

                { collapsed &&
                    <>
                        <FlexContainer direction="row" horizontalAlign="left" verticalAlign="middle" fullWidth={true} gap={8} className={Classes.collapsedContainer} onClick={() => {
                            setCollapsed(false);
                        }}>
                            <SmallButton text="Clear" color="gray" onClick={() => {
                                clearSelection();
                                setCollapsed(false);
                            }}/>
                            <FlexContainer direction="row" horizontalAlign="left" verticalAlign="middle" className={Classes.collapsedRow}>
                                <OverlapText size={16} color="primary">Filter</OverlapText>
                                { Object.keys(selectedUsers).map((uid, index) => {
                                    const user = users.find(user => user.uid === uid);
                                    if (selectedUsers[uid]) {
                                        return <ProfilePicture src={user.profileUrl} alt={user.displayName} key={index} className={Classes.collapsedImage}/>
                                    }
                                    return null;
                                })}
                            </FlexContainer>
                            <div style={{flexBasis: '32px', flexShrink: 0}}></div>
                            <PositionedContainer location="northeast">
                                <FiChevronUp className={Classes.closeButton} onClick={() => {setCollapsed(false)}}/>
                            </PositionedContainer>
                        </FlexContainer>
                    </>
                }
                { !collapsed &&
                    <>
                        {/* Top Left Button */}
                        <PositionedContainer location="northwest">
                            { numOfFiltered > 0 &&
                                <SmallButton text="Clear" color="gray" className={Classes.clearButton} onClick={clearSelection}/>
                            }
                            { numOfFiltered === 0 &&
                                <SmallButton text="Select All" color="gray" className={Classes.clearButton} onClick={selectAll}/>
                            }
                        </PositionedContainer>

                        {/* Top Right Button */}
                        <PositionedContainer location="northeast">
                            { numOfFiltered === 0 &&
                                <FiX className={Classes.closeButton} onClick={onClose}/>
                            }
                            { numOfFiltered > 0 &&
                                <FiChevronDown className={Classes.closeButton} onClick={() => {setCollapsed(true)}}/>
                            }
                        </PositionedContainer>

                        
                        {/* Heading Text */}
                        <FlexContainer
                            direction="row"
                            horizontalAlign="middle"
                            verticalAlign="top"
                            fullWidth={true}
                        >
                            <FlexContainer direction="column" horizontalAlign="middle" verticalAlign="middle" gap={8}>
                                <OverlapText size={20} color="primary">Filter</OverlapText>
                                <OverlapText size={12} color="secondary">Filter who's availability is shown</OverlapText>
                            </FlexContainer>
                        </FlexContainer>

                        { overheight &&
                            <IconSearchBar placeholder="Search" icon={<FiSearch/>} style={{marginTop: '8px'}} value={searchText} onChange={(e) => {
                                setSearchText(e.target.value)
                            }}/>
                        }

                        {/* Person List */}
                        <FlexContainer direction="row" horizontalAlign="middle" verticalAlign="top" fullWidth={true} className={Classes.personListContainer}>
                            <div className={`${Classes.personList} ${!showMoreView ? Classes.limitHeight: ''}`} ref={scrollViewRef}>
                                { users.map((user, index) => {
                                    if (searchText !== '') {
                                        // see if search text is contained in the user's name
                                        if (user.displayName.toLowerCase().indexOf(searchText.toLowerCase()) === -1) {
                                            return null;
                                        }
                                    }
                                    return (
                                        <div className={Classes.personItem} key={index}>
                                            <SelectablePersonBubble name={user.displayName} image={user.profileUrl} selected={selectedUsers && selectedUsers[user.uid]} onClick={()=> {
                                                setSelectedUsers({
                                                    ...selectedUsers,
                                                    [user.uid]: !selectedUsers[user.uid]
                                                })
                                            }}/>
                                        </div>
                                    )
                                })}
                            </div>
                        </FlexContainer>
                        { overheight &&
                            <FlexContainer direction="row" horizontalAlign="middle" verticalAlign="middle" gap={4} style={{marginTop: '8px', cursor: 'pointer', pointerEvents: 'all'}} onClick={(e) => {
                                setShowMoreView(!showMoreView);
                                e.stopPropagation();
                            }}>
                                <OverlapText size={12} color="secondary">{showMoreView ? 'Show Less' : 'Show More'}</OverlapText>
                                { showMoreView &&
                                    <FiChevronUp size={20} className={Classes.secondaryColor}/>
                                }
                                { !showMoreView &&
                                    <FiChevronDown size={20} className={Classes.secondaryColor}/>
                                }
                            </FlexContainer>
                        }
                    </>
                }                
            </div>
            
            {/* Bottom Spacer */}
            <div className={Classes.spacer} style={{height: `${spacerHeight}px`}}></div>
        </>
    )
}