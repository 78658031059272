import { useState, useEffect } from 'react';

/**
 * A react hook that returns a state reference [width, height] of the given ref.
 * The size is the visible size of the component and does not include overflowed content or padding, margins, or other such styles.
 * Will automatically update when the ref changes size.
 * Uses the ResizeObserver API.
 * @param {React.MutableRefObject} ref 
 * @return {{width: number, height: number}}
 */
export function useSize(ref) {
    
    // create state with initial size
    const [size, setSize] = useState({width: 0, height: 0});

    // run on hook initialization
    useEffect(() => {

        // immediately set the size state to the current size of the ref
        if (ref.current) {
            setSize(ref.current.getBoundingClientRect());
        } else {
            setSize({width: 0, height: 0});
        }
        
        // create a new observer that will set the size state when the ref changes size
        const observer = new ResizeObserver(entries => {
            if (entries.length > 0) {
                const entry = entries[0];
                setSize({
                    width: entry.contentRect.width,
                    height: entry.contentRect.height
                });
            }
        });
        
        // if the ref actually exists, observe it
        if (ref.current) {
            observer.observe(ref.current);
        }
        
        // when the hook is cleaned up, disconnect the observer
        return () => {
            observer.disconnect();
        }
    }, [ref]);

    return size;
}

/**
 * A react hook that returns the scrollView size of the given ref. This is the size of the content including padding and overflowing content, but does not include margin, scrollbars, or border.
 * See https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollWidth for more details.
 * Will automatically update when the ref changes size by using the ResizeObserver API.
 * 
 * @param {React.MutableRefObject} ref
 * @return {{width: number, height: number}}
 */
export function useScrollSize(ref) {
    // create state with initial size
    const [size, setSize] = useState({width: 0, height: 0});

    // run on hook initialization
    useEffect(() => {

        // immediately set the size state to the current size of the ref
        if (ref.current) {
            setSize(ref.current.getBoundingClientRect());
        } else {
            setSize({width: 0, height: 0});
        }
        
        // create a new observer that will set the size state when the ref changes size
        const observer = new ResizeObserver(entries => {
            if (entries.length > 0) {
                const entry = entries[0];
                setSize({
                    width: entry.target.scrollWidth,
                    height: entry.target.scrollHeight
                });
            }
        });
        
        // if the ref actually exists, observe it
        if (ref.current) {
            observer.observe(ref.current);
        }
        
        // when the hook is cleaned up, disconnect the observer
        return () => {
            observer.disconnect();
        }
    }, [ref]);

    return size;
}

/**
 * A react hook that returns a single state value that indicates whether the given ref has overflowing content.
 * This hook works by using a ResizeObserver to detect changes in the ref's size, and then comparing the scrollWidth and scrollHeight to the clientWidth and clientHeight.
 * @param {React.MutableRefObject} ref 
 * @returns {boolean} Whether or not the referenced element has overflowing content.
 */
export function useOverflow(ref) {
        
    // create state with initial size
    const [overflow, setOverflow] = useState(false);

    // run on hook initialization
    useEffect(() => {
        
        if (!ref.current) {
            setOverflow(false);
            return;
        }
        
        // create a new observer that will set the size state when the ref changes size
        const observer = new ResizeObserver(entries => {
            if (entries.length > 0) {
                const entry = entries[0];
                const xOverflow = entry.target.scrollWidth > entry.target.clientWidth;
                const yOverflow = entry.target.scrollHeight > entry.target.clientHeight;
                setOverflow(xOverflow || yOverflow);
            }
        });
        
        // if the ref actually exists, observe it
        if (ref.current) {
            observer.observe(ref.current);
        }
        
        // when the hook is cleaned up, disconnect the observer
        return () => {
            observer.disconnect();
        }
    }, [ref]);

    return overflow;
}