import {useEffect, useRef, useState} from "react";
import Classess from "./TimeRange.module.scss";
import {timeSegmentToTime} from "../../../../utilities/time";

const TimeSelector = (props) => {
    const [showTimeList, setShowTimeList] = useState(false);
    const timeListHTMLElement = useRef();
    const isAM = props.timeSegment < (12 * 60);
    const timeSegment = props.timeSegment < (13 * 60) ? props.timeSegment : props.timeSegment - (12 * 60);

    function onTimeChange(timeSegment, isAM) {
        const index = Math.floor((timeSegment / 60) % 12);
        let newTimeSegment = index * 60;
        if (!isAM) newTimeSegment += 12 * 60;
        if (timeSegment % 60) newTimeSegment += 30;
        props.onTimeChange(newTimeSegment);
    }

    useEffect(() => {
        function hideTimeList(event) {
            if(!timeListHTMLElement.current.contains(event.target))  setShowTimeList(false);
        }
        document.addEventListener("click", hideTimeList);
        return () => {
            document.removeEventListener("click", hideTimeList);
        };
    }, []);


    return (
        <div ref={timeListHTMLElement}>
            <div onClick={() => setShowTimeList(!showTimeList)}
                 className={`${Classess.Input} ${showTimeList ? Classess.Active : ''}`}>
                <p className="mb-0 text-uppercase ">
                    {timeSegmentToTime(timeSegment)} {isAM ? "AM" : "PM"}
                </p>
                {showTimeList && (
                    <div className={Classess.timeList}>
                        <div className={Classess.hourWrap}>
                            {
                                Array(24).fill(0)
                                    .map((_, index) => (Math.floor(index / 2) * 60) + ((index % 2) ? 30 : 0))
                                    .map((currTimeSegment, index) => {
                                        return (
                                            <div
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    onTimeChange(currTimeSegment, isAM);
                                                }}
                                                key={index}
                                                className={`${Classess.Time} ${
                                                    timeSegment === currTimeSegment ? Classess.active : null
                                                }`}
                                            >
                                                {timeSegmentToTime(currTimeSegment)}
                                            </div>
                                        );
                                    })
                            }
                        </div>
                        <div className={Classess.amPm}>
                            <div
                                onClick={() => {
                                    onTimeChange(timeSegment, true)
                                }}
                                className={`${Classess.lap} ${isAM ? Classess.active : null}`}>
                                AM
                            </div>
                            <div
                                onClick={() => {
                                    onTimeChange(timeSegment, false)
                                }}
                                className={`${Classess.lap} ${!isAM ? Classess.active : null}`}>
                                PM
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default TimeSelector;
