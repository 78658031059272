import { useEffect, useReducer, useRef } from 'react';
import getLogo from '../../utilities/getLogo';
import GoogleBtn from '../Ui/LoginWithBtn/Google/GoogleBtn';
import Classes from './LoginWithGuest.module.scss';
import DefaultButton from '../Ui/Button/DefaultButton';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import FormAlert from '../Ui/FormAlert/FormAlert';
import FormInfo from '../Ui/FormAlert/FormInfo';
import FullPageLoader from '../loader/FullPageLoader';
import useError from '../../utilities/error';
import { useAuth } from '../../context/authContext';
import { useVerify } from '../../utilities/verification';
import { Link } from 'react-router-dom';
import { validateGuestCredentials } from 'utilities/APIs';

function LoginReducer(state, action) {

    switch (action.type) {

        case 'SHOW_LOADER':
            return {
                ...state,
                loading: true,
                loadingMessage: action.message ?? null,
            }

        case 'HIDE_LOADER':
            return {
                ...state,
                loading: false,
                loadingMessage: null,
            }
        
        case 'OPEN_GUEST_SIGN_IN':
            return {
                ...state,
                showGuestLogin: true,
                showManualLogin: false,
            }
        
        case 'CLOSE_GUEST_SIGN_IN':
            return {
                ...state,
                showGuestLogin: false,
            }
        
        case 'UPDATE_GUEST_NAME_EXISTS':
            return {
                ...state,
                guestNameExists: action.invalid
            }
        
        case 'SHOW_MANUAL_MODE':
            return {
                ...state,
                showManualLogin: true,
            }
        
        case 'TOGGLE_SIGNUP_MODE':
            return {
                ...state,
                signUpMode: !state.signUpMode,
            }
        
        case 'RECAPTCHA_SUCCESS':
            return {
                ...state,
                recaptchaSuccess: true,
            }

        default:
            return state;
    }
}

/**
 * Login field that allows manual login, login with google, and login as guest.
 * props.onGuestLogin(guestName, accessCode);
 * props.onNormalLogin();
 */
const LoginWithGuest = ({ onNormalLogin, onGuestLogin, members, allowGuest=true, meetingID }) => {

    const error = useError();
    const authContext = useAuth();

    const [state, dispatch] = useReducer(LoginReducer, {
        showManualLogin: false,
        showGuestLogin: false,
        signUpMode: false,

        firstNameValid: true,
        lastNameValid: true,
        passwordCheckValid: true,

        recaptchaSuccess: false,

        loading: false,
        loadingMessage: null,
    });

    // create verification objects for form elements
    const [guestNameRef, guestNameObj] = useVerify(Classes.invalid);
    const [accessCodeRef, accessCodeObj] = useVerify(Classes.invalid);

    const [loginEmailRef, loginEmailObj] = useVerify(Classes.invalid);
    const [loginPasswordRef, loginPasswordObj] = useVerify(Classes.invalid);

    const [signupEmailRef, signupEmailObj] = useVerify(Classes.invalid);
    const [signupPasswordRef, signupPasswordObj] = useVerify(Classes.invalid);
    const [signupPasswordCheckRef, signupPasswordCheckObj] = useVerify(Classes.invalid);
    const [signupFirstNameRef, signupFirstNameObj] = useVerify(Classes.invalid);
    const [signupLastNameRef, signupLastNameObj] = useVerify(Classes.invalid);

    const recaptchaRef = useRef(null);

    // assign verification rules to form elements
    guestNameObj.notEmpty('Name cannot be empty.');

    loginEmailObj.validEmail("Must be a valid email address.").notEmpty("Email is required.");
    loginPasswordObj.minimumLength(6, "Password must be at least 6 characters.");

    signupEmailObj.validEmail("Must be a valid email address.").notEmpty("Email is required.");
    signupPasswordObj.notEmpty("Password is required.").minimumLength(6, "Password must be at least 6 characters.");
    signupPasswordCheckObj.matchesField(signupPasswordRef, "Password check does not match entered password.");
    signupFirstNameObj.notEmpty("First Name is required.");
    signupLastNameObj.notEmpty("Last Name is required.");

    /**
     * Runs when the authContext.currentUser changes.
     * Either the user has just logged in or the page has just loaded.
     * Decide which, and do something.
     */
    useEffect(() => {

        // warn if prop doesn't exist but do not fail the whole page
        if (!onNormalLogin) {
            console.error("LOGIN COMPONENT HAS NO onNormalLogin PROP!!!");
            return;
        }

        // if the user is now logged in, call onNormalLogin()
        if (authContext.currentUser !== false && authContext.currentUser !== null) {
            onNormalLogin();
        }

        // otherwise, do nothing. Login as guest will return with a seperate function.

    }, [authContext.currentUser, onNormalLogin])

    /**
     * Will watch for recaptcha verification and set recaptcha success.
     */
    useEffect(() => {
        if (!recaptchaRef.current) { return; }
        authContext.recaptchaVerifier('recaptcha-container', (_response) => {
            dispatch({ type: 'RECAPTCHA_SUCCESS' });
        });
    }, [authContext, state.signUpMode, state.showManualLogin]);

    /**
     * Logs in using a 3rd party provider, such as Google.
     */
    async function logInWithDelegate(delegate) {
        dispatch({ type: 'SHOW_LOADER', message: 'Waiting for Google Authentication' });
        try {
            await delegate();
            dispatch({ type: 'HIDE_LOADER' });
        } catch (e) {
            if (e.code === 'auth/popup-closed-by-user' || e.code === 'auth/cancelled-popup-request') {
                // if this error happens, then the user just closed the popup. Close the loader.
                dispatch({ type: 'HIDE_LOADER' });
                return;
            }

            console.error(e);
            error('COBALT', 500, 'An error occurred when awaiting delegate.');
        }
    }
    
    /**
     * Submits the guest login. First validates that the input in both the guest name
     * and the access code inputs are valid. If they are, returns by calling the
     * props.onGuestLogin() function. Otherwise, updates the state of various components.
     */
    async function submitGuestLogin() {

        let guestNameValid = await guestNameObj.verify();
        let accessCodeValid = await accessCodeObj.verify();

        if (guestNameValid && accessCodeValid) {
            dispatch({ type: 'SHOW_LOADER' });
            validateGuestCredentials(meetingID, guestNameObj.value, accessCodeObj.value, {
                guestAccessCodeValid: (data) => {
                    dispatch({ type: 'HIDE_LOADER' });
                    onGuestLogin(guestNameObj.value, accessCodeObj.value);
                },
                guestAccessCodeInvalid: (data) => {
                    dispatch({ type: 'HIDE_LOADER' });
                    accessCodeObj.setInvalidState("Access code is incorrect. Enter the correct access code or try a name that isn't already in the meeting.");
                },
                guestNotInMeeting: (data) => {
                    dispatch({ type: 'HIDE_LOADER' });
                    onGuestLogin(guestNameObj.value, accessCodeObj.value);
                },
                onOtherResponse: (response) => {
                    console.error(response);
                    error('PLASTIC', response.code, "Recieved an unexpected response from the server while checking guest information.");
                },
                onClientError: (error) => {
                    console.error(error);
                    error('CARPET', 500, "Something went wrong while checking guest information.");
                }
            });
        }
    }

    /**
     * Submits the manual login. First validates that the input in both the email and
     * the password inputs are valid. If they are, attempts to log them in. If it succeeds,
     * it calls props.onNormalLogin. Otherwise, it will update the state of various components.
     */
    async function submitManualLogin() {
        
        // show the loading screen
        dispatch({ type: 'SHOW_LOADER' });

        // verify that the email and password are valid
        let emailValid = await loginEmailObj.verify();
        let passwordValid = await loginPasswordObj.verify();

        // if they are valid
        if (emailValid && passwordValid && onNormalLogin) {
            // attempt to actually log the user in
            try {
                await authContext.logIn(loginEmailObj.value, loginPasswordObj.value);
                onNormalLogin();
            } catch (e) {

                switch (e.code) {
                    case 'auth/wrong-password':
                        loginPasswordObj.setInvalidState("Password is incorrect.");
                        break;
                    
                    case 'auth/user-not-found':
                        loginEmailObj.setInvalidState('No user found with that email.');
                        break;
                    
                    default:
                        console.error(e);
                        error('MAGNESIUM', 500, 'An error occurred while attempting to log in.');
                }
            }
        }

        dispatch({ type: 'HIDE_LOADER' });
    }

    /**
     * Submits the manual signup. First validates whether all inputs are valid, then attempts to sign the
     * user up. If sign up was successful, then it will call the onNormalLogin() function. If it was not
     * successful or if inputs are not valid, it will update the relavent components.
     */
    async function submitManualSignup() {

        // show the loading screen
        dispatch({ type: 'SHOW_LOADER' });

        // get verification for all inputs
        let emailValid = await signupEmailObj.verify();
        let passwordValid = await signupPasswordObj.verify();
        let passwordCheckValid = await signupPasswordCheckObj.verify();
        let firstNameValid = await signupFirstNameObj.verify();
        let lastNameValid = await signupLastNameObj.verify();

        // if all are valid
        if (emailValid && passwordValid && passwordCheckValid && firstNameValid && lastNameValid && onNormalLogin && state.recaptchaSuccess) {
            // attempt to sign the user up
            try {
                await authContext.signUp(signupEmailObj.value, signupPasswordObj.value);
                await authContext.updateUserProfile({
                    displayName: `${signupFirstNameObj.value} ${signupLastNameObj.value}`.trim(),
                });
            } catch (e) {
                switch (e.code) {
                    case 'auth/email-already-in-use':
                        signupEmailObj.setInvalidState('Email is already in use.');
                        break;
                    
                    default:
                        console.error(e);
                        error('FORTRAN', 500, 'An error occurred while attempting to sign up a new user.');
                }
            }
        }

        // hide the loading screen
        dispatch({ type: 'HIDE_LOADER' });
    }

    return (
        <div className={Classes.columnWrapper}>

            {/* Welcome to Overlap */}
            { !state.showGuestLogin &&
                <div className={Classes.titleText}>
                    <span>Welcome to</span>
                    <img src={getLogo()} alt="Overlap logo"/>
                </div>
            }

            {/* Log in with Google and Continue as Guest buttons (and 'or' line) */}
            { !state.showGuestLogin &&
                <>
                    <div className={Classes.largeButtonContainer}>
                        <GoogleBtn click={() => {
                            logInWithDelegate(authContext.logInUsingGoogle);
                        }}/>
                    </div>
                    { allowGuest &&
                        <div className={Classes.largeButtonContainer} onClick={(e) => {
                            e.preventDefault();
                            dispatch({ type: 'OPEN_GUEST_SIGN_IN' });
                        }}>
                            <div className={`${Classes.largeButton} ${Classes.guestModeButton}`}>Continue as Guest</div>
                        </div>
                    }
                    <div className={Classes.orRow}>
                        <div className={Classes.line}></div>
                        <div className={Classes.orText}>or</div>
                        <div className={Classes.line}></div>
                    </div>
                </>
            }

            {/* Log in or Sign up with Email button */}
            { !state.showManualLogin && !state.showGuestLogin &&
                <div className={Classes.manualModeSwitch} onClick={() => {
                    dispatch({ type: 'SHOW_MANUAL_MODE' });
                }}>
                    Log in or Sign up with Email
                </div>
            }

            {/* Log in form and submission button */}
            { state.showManualLogin && !state.signUpMode &&
                <>
                    <div className={Classes.formContainer}>
                        {loginEmailObj.invalid && loginEmailObj.errorMessage &&
                            <div className={Classes.formFeedback}>{loginEmailObj.errorMessage}</div>
                        }
                        <input
                            className={Classes.input}
                            placeholder="Email"
                            type="email"
                            autoFocus={true}
                            ref={loginEmailRef}
                        />
                        {loginPasswordObj.invalid && loginPasswordObj.errorMessage &&
                            <div className={Classes.formFeedback}>{loginPasswordObj.errorMessage}</div>
                        }
                        <input
                            className={Classes.input}
                            placeholder="Password"
                            type="password"
                            ref={loginPasswordRef}
                        />
                        <div className={Classes.formBottomRow}>
                            <Link to={'/passreset'}>
                                <div className={Classes.forgotPasswordButton}>Forgot Password?</div>
                            </Link>
                            <div className={Classes.spacer}></div>
                            <DefaultButton
                                text="Log In"
                                rightIcon={<FiChevronRight/>}
                                rightLarge={true}
                                onClick={submitManualLogin}
                            />
                        </div>
                    </div>
                </>
            }

            {/* Sign up form and submission button */}
            { state.showManualLogin && state.signUpMode &&
                <>
                    <div className={Classes.formContainer}>
                        {signupEmailObj.invalid && signupEmailObj.errorMessage &&
                            <div className={Classes.formFeedback}>{signupEmailObj.errorMessage}</div>
                        }
                        <input
                            className={Classes.input}
                            placeholder="Email"
                            type="email"
                            autoFocus={true}
                            ref={signupEmailRef}
                        />
                        {signupPasswordObj.invalid && signupPasswordObj.errorMessage &&
                            <div className={Classes.formFeedback}>{signupPasswordObj.errorMessage}</div>
                        }
                        <input
                            className={Classes.input}
                            placeholder="Password"
                            type="password"
                            ref={signupPasswordRef}
                        />
                        {signupPasswordCheckObj.invalid && signupPasswordCheckObj.errorMessage &&
                            <div className={Classes.formFeedback}>{signupPasswordCheckObj.errorMessage}</div>
                        }
                        <input
                            className={Classes.input}
                            placeholder="Re-Enter Password"
                            type="password"
                            ref={signupPasswordCheckRef}
                        />
                        <div className={Classes.inputRow}>
                            <div className={Classes.col}>
                                {signupFirstNameObj.invalid && signupFirstNameObj.errorMessage &&
                                    <div className={Classes.formFeedback}>{signupFirstNameObj.errorMessage}</div>
                                }
                                <input
                                    className={Classes.input}
                                    placeholder="First Name"
                                    type="text"
                                    ref={signupFirstNameRef}
                                />
                            </div>
                            <div className={Classes.col}>
                                {signupLastNameObj.invalid && signupLastNameObj.errorMessage &&
                                    <div className={Classes.formFeedback}>{signupLastNameObj.errorMessage}</div>
                                }
                                <input
                                    className={Classes.input}
                                    placeholder="Last Name"
                                    type="text"
                                    ref={signupLastNameRef}
                                />
                            </div>
                        </div>
                        { !state.recaptchaSuccess &&
                            <div className={Classes.recaptchaContainer}>
                                <div id="recaptcha-container" ref={recaptchaRef}></div>
                            </div>
                        }
                        <div className={Classes.formBottomRow}>
                            <div className={Classes.spacer}></div>
                            <DefaultButton
                                text="Sign Up"
                                rightIcon={<FiChevronRight/>}
                                rightLarge={true}
                                onClick={submitManualSignup}
                            />
                        </div>
                    </div>
                </>
            }

            {/* Manual mode switch (at bottom of sign up and login forms) */}
            { state.showManualLogin &&
                <div className={Classes.manualSwitchRow} onClick={() => {
                    dispatch({ type: 'TOGGLE_SIGNUP_MODE' });
                }}>
                    { state.signUpMode ? 'Already have an account? ' : "Don't have an account yet? "}
                    { state.signUpMode &&
                        <span className={Classes.manualSwitchRowButton}>Log In</span>
                    }
                    { !state.signUpMode &&
                        <span className={Classes.manualSwitchRowButton}>Sign Up</span>
                    }
                </div>
            }

            {/* Guest login form */}
            { state.showGuestLogin &&
                <>
                    <div className={Classes.topBar}>
                        <FiChevronLeft className={Classes.backButton} onClick={()=>{
                            dispatch({ type: 'CLOSE_GUEST_SIGN_IN' });
                        }}/>
                        <div className={Classes.guestTitleText}>Continuing as Guest</div>
                        <div className={Classes.flexSpacer}/>
                    </div>
                    <div className={Classes.formContainer}>
                        { state.guestNameExists &&
                            <div className={Classes.formAlertContainer}>
                                <FormAlert>That name is already used in this meeting. Please enter the correct access code for that name or enter another name.</FormAlert>
                            </div>
                        }
                        { guestNameObj.invalid && guestNameObj.errorMessage &&
                            <div className={Classes.formFeedback}>{guestNameObj.errorMessage}</div>
                        }
                        <input
                            className={Classes.input}
                            placeholder="Name (required)"
                            type="text"
                            autoFocus={true}
                            ref={guestNameRef}
                        />
                        { accessCodeObj.invalid && accessCodeObj.errorMessage &&
                            <div className={Classes.formFeedback}>{accessCodeObj.errorMessage}</div>
                        }
                        <input
                            className={Classes.input}
                            placeholder="Access Code (optional)"
                            type="text"
                            ref={accessCodeRef}
                        />
                        <div className={Classes.formAlertContainer}>
                            <FormInfo>Create any access code and keep it in mind so you can return later and edit your availability.</FormInfo>
                        </div>
                        <div className={Classes.formBottomRow}>
                            <div className={Classes.spacer}></div>
                            <DefaultButton
                                text="Submit"
                                rightIcon={<FiChevronRight/>}
                                rightLarge={true}
                                onClick={submitGuestLogin}
                            />
                        </div>
                    </div>
                </>
            }

            {/* Full page loading spinner */}
            { state.loading &&
                <div className={Classes.loadingSpinnerContainer} onClick={(e) => {
                    e.stopPropagation();
                }}>
                    { state.loadingMessage &&
                        <FullPageLoader message={true} messageText={state.loadingMessage}/>
                    }
                    { !state.loadingMessage &&
                        <FullPageLoader/>
                    }
                </div>
            }
        </div>
    )
};

export default LoginWithGuest;
