import Classes from './ChangeProfileInfo.module.scss';
import { useAuth } from '../../context/authContext';
import { useVerify, verifyAll, getErrorMessageList } from '../../utilities/verification';
import FormAlert from '../Ui/FormAlert/FormAlert';
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import FullPageLoader from '../loader/FullPageLoader';
import { constants } from '../../firebase';
import { Helmet } from 'react-helmet';

/**
 * Dialog for changing email and phone number and save button.
 */
export default function ChangeProfileInfo() {

    // hooks
    const authContext = useAuth();
    const [emailRef, emailObj] = useVerify();
    const [phoneRef, phoneObj] = useVerify();

    // verification settings
    emailObj.validEmail("Please enter a valid email address.").notEmpty("Email is required.");
    phoneObj.validPhone("Please enter a valid phone number.");

    // state
    const [messages, setMessages] = useState([]);
    const [showLoader, setShowLoader] = useState(false);

    // variables
    const phoneNumber = authContext.currentUser.phoneNumber ?? '';

    // handlers
    async function updateErrorMessages() {

        const messages = getErrorMessageList([emailObj, phoneObj]);

        setMessages(messages);
    }

    async function onSave(event) {
        event.stopPropagation();
        event.preventDefault();

        // return if any fields are invalid
        const [valid, ] = await verifyAll([emailObj, phoneObj]);
        updateErrorMessages();
        if (!valid) return;

        setShowLoader(true); // show loader while submitting changes
        
        // process phone number to just raw number and +
        let phoneNumber = phoneObj.value.replace(/[^0-9+]/g, '');
        if (phoneNumber[0] !== '+' && phoneNumber !== '') {
            // add +1 as the country code if no country code is included
            // only if the phone number was included
            phoneNumber = '+1' + phoneNumber;
        }

        // submit changes
        let response = await fetch(`${constants.functionsBaseUrl}/users/self/phone`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${await authContext.currentUser.getIdToken()}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                phoneNumber,
            })
        })

        if (!response.ok) {
            setShowLoader(false); // hide loader
            toast.error('Failed to update profile information.');
            console.debug('Failed to update profile information!');
            console.debug(response);
            console.debug(await response.json());
            return;
        }

        // NOTE: Access the values of the fields with emailObj.value and phoneObj.value
        toast.success("Profile information updated!", { duration: 2500 });
        setShowLoader(false);
        setTimeout(() => {
            window.location.replace('/account');
        }, 1000);
        return;
    }

    /**
     * Note from David:
     * I realize that it would make more intuitive sense to render only the full page loader if showLoader is true, and render the rest of the page if showLoader is false. This weird system I have set up with the gone class (which is display: none btw) might seem stupid. The reason I've done it is because if I don't render the inputs, then any code to set error messages fails because the verification objects require that the inputs exist. By hiding them instead, I make sure that the inputs are still mounted, which means that the verification objects will still work, allowing the submission code to set custom error messages that will appear when the loader disappears.
     * 
     * TLDR: Inputs have to be mounted at all times so that submission code can set custom error messages. This is why I hide them instead of unmounting them when the loader is shown.
     */

    return (
    <>
        <Helmet>
            <title>My Profile</title>
        </Helmet>
        {showLoader && <FullPageLoader/>}
        <form className={`${Classes.pageWrap} ${showLoader ? Classes.gone : ''}`} onSubmit={onSave}>
            <div className={Classes.containerWrap}>
                <div className={Classes.inputWrap}>
                    <label htmlFor="email" className={Classes.inputLabel}>Email</label>
                    <input className={Classes.input} type="email" id="email" name="email" placeholder="example@mail.com"
                    defaultValue={authContext.currentUser.email}
                    ref={emailRef}
                    disabled={true}
                    />
                </div>
                <div className={Classes.inputWrap}>
                    <label htmlFor="phone" className={Classes.inputLabel}>Phone Number</label>
                    <input className={Classes.input} type="tel" id="phone" name="phone" placeholder="(###) ###-####" defaultValue={phoneNumber} ref={phoneRef}/>
                </div>
            </div>
            { messages.length > 0 &&
                <div className={Classes.alertWrap}>
                    <FormAlert>{messages[0]}</FormAlert>
                </div>
            }
            <button className={Classes.saveButton} onClick={onSave}>Save Changes</button>
        </form>
    </>
    )
}