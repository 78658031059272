import { FiCheck } from "react-icons/fi";
import Classes from "./PremiumTable.module.scss";

const features = [
    "Create Unlimited Meetings",
    "Invite Unlimited Participants",
    "Meeting Leader Notifications",
    "Filter People's Availability"
];

const plans = {
    free: [true, true, false, false],
    premium: [true, true, true, true]
};

const buffer = [
    false, false, false, false
];

export default function PremiumTable() {
    return (
        <div className={Classes.table}>
            <div className={Classes.leftCol}>
                <div className={`${Classes.blank} ${Classes.header}`}>blank</div>
                {features.map((feature, index) => (
                    <div key={index} className={`${Classes.body} ${index === features.length - 1 ? Classes.bottomRow : ""}`}>
                        {feature}
                    </div>
                ))}
            </div>
            <div className={Classes.leftBuffer}>
                <div className={`${Classes.blank} ${Classes.header}`}>blank</div>
                {buffer.map((isAvailable, index) => (
                    <div key={index} className={`${Classes.body} ${index === features.length - 1 ? Classes.bottomRow : ""}`}>
                        <FiCheck className={`${Classes.checkmark} ${!isAvailable ? Classes.blank : ""}`} />
                    </div>
                ))}
            </div>
            <div className={Classes.midCol}>
                <div className={Classes.header}>FREE</div>
                {plans.free.map((isAvailable, index) => (
                    <div key={index} className={`${Classes.body} ${index === features.length - 1 ? Classes.bottomRow : ""}`}>
                        <FiCheck className={`${Classes.checkmark} ${!isAvailable ? Classes.blank : ""}`} />
                    </div>
                ))}
            </div>
            <div className={Classes.rightBuffer}>
                <div className={`${Classes.blank} ${Classes.header}`}>blank</div>
                {buffer.map((isAvailable, index) => (
                    <div key={index} className={`${Classes.body} ${index === features.length - 1 ? Classes.bottomRow : ""}`}>
                        <FiCheck className={`${Classes.checkmark} ${!isAvailable ? Classes.blank : ""}`} />
                    </div>
                ))}
            </div>
            <div className={Classes.rightCol}>
                <div className={`${Classes.premium} ${Classes.header}`}>Premium</div>
                {plans.premium.map((isAvailable, index) => (
                    <div key={index} className={`${Classes.body} ${index === features.length - 1 ? Classes.bottomRow : ""}`}>
                        {isAvailable && <FiCheck className={Classes.checkmark} />}
                    </div>
                ))}
            </div>
        </div>
    );
}
