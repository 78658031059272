import { useContext, useEffect, useRef, useState } from 'react';
import { AvailabilityContext } from '../AvailabilityCard/Subcomponents/AvailabilityContext';
import Classes from './BubbleDetails.module.scss';
import { FiChevronDown, FiChevronUp, FiUserCheck, FiUserX, FiX } from 'react-icons/fi';
import PersonItem from '../MeetingDetails/PersonItem';

export default function BubbleDetails({ gridInfo, openHandler = () => {}, closeHandler = () => {}, bottomSpacing = 15 }) {

    // create references
    const popupRef = useRef(null);
    const scrollViewRef = useRef(null);
    const availableListRef = useRef(null);
    const notAvailableListRef = useRef(null);
    const refreshTimeoutRef = useRef(null);

    // create state
    const [popupHeight, setPopupHeight] = useState(0);
    const [popupOverflowing, setPopupOverflowing] = useState(false);
    const [showSeeMore, setShowSeeMore] = useState([false, false]);
    const [expandSeeMore, setExpandSeeMore] = useState([false, false]);
    const [bubbleDetails, setBubbleDetails] = useState(null);

    // create availability context
    const availabilityContext = useContext(AvailabilityContext);

    /**
     * Checks whether the popup is overflowing and sets the showSeeMore state accordingly
     */
    function recheckAvailabilityPopupState() {
        recalculateSpacerHeight();

        if (!scrollViewRef.current || !availableListRef.current || !notAvailableListRef.current) return;

        // check if the popup is overflowing
        if (scrollViewRef.current) {
            setPopupOverflowing(scrollViewRef.current.scrollHeight > scrollViewRef.current.clientHeight);
        }

        // WARNING: THIS IS JANK AND DEPENDS ON THE CSS TO NOT CHANGE
        // IF THE CSS FOR .peopleList OR ANY OF IT'S CHILDREN CHANGES, DOUBLE CHECK THIS CODE
        // This is the maximum height a peopleList can be before it starts cut off content and show the "see more" button. This should be in pixels
        let maxheight = 70;
        let availableListHeight, notAvailableListHeight;
        if (availableListRef.current && !notAvailableListRef.current) {
            // if only the availabile list is present, use the height of the available list and use the previous height of the not available list
            availableListHeight = availableListRef.current.getBoundingClientRect().height;
            notAvailableListHeight = maxheight;
        }

        else if (!availableListRef.current && notAvailableListRef.current) {
            // if only the not availabile list is present, use the height of the not availabile list and use the previous height of the availabile list
            availableListHeight = maxheight;
            notAvailableListHeight = notAvailableListRef.current.getBoundingClientRect().height;
        }

        else if (availableListRef.current && notAvailableListRef.current) {
            // if both lists are present, use the height of both lists
            availableListHeight = availableListRef.current.getBoundingClientRect().height;
            notAvailableListHeight = notAvailableListRef.current.getBoundingClientRect().height;
        }

        if (availableListRef.current || notAvailableListRef.current) {
            setShowSeeMore([availableListHeight > maxheight, notAvailableListHeight > maxheight]);
        }
    }

    /**
     * Runs whenever bubble details changes. If bubble details is null, it will call onClose. If bubble details is not null, it will call onOpen.
     * This allows the parent component to handle the opening and closing of the bubble details without directly controlling it.
     */
    useEffect(() => {
        if (bubbleDetails && openHandler) {
            openHandler();
        }
        if (!bubbleDetails && closeHandler) {
            closeHandler();
        }
    }, [bubbleDetails, closeHandler, openHandler]);

    // this code will run whenever bubbleDetails changes
    useEffect(() => {
        recheckAvailabilityPopupState();

        // reset expandShowMore when bubble details is closed
        if (!bubbleDetails) {
            setExpandSeeMore([false, false]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bubbleDetails]);

    // this code only refreshes the popup if the screen has not been resized for more than 100 milliseconds.
    // this way the screen will automatically refresh the popup, but will not continually refresh while the screen is being resized.
    function setResizeTimeout() {

        refreshTimeoutRef.current = setTimeout(() => {
            // refreshTimeoutRef.current = null;
            recheckAvailabilityPopupState();
            console.log("Refreshing availability popup due to window size change!");
        }, 40);
    }

    window.addEventListener('resize', () => {

        if (refreshTimeoutRef.current) {
            clearTimeout(refreshTimeoutRef.current);
            setResizeTimeout();
        }

        else {
            setResizeTimeout();
        }
    });

    function recalculateSpacerHeight() {
        if (!popupRef.current) return;

        // update the height of the spacer to be the height of the popup
        const height = popupRef.current.getBoundingClientRect().height;
        setPopupHeight(`${height + 30}px`);
    }

    /** Processing function to turn luxon interval into the string required for the bubble popup */
    function getIntervalString(interval) {
        const start = interval.start.toFormat('h:mm a');
        const end = interval.end.toFormat('h:mm a');
        const date = interval.start.toFormat('cccc MMM d');

        return `${start} - ${end}, ${date}`;
    }

    function closePopupHandler() {
        setBubbleDetails(null);
    }

    useEffect(() => {

        function handleActiveChange(activeBubble) {
            // if set to no bubble active, close bubble details
            if (!activeBubble) {
                closePopupHandler();
                return;
            }

            // get row and column of selected bubble
            let [col, row] = activeBubble.split(',');

            // get a list of the available member objects at that bubble
            let availableMembersNum = gridInfo.availability[col][row];
            let membersAvailable = [];
            let membersNotAvailable = [];
            gridInfo.members.forEach((member, index) => {
                if (availableMembersNum.includes(index)) {
                    membersAvailable.push(member);
                } else {
                    membersNotAvailable.push(member);
                }
            })


            // build bubble details
            let bubbleDetails = {
                interval: gridInfo.intervalGrid[col][row],
                membersAvailable,
                membersNotAvailable,
            }

            setBubbleDetails(bubbleDetails);
        }

        try {
            handleActiveChange(availabilityContext.active);
        } catch (e) {
            // do nothing, the only time this errors is when the page is loading, at which point this shouldn't be showing anyway
        }

    }, [availabilityContext?.active, gridInfo]);

    return (
        <>
            { bubbleDetails &&
                <>
                    <div className={Classes.availabilityPopupContainer} ref={popupRef} onClick={(e) => e.stopPropagation()} style={{bottom: `${bottomSpacing}px`}}>
                        <div className={Classes.topRow}>
                            <div className={Classes.timeDateBar}>
                                {getIntervalString(bubbleDetails.interval)}
                            </div>
                            <div className={Classes.closeButton} onClick={() => {
                                availabilityContext.clearActive();
                                closePopupHandler();
                            }}><FiX /></div>
                        </div>
                        <div className={`${Classes.scrollView} ${popupOverflowing ? Classes.scrollable : ''}`} ref={scrollViewRef}>
                            {bubbleDetails.membersAvailable.length > 0 &&
                                <div className={Classes.peopleSection}>
                                    <div className={Classes.peopleListTitle}>
                                        {bubbleDetails.membersAvailable.length}
                                        <FiUserCheck />
                                    </div>
                                    <div className={`${Classes.peopleList} ${showSeeMore[0] && !expandSeeMore[0] ? Classes.capped : ''}`} ref={availableListRef}>
                                        {bubbleDetails.membersAvailable.map((user, index) => {
                                            return (
                                                <div className={Classes.person} key={user.uid}>
                                                    <PersonItem user={user} available={true} leader={user.role === 0} />
                                                </div>
                                            )
                                        })}
                                    </div>
                                    {showSeeMore[0] &&
                                        <div className={Classes.seeMoreButton} onClick={() => {
                                            setExpandSeeMore([!expandSeeMore[0], expandSeeMore[1]]);
                                        }}>
                                            {expandSeeMore[0] ? "See Less" : "See More"}
                                            {expandSeeMore[0] ? <FiChevronUp /> : <FiChevronDown />}
                                        </div>
                                    }
                                </div>
                            }
                            {bubbleDetails.membersNotAvailable.length > 0 &&
                                <div className={Classes.peopleSection}>
                                    <div className={Classes.peopleListTitle}>
                                        {bubbleDetails.membersNotAvailable.length}
                                        <FiUserX />
                                    </div>
                                    <div className={`${Classes.peopleList} ${showSeeMore[1] && !expandSeeMore[1] ? Classes.capped : ''}`} ref={notAvailableListRef}>
                                        {bubbleDetails.membersNotAvailable.map((user, index) => {
                                            return (
                                                <div className={Classes.person} key={user.uid}>
                                                    <PersonItem user={user} available={false} leader={user.role === 0} />
                                                </div>
                                            )
                                        })}
                                    </div>
                                    {showSeeMore[1] &&
                                        <div className={Classes.seeMoreButton} onClick={() => {
                                            setExpandSeeMore([expandSeeMore[0], !expandSeeMore[1]]);
                                        }}>
                                            {expandSeeMore[1] ? "See Less" : "See More"}
                                            {expandSeeMore[1] ? <FiChevronUp /> : <FiChevronDown />}
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                    <div className={Classes.bottomSpacer} style={{ height: popupHeight }}></div>
                </>
            }
        </>
    )
}