import React from "react";
import Classess from "./Calender.module.scss";

import "./Month.scss";
import {constructDate} from "../../../../utilities/date";

const Month = (props) => {
    const {monthList, daySelectHandler} = props;

    const dayHoverHandler = (e) => {
    };

    const date = new Date();
    const today = date.getDate();

    return (
        <div className={Classess.calenderContainer}>
            {monthList.map((currentMonth, monthIndex) => {

                return (
                    <div
                        data-month={currentMonth.monthAbbr.toLowerCase()}
                        className={Classess.Month}
                        key={monthIndex}
                    >
                        <h1 className={Classess.MonthName}>
                            {currentMonth.month}
                        </h1>

                        <div className={Classess.dateWrap}>
                            {currentMonth.calendar.map((week, weekIndex) => {

                                return (
                                    <div
                                        className={`${
                                            Classess.weekRow
                                        } ${currentMonth.monthAbbr.toLowerCase()}-${weekIndex}`}
                                        key={weekIndex}
                                    >
                                        {week.map((day, dayIndex) => {
                                            // console.log('Month Index: ', monthIndex, 'Week Index: ', weekIndex, ' Day Index: ', dayIndex, ' Today: ', today, ' Day: ', day)

                                            const currentDate = constructDate(day, currentMonth.monthAbbr.toLowerCase(), currentMonth.year);
                                            const isSelected = props.selectedDates.includes(currentDate);

                                            // Here return either gray (and unselectable) day if it's before current date of current month OR normal day that is selectable otherwise
                                            if ((monthIndex === 0 && day < today) || day === 0) {
                                                return (
                                                    <div className={Classess.dayContainer} key={dayIndex}>
                                                        <div
                                                            data-date={day}
                                                            className={`${isSelected ? Classess.Active : null} ${
                                                                Classess.DateGray
                                                            } ${
                                                                day
                                                                    ? `${currentMonth.monthAbbr.toLowerCase()}_${weekIndex}_${day}`
                                                                    : ""
                                                            }  `}
                                                            key={dayIndex}
                                                        >
                                                            {day ? day : null}
                                                        </div>
                                                    </div>
                                                );
                                            } else {
                                                return (
                                                    <div className={Classess.dayContainer} key={dayIndex}>
                                                        <div
                                                            data-date={day}
                                                            className={`${isSelected ? Classess.Active : null} ${
                                                                Classess.Date
                                                            } ${
                                                                day
                                                                    ? `${currentMonth.monthAbbr.toLowerCase()}_${weekIndex}_${day}`
                                                                    : ""
                                                            }  `}
                                                            key={dayIndex}
                                                            onClick={(e) =>
                                                                daySelectHandler(currentDate)
                                                            }
                                                            onMouseOver={
                                                                dayHoverHandler
                                                            }
                                                        >
                                                            {day ? day : null}
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        })}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default Month;
