import {useAuth} from "../context/authContext";
import {useNavigate} from "react-router-dom";
import FullPageLoader from "../components/loader/FullPageLoader";
import Header from "../components/Header/Header";
import useError from "../utilities/error";

const LogOutPage = () => {
    const authContext = useAuth();
    const navigate = useNavigate();
    const error = useError();

    // TODO : punch loader
    authContext.logOut().then(_ => {
        navigate("/welcome");
    }).catch(e => {
        console.error(e);
        error('CARIBBEAN', 500, 'An error occurred while attempting to log out.');
    }).finally(_ => {
        // TODO : release loader
    });

    return (
        <>
            <Header/>
            <FullPageLoader message="Preparing for logout....., You will be redirected shortly!"/>
        </>
    );
}

export default LogOutPage