import FormAlertTemplate from './FormAlertTemplate';

function FormInfo(props) {
    return (
        <FormAlertTemplate borderColor={'#939393'} backgroundColor={'#DFDFDF'}>
            {props.children}
        </FormAlertTemplate>
    )
}

export default FormInfo;