import { FiSearch } from "react-icons/fi";
import Classes from './IconSearchBar.module.scss';

export default function IconSearchBar({ value, icon, placeholder, onChange, style }) {

    icon = icon ?? <FiSearch/>;

    return (
        <div className={Classes.searchBar} style={style}>
            {icon}
            <input className={Classes.searchInput} type="text" placeholder={placeholder} value={value} onChange={onChange}/>
        </div>
    )
}