import { useEffect, useReducer, useRef } from "react";
import { useOutsideClick, useOutsidePointerUp } from "../../../utilities/clickHandling";
import availabilityReducer from "./AvailabilityReducer";

/**
 * Generates a 2D matrix of size cols x rows filled with the fill value.
 * @private
 * @param {number} rows 
 * @param {number} cols 
 * @param {any} fill
 * @returns {[any][any]}
 */
function _generate2DMatrix(rows, cols, fill) {
    return [...Array(cols)].map(_ => Array(rows).fill(fill));
}

export default function useCreateAvailabilityContext(mode, data) {

    // REFS
    const gridRef = useRef(null);
    const timeColumnRef = useRef(null);

    // SETUP CONTEXT
    const [state, dispatch] = useReducer(availabilityReducer, {
        selected: data?.initialSelected ?? _generate2DMatrix(data?.bubbles[0].length, data?.bubbles.length, false),
        active: mode === 'OVERVIEW' ? null : _generate2DMatrix(data?.bubbles[0].length, data?.bubbles.length, false),
        pointerDown: false,
        pointerStartPosition: null,
        pointerLastPosition: null,
        mode: mode, // this should not be updated as state, it is just so that the reducer has access to it
        intervalGrid: data?.intervalGrid,
        gridRef,
    });

    // update the grid when the data changes
    useEffect(() => {
        dispatch({
            type: 'GRID_CHANGE',
            data: data
        })
    }, [data])

    /**
     * Bubbles should call this function on the onClick event.
     * Updates the context with new state, such as selected/active bubbles.
     * @param {[row, col]} position 
     */
    function bubbleClicked(position) {
        // dispatch change to the reducer, who will update the state if necessary
        dispatch({
            type: 'BUBBLE_CLICKED',
            position
        });
    }

    /**
     * Bubbles should call this function on the on the onPointerDown event.
     * Updates the context with new state, such as selected/active bubbles.
     * @param {[row, col]} position 
     */
    function bubblePointerDown(position) {
        // dispatch change to the reducer, who will update the state if necessary
        dispatch({
            type: 'BUBBLE_POINTER_DOWN',
            position
        });
    }

    /**
     * Bubbles should call this function on the on the onPointerMove event.
     * Updates the context with new state, such as selected/active bubbles.
     * @param {[row, col]} position 
     */
    function bubblePointerMove(position) {
        dispatch({
            type: 'BUBBLE_POINTER_MOVE',
            position
        })
    }

    /**
     * Bubbles should call this function on the on the onPointerUp event.
     * Updates the context with new state, such as selected/active bubbles.
     * @param {[row, col]} position 
     */
    function bubblePointerUp(position) {
        dispatch({
            type: 'BUBBLE_POINTER_UP',
            position
        });
    }

    /**
     * Bubbles should call this function on the onPointerUp event if the pointer is not over a bubble.
     */
    function pointerUpOutsideBubble() {
        dispatch({
            type: 'OUTSIDE_CLICK'
        })
    }

    /**
     * Called to clear all the active bubbles.
     */
    function clearActive() {
        dispatch({
            type: 'CLEAR_ACTIVE'
        })
    }

    useOutsideClick(gridRef, () => {
        pointerUpOutsideBubble();
    })
    useOutsidePointerUp(gridRef, () => {
        pointerUpOutsideBubble();
    })

    // define basic properties
    const skipRowHeight = '10px';

    const context = {
        selected: state.selected,
        active: state.active,
        intervalGrid: state.intervalGrid,
        bubbleClicked,
        bubblePointerDown,
        bubblePointerMove,
        bubblePointerUp,
        pointerUpOutsideBubble,
        clearActive,

        mode,
        skipRowHeight,
        gridRef,
        timeColumnRef,
    };

    return context;
}