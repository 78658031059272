import React from 'react'

const InplaceCircle = (props) => {

    const scale = props.scale ?? 1;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
             style={{
                scale: String(scale),
                margin: "auto",
                background: "none",
                display: "block",
                shapeRendering: "auto"
             }}
             width={200} height={200} viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
            <circle cx="50" cy="50" fill="none" stroke="#0a984c" strokeWidth="1" r="10"
                    strokeDasharray="47.12388980384689 17.707963267948966">
                <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s"
                                  values="0 50 50;360 50 50" keyTimes="0;1"/>
            </circle>
        </svg>
    )
}

export default InplaceCircle