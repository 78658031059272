import { Fragment, useContext } from 'react';
import Classes from './AvailabilityTimeColumn.module.scss';
import { AvailabilityContext } from './AvailabilityContext';
import { useSearchParams } from 'react-router-dom';
import { getAbbreviation, getUserTimeZone } from 'utilities/timezone';

export default function AvailabilityTimeColumn(props) {

    /* NOTE:
     * The times array should be of the form:
     * ["9am", "10am", "11am", ...] and so on in order from top to bottom of the column.
     * "SKIP" should be used to generate a skip row.
     */

    // PROPS
    const { times } = props;
    const availabilityContext = useContext(AvailabilityContext);

    // DEV FUN FEATURES
    const [queryParams,] = useSearchParams();
    const lines = queryParams.get('lines') === 'true';

    return (
        <div className={Classes.timeColumn}>
            {times.map((text, index) => {
                // if the row is a skip row, insert a spacer
                if (text === "SKIP") {
                    return <div key={`skip-${index}`} style={{ height: availabilityContext.skipRowHeight }}></div>
                }

                // otherwise, insert the time
                else {
                    // console.log(getUserTimeZone());
                    // console.log(getAbbreviation(getUserTimeZone()));
                    let zone = getAbbreviation(getUserTimeZone())
                    if (zone === undefined) {
                        zone = "???"
                    }
                    if ((index === 0 || index === 1) && text !== "") {
                        return (
                            <Fragment key={`fragment-${index}`}>
                                <div className={Classes.inlineContainer}>
                                    <div className={Classes.timeZone}>  {zone} &nbsp; </div>
                                    <div key={`time-${index}`} className={Classes.time}>{text}</div>
                                </div>
                                {lines && index % 2 === 1 &&
                                    <div key={`line-${index}`} className={Classes.lineSpacer}></div>
                                }
                            </Fragment>
                        )
                    } else {
                        return (
                            <Fragment key={`fragment-${index}`}>
                                <div key={`time-${index}`} className={Classes.time}> {text}</div>
                                {lines && index % 2 === 1 &&
                                    <div key={`line-${index}`} className={Classes.lineSpacer}></div>
                                }
                            </Fragment>
                        )
                    }
                }
            })}
        </div>
    );
}
