import Header from "components/Header/Header"
import React from "react"
import { Helmet } from "react-helmet"
import Classes from "./scss/AboutUsPage.module.scss"
import TextWithTitle from "components/Ui/AboutUsComponents/TextWithTitle"
import DefaultButton from "components/Ui/Button/DefaultButton"
import { useAuth } from "context/authContext"
import { useNavigate } from "react-router-dom"
import TextBox from "components/Ui/AboutUsComponents/TextBox"
import { FiInfo } from "react-icons/fi"
import PremiumTable from "components/Ui/AboutUsComponents/PremiumTable"
import getLogo from "utilities/getLogo"
import getImg from "utilities/getImg"
import ResourceFooter from "../components/resourceFooter/ResourceFooter";
import TeamBubble from "components/Ui/AboutUsComponents/TeamBubble"

export default function AboutUsPage() {
    const navigate = useNavigate();
    const signedIn = useAuth();

    const team = [
        { name: "Noah Hubbard", title: "Co-Founder & CEO" },
        { name: "Joseph Johnson", title: "Co-Founder & UX Designer" },
        { name: "Karthik Nedunchezhiyan", title: "Co-Founder & Back End Dev" },
        { name: "David Bootle", title: "Co-Founder & Front End Dev" },
        { name: "Isaac Kim", title: "Front End Dev" }
    ];
    

    const navigateToBlog = () => {
        window.open("https://blogs.myoverlap.com/blogs", "_blank", "noopener noreferrer");
    }

    return (
        <>
            <Helmet>
                <title>About Us</title>
            </Helmet>
            <Header />
            <main className={Classes.customMain}>
                <div className={Classes.pageContainer}>
                    <TextWithTitle 
                        title="Helping You Schedule"
                        body="We're here to simplify group scheduling for everyone, whether you're a small startup, a large corporation, a busy student, or an individual managing personal commitments."
                        textCenter={true}
                        className={Classes.schedule}
                    />

                    <div className={Classes.bufferOne}></div>

                    <div className={Classes.graphicsWrapper}>
                        <img className={Classes.availability} src={getImg("availability")} alt="" />
                        <img className={Classes.green} src={getImg("darkerGreen")} alt="" />
                    </div>

                    <div className={Classes.bufferOne}></div>

                    <div className={Classes.overlapContainer}>
                        <div className={Classes.textAndButton}>
                            <TextWithTitle 
                                title="What is Overlap?"
                                body={"Overlap simplifies scheduling by eliminating back-and-forth emails and texts. Send an Overlap poll for group members to add their availability, and easily find overlapping free times. \n\nStart bringing your groups together with Overlap today!"}
                                textLeft={true}
                            />
                            {!signedIn.currentUser && 
                            <DefaultButton
                                text="Sign Up"
                                onClick={() => navigate("/signup")}
                                className={Classes.button}
                            />}
                        </div>
                        <div className={Classes.videoWrapper}>
                            <iframe
                                width="516" height="290"
                                src="https://www.youtube.com/embed/xnz9impK8tg?si=9qw8UJFVf2Mr91t1&rel=0"
                                title="YouTube video player"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerPolicy="strict-origin-when-cross-origin"
                                allowFullScreen>
                            </iframe>
                        </div>
                    </div>

                    <div className={Classes.bufferTwo}></div>

                    <div className={Classes.differentContainer}>
                        <div className={Classes.title}>What We Do Differently</div>
                        <div className={Classes.boxItems}>
                            <TextBox title="Features">
                                <div className={Classes.item1}> <span>Default availability</span> <div><FiInfo /></div> </div>
                                <div className={Classes.item2}> <span>Filter feature</span> <div><FiInfo /></div> </div>
                            </TextBox>
                            <TextBox title="Easy Sharing">
                                Overlap polls can be shared with a link or QR code.
                            </TextBox>
                            <TextBox title="Mobile Friendly">
                                The experience is the same from your phone or laptop.
                            </TextBox>
                            <TextBox title="User Experience">
                                A simple and  intuitive experience compared to other solutions.
                            </TextBox>
                        </div>
                    </div>

                    <div className={Classes.bufferTwo}></div>

                    <div className={Classes.premiumTitle}>Coming soon... 
                        <span className="mx-1"><img className={Classes.Logo} src={getLogo()} alt="Overlap Logo"/></span> <span className={Classes.premium}>Premium</span>
                    </div>
                    <div className={Classes.premiumContainer}>
                        <PremiumTable />
                    </div>

                    <div className={Classes.bufferTwo}></div>
                    
                    <div className={Classes.missionContainer}>
                        <TextWithTitle 
                            title="Our Mission"
                            body="Overlap transforms group meeting planning by simplifying scheduling, removing hassle, and allowing you to focus on what matters most—effortlessly coordinating meetings and reclaiming your time."
                            textLeft={true}
                        />
                        <div className={Classes.graphicWrapperOne}>
                            <img className={Classes.devices} src={getImg("laptopPhone")} alt="" />
                        </div>
                    </div>

                    <div className={Classes.bufferTwo}></div>

                    <div className={Classes.commitmentContainer}>
                        <div className={Classes.graphicWrapperTwo}>
                            <img className={Classes.bubbles} src={getImg("bubbleStack")} alt="" />
                        </div>
                        <TextWithTitle 
                            title="Our Commitment To Our Users"
                            body="We're committed to constant improvement, regularly adding new features based on your feedback."
                            textLeft={true}
                        />
                    </div>

                    <div className={Classes.bufferTwo}></div>

                    <TextWithTitle 
                        title="Our Story"
                        body="Our journey began in college, where we discovered a real need for a group scheduling solution that was easy to use. With this in mind, we started creating a scheduling tool focused on enhancing the user’s experience. Today, our team includes both current college students and recent graduates."
                        textCenter={true}
                    />

                    <div className={Classes.bufferTwo}></div>

                    <div className={Classes.meetTheTeam}>
                        <div className={Classes.title}>Meet Our Team</div>
                        <div className={Classes.team}>
                            {team.map((member,index) => (
                                <div key={index}>
                                    <TeamBubble
                                        img={getImg(String(member.name))}
                                        name={member.name}
                                        role={member.title}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className={Classes.bufferTwo}></div>

                    <div className={Classes.textAndButtonTwo}>
                        <TextWithTitle 
                            title="Learn More"
                            body={"Visit our blog for more information, FAQ, and Release Notes so you’re up to date on everything new with Overlap."}
                            textCenter={true}
                        />
                        <DefaultButton
                            text="Learn More"
                            onClick={navigateToBlog}
                            className={Classes.learn}
                        />
                    </div>

                    <div className={Classes.bufferTwo}></div>

                    <div className={Classes.textAndButtonTwo}>
                        <TextWithTitle 
                            title="Support Us"
                            body={"Your donation supports developing our web app subscription, ensuring ongoing innovation."}
                            textCenter={true}
                        />
                        <DefaultButton
                            text="Donate"
                            // onClick={() => navigate("/signup")}
                            className={Classes.donate}
                        />
                    </div>

                    <div className={Classes.bufferTwo}></div>

                    <div className={Classes.contactUs}>
                        <TextWithTitle 
                            title="Contact Us"
                            body={"We would love to hear from you! Whether it’s feedback on our web app, a question about Overlap, or something else, we’re here to listen."}
                            textCenter={true}
                        />
                        <div className={Classes.socialMedia}>
                            <a href="mailto:karthik@myoverlap.com" target="_blank" rel="noopener noreferrer">
                                <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" alt="email" aria-label="email" aria-hidden="true" dimension="base" className={Classes.socialIcons}>
                                    <circle cx="16" cy="16" r="15.5" stroke="#464646"></circle>
                                    <path d="M1.5 8.67v8.58a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3V8.67l-8.928 5.493a3 3 0 0 1-3.144 0L1.5 8.67Z" fill="#464646" transform="translate(4 4)"/>
                                    <path d="M22.5 6.908V6.75a3 3 0 0 0-3-3h-15a3 3 0 0 0-3 3v.158l9.714 5.978a1.5 1.5 0 0 0 1.572 0L22.5 6.908Z" fill="#464646" transform="translate(4 4)"/>
                                </svg>
                            </a>
                            <a href="https://www.instagram.com/overlap_app/" target="_blank" rel="noreferrer">
                                <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" alt="instagram" aria-label="instagram" aria-hidden="true" dimension="base" className={Classes.socialIcons}>
                                    <circle cx="16" cy="16" r="15.5" stroke="#464646"></circle>
                                    <g>
                                        <path
                                            d="M16 8c2.174 0 2.445.008 3.298.048.852.04 1.432.174 1.942.372a3.9 3.9 0 011.418.922c.406.4.721.884.922 1.418.198.51.332 1.09.372 1.942.038.853.048 1.124.048 3.298s-.008 2.445-.048 3.298c-.04.852-.174 1.432-.372 1.942a3.907 3.907 0 01-.922 1.418c-.4.406-.884.721-1.418.922-.51.198-1.09.332-1.942.372-.853.038-1.124.048-3.298.048s-2.445-.008-3.298-.048c-.852-.04-1.432-.174-1.942-.372a3.911 3.911 0 01-1.418-.922 3.924 3.924 0 01-.922-1.418c-.198-.51-.332-1.09-.372-1.942C8.01 18.445 8 18.174 8 16s.008-2.445.048-3.298c.04-.852.174-1.432.372-1.942.2-.534.515-1.018.922-1.418.4-.407.884-.721 1.418-.922.51-.198 1.09-.332 1.942-.372C13.555 8.01 13.826 8 16 8zm0 4a4 4 0 100 8 4 4 0 000-8zm5.2-.2a1 1 0 10-2 0 1 1 0 002 0zM16 13.6a2.4 2.4 0 110 4.801 2.4 2.4 0 010-4.801z"
                                            fill="#464646"></path>
                                    </g>
                                    <defs>
                                        <clipPath>
                                            <path fill="#fff" transform="translate(4 4)" d="M0 0h24v24H0z"></path>
                                        </clipPath>
                                    </defs>
                                </svg>
                            </a>
                            <a href="https://www.linkedin.com/company/myoverlap/" target="_blank" rel="noreferrer">
                                <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" alt="linkedin" aria-label="linkedin" aria-hidden="true" dimension="base" className={Classes.socialIcons}>
                                    <circle cx="16" cy="16" r="15.5" stroke="#464646"></circle>
                                    <path
                                        d="M10.552 12.42a1.778 1.778 0 100-3.556 1.778 1.778 0 000 3.556zM13.958 13.734h2.946v1.35s.8-1.6 2.975-1.6c1.94 0 3.548.956 3.548 3.87V23.5h-3.053v-5.4c0-1.72-.918-1.908-1.617-1.908-1.451 0-1.7 1.252-1.7 2.132v5.176h-3.1v-9.765zM9.002 13.734h3.1v9.765h-3.1v-9.765z"
                                        fill="#464646"></path>
                                    <path
                                        d="M10.552 12.42a1.778 1.778 0 100-3.556 1.778 1.778 0 000 3.556zM13.958 13.734h2.946v1.35s.8-1.6 2.975-1.6c1.94 0 3.548.956 3.548 3.87V23.5h-3.053v-5.4c0-1.72-.918-1.908-1.617-1.908-1.451 0-1.7 1.252-1.7 2.132v5.176h-3.1v-9.765zM9.002 13.734h3.1v9.765h-3.1v-9.765z"
                                        fill="#464646"></path>
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
                {!signedIn.currentUser && <ResourceFooter />}
            </main>
        </>
    )
}