import {useMeetingContext} from "../../../pages/NewMeetingPage";
import Classes from "./Availability.module.scss";
import AvailabilityCard from "../../AvailabilityCard/AvailabilityCard";
import TimeRange from "../Time/Calender/TimeRange";
import { useContext } from "react";
import { gridToEpoch } from "../../../utilities/dataProcessing";
import { AvailabilityContext } from "../../AvailabilityCard/Subcomponents/AvailabilityContext";
import { useUserSettings } from "context/userSettingsContext";

const MeetingAvailability = (_props) => {
    const context = useMeetingContext();

    const availabilityContext = useContext(AvailabilityContext);
    const serverSettings = useUserSettings();

    function validateValues(timeRange) {
        if (timeRange[0] > timeRange[1]) {
            return {timeRange: "The selected time range is invalid. Please choose a valid time range"};
        } else if (timeRange[1] - timeRange[0] < 3 * 60) {
            return {timeRange: "Please select time range at least 3 hours"};
        }
        return {};
    }

    async function handleTimeRangeChange(newTimeRange) {
        let defaultAvailability = null;
        if (await serverSettings.defaultAvailabilityEnabled) {
            defaultAvailability = await serverSettings.defaultAvailability;
        }
        let availability = gridToEpoch(availabilityContext.intervalGrid, availabilityContext.selected);
        context.dispatch({type: "TIME_RANGE_UPDATE", payload: {
            availability,
            timeRange: newTimeRange.timeRange,
            defaultAvailability,
        }});
        context.dispatch({type: "TIME_RANGE_UPDATE", payload: {
            availability,
            timeRange: newTimeRange.timeRange,
            defaultAvailability,
        }});
    }

    window.context = context;

    return (
        <>
            <h3 className={Classes.subtitle}>Click and Drag</h3>
            <div className={Classes.availabilityCardContainer}>
                <AvailabilityCard
                    data={context.state.gridData}
                    mode={"EDIT"}
                />
            </div>
            <TimeRange
                error={context.state.revealErrors ? validateValues(context.state.currentTimeRange).timeRange : null}
                timeRange={context.state.currentTimeRange}
                onTimeChange={handleTimeRangeChange}
            />
        </>
    );
};

export default MeetingAvailability;
