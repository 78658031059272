import { FiChevronLeft } from 'react-icons/fi';
import Classes from './SettingsPage.module.scss';
import { useNavigate } from 'react-router-dom';
import IOSToggleSwitch from '../Ui/iOSToggleSwitch/iOSToggleSwitch';
import { useEffect, useState } from 'react';
import SearchDropDownInput from '../Ui/FancyDropDownInput/SearchDropDownInput';
import DeleteAccountPopup from './DeleteAccountPopup';
import { useAuth } from '../../context/authContext';
// import { DateTime } from 'luxon';
import { getUserTimeZone } from 'utilities/timezone';
import { timeZonesNames } from '@vvo/tzdb';
import FullPageLoader from 'components/loader/FullPageLoader';
import toast from 'react-hot-toast';
import { useUserSettings } from 'context/userSettingsContext';

export default function SettingsPage() {

    // hooks
    const navigate = useNavigate();
    const authContext = useAuth();
    const serverSettings = useUserSettings();

    // state
    // const [autoTimeZone, setAutoTimeZone] = useState(true);
    const [autoTimeZone,] = useState(true);
    const [timeZone, setTimeZone] = useState(getUserTimeZone());
    const [showDeleteAccountPopup, setShowDeleteAccountPopup] = useState(false);
    const [defaultAvailabilityEnabled, setDefaultAvailabilityEnabled] = useState(null);
    const [hideDefaultAvailabilityToggle, setHideDefaultAvailabilityToggle] = useState(true);

    // handlers
    function handleBackButton(event) {
        event.stopPropagation();
        navigate("/account");
    }

    function handleNewTimeZone(newValue) {
        if (autoTimeZone) return;
        setTimeZone(newValue);

        // do stuff here
    }

    async function handleDefaultAvailabilityEnabledChange() {
        // if it hasn't loaded, don't try to set it
        if (defaultAvailabilityEnabled === null) {
            return;
        }

        // attempt to apply the value to the server
        try {
            setDefaultAvailabilityEnabled(null);
            await serverSettings.setDefaultAvailabilityEnabled(!defaultAvailabilityEnabled);
            setDefaultAvailabilityEnabled(!defaultAvailabilityEnabled);
        } catch (e) {
            console.error('Error occured setting default availability!', e);
            toast.dismiss();
            toast.error('Sorry, something went wrong.');
        }
    }

    // useeffects
    useEffect(() => {
        // get default availability from the server
        async function runAsync() {
            try {
                setDefaultAvailabilityEnabled(await serverSettings.defaultAvailabilityEnabled);
                setHideDefaultAvailabilityToggle(false);
            }
            catch (e) {
                console.warn('Error occured getting default availability!', e);
                toast.dismiss();
                toast.error('Failed to fetch default availability status.');
                setDefaultAvailabilityEnabled(false);
                setHideDefaultAvailabilityToggle(true);
            }
        }

        runAsync();
    }, [authContext.currentUser, serverSettings]);

    // figure out if user has a password that can be changed
    const hasPassword = authContext.currentUser.providerData.some(provider => provider.providerId === "password");
    
    return (
        <>
        <div className={Classes.pageWrapper}>
            <div className={Classes.backButtonRow}>
                <div className={Classes.backButton} onClick={handleBackButton}><FiChevronLeft/></div>
            </div>

            <div className={Classes.pageContent}>
                <div className={`${Classes.sectionContainer} ${Classes.disabled}`} onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                }}>
                    <div className={Classes.inputGroup}>
                        <label className={Classes.inputLabel}>Detect Time Zone</label>
                        <IOSToggleSwitch
                            checked={autoTimeZone}
                            setChecked={() => {
                                // setAutoTimeZone(!autoTimeZone);
                                alert("Overlap currently automatically adjusts to your current time zone. Manual timezone selection is coming soon. In the meantime, you can change your system time settings to adjust the time zone.");
                            }}
                        />
                    </div>
                    <div className={Classes.inputGroup}>
                        <label className={Classes.inputLabel}>Time Zone</label>
                        <SearchDropDownInput
                            disabled={autoTimeZone}
                            options={timeZonesNames}
                            value={timeZone}
                            onChange={handleNewTimeZone}
                            closeOnClick={true}
                        />
                    </div>

                    {/* <div className={Classes.grayedOutBox}></div> */}

                    {/* TEMP TO SHOW COMING SOON TEXT */}
                    {/* <div className={Classes.comingSoonText}>
                        <FiInfo/>
                        <span>Coming soon!</span>
                    </div> */}
                </div>
                
                <div className={Classes.sectionContainer}>
                    <div className={Classes.inputGroup} style={defaultAvailabilityEnabled === null ? {'visibility': 'hidden'}: {}}>
                        <label className={Classes.inputLabel}>
                            Default Availability
                            { defaultAvailabilityEnabled &&
                                <div className={Classes.inputLabelButton} onClick={(e) => {
                                    e.stopPropagation();
                                    navigate("/account/settings/defaultavailability");
                                }}>Edit</div>
                            }
                        </label>
                        { !hideDefaultAvailabilityToggle &&
                            <IOSToggleSwitch
                                checked={defaultAvailabilityEnabled}
                                setChecked={handleDefaultAvailabilityEnabledChange}
                            />
                        }
                        {/** The one below it is a skeleton toggle just to take up the correct amount of space */}
                        { hideDefaultAvailabilityToggle &&
                            <IOSToggleSwitch
                                checked={false}
                            />
                        }
                    </div>
                    {defaultAvailabilityEnabled === null &&
                        <FullPageLoader scale={0.6}/>
                    }
                </div>
                
                { hasPassword &&
                    <div className={Classes.fullWidthButton} onClick={(event) => {
                        event.stopPropagation();
                        navigate("/account/settings/changepassword");
                    }}>Change Password</div>
                }
            </div>
            <div className={Classes.sectionDivider}></div>
            <div className={Classes.accountDeleteButton} onClick={() => setShowDeleteAccountPopup(true)}>Delete Account</div>
        </div>

        { showDeleteAccountPopup &&
            <DeleteAccountPopup onClose={() => setShowDeleteAccountPopup(false)}/>
        }
        </>
    )
}