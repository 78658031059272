import { useContext, useEffect, useState } from 'react';
import Classes from './AvailabilityBubble.module.scss';
import { AvailabilityContext } from './AvailabilityContext';
import { interpolateColor } from '../../../utilities/colors';
import { useSearchParams } from 'react-router-dom';

export default function AvailabilityBubble(props) {

    // CONTEXT
    const availabilityContext = useContext(AvailabilityContext);

    // PROPS
    const { disabled, skipRow, position, colorOpacity } = props;
    let color = interpolateColor('#F5F5F5', '#3EA680', availabilityContext.mode === 'EDIT' ? 0 : colorOpacity);

    // STATE
    const [active, setActive] = useState(false); // whether to show bubble as purple with box shadow
    const [dragging, setDragging] = useState(false); // whether to show bubble as dark purple (when being dragged)
    const [selected, setSelected] = useState(false); // whether to show bubble as purple (selected)

    // DEV FUN FEATURES
    const [queryParams,] = useSearchParams();
    const devModeEnabled = queryParams.get('dev') === 'true';
    const lines = queryParams.get('lines') === 'true';

    let devModeText = false;
    if (devModeEnabled) {
        switch (queryParams.get('mode')) {
            case 'position':
                devModeText = `[${position[0]}, ${position[1]}]`;
                break;
            case 'active':
                devModeText = availabilityContext.mode === 'OVERVIEW' ? active.toString() : dragging.toString();
                break;
            case 'selected':
                devModeText = selected.toString();
                break;
            case 'opacity':
                if (colorOpacity !== undefined) {
                    devModeText = colorOpacity.toFixed(2);
                } else {
                    devModeText = '';
                }
                break;
            case 'color':
                devModeText = color;
                break;
            case 'bubblecolor':
                let startColor = queryParams.get('startcolor')?.toString() ?? 'F5F5F5';
                let endColor = queryParams.get('endcolor')?.toString() ?? '3EA680';
                console.log(startColor, endColor);
                color = interpolateColor(`#${startColor}`, `#${endColor}`, colorOpacity);
                if (colorOpacity === 1) {
                    console.log(color);
                }
                break;
            default:
                break;
        }
    }

    // Update whether the bubble is selected or active
    useEffect(() => {
        
        if (availabilityContext.mode === 'OVERVIEW') {
            // check if this bubble's position value is contained in active
            if (availabilityContext.active === String(position)) {
                setActive(true);
            } else if (active) {
                setActive(false);
            }
        }
        
        if (availabilityContext.mode === 'EDIT') {

            // check if this bubble's position value is contained in active, and if so, set it to dragging.
            // Note that if the mode is EDIT, active is a 2x2 array of booleans, not a single bubble position.

            if (availabilityContext.active.length <= position[0]) return; // if the position is out of bounds, return. This may happen if the grid rerenders with a different size while the user is dragging. If this happens, react will re-call this function and the action will be completed successfully.

            if (availabilityContext.active[position[0]][position[1]]) {
                setDragging(true);
            } else if (dragging) {
                setDragging(false);
            }

            // check if this bubble's position value is in selected, and if so, set it to selected.
            if (availabilityContext.selected[position[0]][position[1]]) {
                setSelected(true);
            } else if (selected) {
                setSelected(false);
            }
        }
        
    }, [availabilityContext.active, availabilityContext.selected, position, availabilityContext.mode, active, dragging, selected]);

    function onClick() {
        if (disabled || skipRow) return;
        availabilityContext.bubbleClicked(position);
    }

    function onPointerDown() {
        availabilityContext.bubblePointerDown(position);
    }

    function onPointerMove(event) {

        // mouse move events are called through element the mouse is moving over
        if (event.pointerType === 'mouse') {
            availabilityContext.bubblePointerMove(position);
        }

        // touch move events are called through the element the touch started on
        else {
            // get the element the pointer is currently hovering over
            let target = document.elementFromPoint(event.clientX, event.clientY);
            
            // get the row and column information of the bubble
            let row = target.getAttribute('data-row') || null;
            let col = target.getAttribute('data-col') || null;

            // if the target is not a bubble, ignore the event
            if (row === null || col === null) return;
            availabilityContext.bubblePointerMove([Number(col), Number(row)]);
        }
        
    }

    function onPointerUp(event) {

        // mouse move events are called through element the mouse is moving over
        if (event.pointerType === 'mouse') {
            availabilityContext.bubblePointerUp(position);
        }

        // touch move events are called through the element the touch started on
        else {
            // get the element the pointer is currently hovering over
            let target = document.elementFromPoint(event.clientX, event.clientY);

            // if there is no target, the pointer has left the window
            if (target === null) {
                availabilityContext.pointerUpOutsideBubble();
                return;
            }

            // get the row and column information of the bubble
            let row = target.getAttribute('data-row') || null;
            let col = target.getAttribute('data-col') || null;

            // if the target is not a bubble, call pointerUpOutsideBubble
            if (row === null || col === null) {
                availabilityContext.pointerUpOutsideBubble();
            } else {
                availabilityContext.bubblePointerUp([Number(col), Number(row)]);
            }
        }
    }

    return (
        <div className={`${Classes.bubbleContainer} ${skipRow ? Classes.skipRow : ''}`}
            onClick={onClick}
            onPointerDown={onPointerDown}
            onPointerMove={onPointerMove}
            onPointerUp={onPointerUp}
            data-row={position[1]}
            data-col={position[0]}
        >

            { /* Disabled Bubble */ }
            { disabled && 
                <div className={`${Classes.bubble} ${Classes.disabled}`}></div>
            }

            { /* Skip Row */ }
            { skipRow &&
                <div style={{height: availabilityContext.skipRowHeight}}></div>
            }

            { /* Normal Bubble */ }
            { !disabled && !skipRow && 
                <div className={`${Classes.bubble} ${selected && !dragging ? Classes.selected : ''} ${active ? Classes.active : ''} ${dragging ? Classes.dragging : ''}`} style={{backgroundColor: color}}>
                    {devModeEnabled && devModeText}
                </div>
            }

            {lines && position[1] % 2 === 1 &&
                <div className={Classes.line}></div>
            }
        </div>
    )
}