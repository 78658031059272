import Classes from './CustomToast.module.scss';
import { Toaster } from 'react-hot-toast';
import { FiCheckSquare, FiAlertOctagon } from 'react-icons/fi';

export function CustomToast() {

    return (
        <Toaster
            position="bottom-center"
            toastOptions={{
                duration: 5000,
                success: {
                    className: Classes.successToast,
                    style: {
                        backgroundColor: '#D9EAE4'
                    },
                    icon: <FiCheckSquare fontSize={16}/>
                },
                error: {
                    className: `${Classes.toast} ${Classes.errorToast}`,
                    style: {
                        backgroundColor: '#F9E4E3'
                    },
                    icon: <FiAlertOctagon fontSize={16}/>
                }
            }}
        />
    )
}