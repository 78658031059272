import { useState } from 'react';
import { FiChevronLeft, FiMoreHorizontal, FiUpload, FiEdit, FiTrash2, FiLogOut, FiMic, FiFilter, FiZap } from 'react-icons/fi';
import DropDown from '../Ui/DropDown/DropDown';
import DropDownOption from '../Ui/DropDown/DropDownOption';
import Classes from './TopBar.module.scss';
import { useUnhandledClick } from '../../utilities/clickHandling';

const TopBar = ({ isCreator, onEdit, onDelete, onLeave, onShare, onBack, onFilter, showEdit = false, showDeleteLeave = false, showShare = false, showBack = false, showPremiumMenu = false }) => {
    const [openMenu, setOpenMenu] = useState(null);

    const trueCount = [showEdit, showDeleteLeave].filter(value => value === true).length;
    const onlyOne = trueCount === 1;
    const hideDropdown = trueCount === 0;

    useUnhandledClick(() => {
        setOpenMenu(null);
    })

    return (
        <div className={Classes.topIconBar}>
            {showBack && (
                <div className={`${Classes.backButton} ${Classes.iconButton}`} onClick={(e) => {
                    onBack();
                    e.preventDefault();
                    e.stopPropagation();
                }}>
                    <FiChevronLeft />
                </div>
            )}

            <div className={Classes.spacer}></div>
            
            {!onlyOne && !hideDropdown &&
                <div className={`${Classes.ellipsisButton} ${Classes.iconButton}`} onClick={(event) => {
                    if (openMenu !== "moreMenu") setOpenMenu("moreMenu");
                    else setOpenMenu(null);
                    event.stopPropagation();
                }}>
                    <FiMoreHorizontal />
                    {openMenu === "moreMenu" && (
                        <div className={Classes.menuContainer} onClick={(e) => {
                            setOpenMenu(null);
                            e.stopPropagation()
                        }}>
                            <DropDown>
                                {showEdit && !onlyOne &&
                                    <DropDownOption text="Edit" icon={<FiEdit />} onClick={onEdit} />
                                }
                                {isCreator && showDeleteLeave && !onlyOne && (
                                    <DropDownOption text="Delete" icon={<FiTrash2 />} onClick={onDelete} className={Classes.deleteOption} />
                                )}
                                {!isCreator && showDeleteLeave && !onlyOne && (
                                    <DropDownOption text="Leave" icon={<FiLogOut />} onClick={onLeave} className={Classes.deleteOption} />
                                )}
                                {!onlyOne &&
                                    <DropDownOption text="????"
                                        icon={<FiMic />}
                                        onClick={() => { window.location.href = "https://www.youtube.com/watch?v=dQw4w9WgXcQ"; }}
                                        style={{ color: "purple" }}
                                        hidden={Math.random() > 0.001}
                                    />
                                }
                            </DropDown>
                        </div>
                    )}
                </div>
            }

            { showPremiumMenu && (
                <div className={`${Classes.ellipsisButton} ${Classes.iconButton}`} onClick={(event) => {
                    if (openMenu !== "premiumMenu") setOpenMenu("premiumMenu");
                    else setOpenMenu(null);
                    event.stopPropagation();
                }}>
                    <FiZap />
                    {openMenu === "premiumMenu" && (
                        <div className={Classes.menuContainer} onClick={(e) => {
                            setOpenMenu(null);
                            e.stopPropagation()
                        }}>
                            <DropDown>
                                <DropDownOption text="Filter" icon={<FiFilter />} onClick={onFilter} />
                            </DropDown>
                        </div>
                    )}
                </div>
            )}

            {onlyOne && showEdit &&
                <div className={`${Classes.ellipsisButton} ${Classes.iconButton}`} onClick={(event) => {
                    onEdit();
                    event.stopPropagation();
                }}>
                    <FiEdit />
                </div>
            }

            {onlyOne && showDeleteLeave && isCreator &&
                <div className={`${Classes.ellipsisButton} ${Classes.iconButton}`} onClick={(event) => {
                    onDelete();
                    event.stopPropagation();
                }}>
                    <FiTrash2 />
                </div>
            }

            {onlyOne && showDeleteLeave && !isCreator &&
                <div className={`${Classes.ellipsisButton} ${Classes.iconButton}`} onClick={(event) => {
                    onLeave();
                    event.stopPropagation();
                }}>
                    <FiLogOut />
                </div>
            }

            {showShare &&
                <div className={`${Classes.shareButton} ${Classes.iconButton}`} onClick={(event) => {
                    event.stopPropagation();
                    onShare();
                }}>
                    <FiUpload />
                </div>
            }
        </div>
    );
};

export default TopBar;
