import { useMemo } from 'react';
import {useSize} from './useSize'; // Adjust the import path as needed

/**
 * A react hook that returns a ref and the height for a spacer that matches the height of the referenced element.
 * The height returned is a sum of the element's height and the additional spacing, unless the element's height is 0, in which case the spacer height is zero.
 * @param {React.MutableRefObject} ref - The ref of the element to match the height of.
 * @param {number} additionalSpacing - Additional spacing to add to the element's height in addition to the normal spacing.
 * @return {number} spacerHeight
 */
export function useHeightSpacer(ref, additionalSpacing = 0) {
    const size = useSize(ref);
    
    const spacerHeight = useMemo(() => {
        if (size.height === 0) return 0;
        else return size.height + additionalSpacing;
    }, [size.height, additionalSpacing]);

    return spacerHeight;
}

/**
 * A react hook that returns a ref and the width for a spacer that matches the width of the referenced element.
 * The width returned is a sum of the element's width and the additional spacing, unless the element's width is 0, in which case the spacer width is zero.
 * @param {React.MutableRefObject} ref - The ref of the element to match the width of.
 * @param {number} additionalSpacing - Additional spacing to add to the element's width in addition to the normal spacing.
 * @return {number} spacerWidth
 */
export function useWidthSpacer(ref, additionalSpacing = 0) {
    const size = useSize(ref);
    
    const spacerWidth = useMemo(() => {
        if (size.width === 0) return 0;
        else return size.width + additionalSpacing;
    }, [size.width, additionalSpacing]);

    return spacerWidth;
}