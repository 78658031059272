import React from "react";
import MobileNav from "../MobileNav/MobileNav";
import Classess from "./Footer.module.scss";
import {useAuth} from "../../context/authContext";
import { useLocation } from 'react-router-dom';

const Footer = () => {
    const authContext = useAuth();
    const path = useLocation().pathname;
    // We have use cases (eg: free up space in the bottom) to restrict the mobile navigation at some pages.
    const navRestrictedPaths = ["/meeting", "/invite", "/editmeeting", "/instagram-redirect", "/account/settings/defaultavailability"];

    const restrictFooter = navRestrictedPaths.some(restrictedPath => path.includes(restrictedPath));

    return (
        <footer className={Classess.Footer}>
            {authContext.currentUser && !restrictFooter && <MobileNav/>}
        </footer>
    );
};

export default Footer;
