import Classes from "./FullPageLoader.module.scss";
import InplaceCircle from "./InplaceCircle/InplaceCircle";

const FullPageLoader = (props) => {
    return (
        <>
            <div className={Classes.centerContainer}>
                <InplaceCircle scale={props.scale || 1.2}/>
                {props.message &&
                    <div className={Classes.loadingText}>{props.messageText || 'Loading'}</div>
                }
            </div>
        </>
    )
}

export default FullPageLoader