import Classes from './AvailabilityHeader.module.scss';
import {useSize} from '../../../utilities/useSize';

export default function AvailabilityHeader(props) {

    // PROPS
    const { headers, timeColumnRef } = props;

    // get size state for timeColumn
    const timeColumnSize = useSize(timeColumnRef);

    return (
        <div className={`${Classes.headerRow} ${timeColumnSize.width ? '' : Classes.hide}`}>
            <div className={Classes.spacer} style={{flexBasis: timeColumnSize.width}}></div>
            { headers.map((header, index) => {
                return (
                    <div className={Classes.header} key={index}>
                        {header.date &&
                            <div className={Classes.date}>{header.date}</div>
                        }
                        <div className={Classes.day}>{header.day}</div>
                    </div>
                )
            })}
        </div>
    );
}