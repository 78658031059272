import { useParams, useSearchParams } from "react-router-dom";
import { useCallback, useEffect, useReducer, useRef } from "react";
import { constants } from "../firebase";
import { useAuth } from "../context/authContext";
import FullPageLoader from "../components/loader/FullPageLoader";
import Classes from "./scss/MeetingOverview.module.scss";
import AvailabilityCard from "../components/AvailabilityCard/AvailabilityCard";
import InplaceCircle from "../components/loader/InplaceCircle/InplaceCircle";
import DeletePopup from "../components/Popup/Presets/DeletePopup";
import LeavePopup from "../components/Popup/Presets/LeavePopup";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header/Header";
import TitleBubble from '../components/MeetingDetails/TitleBubble';
import InvalidMeetingPage from "./InvalidMeetingPage";
import useError from "../utilities/error";
import SharePopup from "../components/Popup/SharePopup/SharePopup";
import { epochToGrid } from "../utilities/dataProcessing";
import useCreateAvailabilityContext from "../components/AvailabilityCard/Subcomponents/useCreateAvailabilityContext";
import { AvailabilityContext } from "../components/AvailabilityCard/Subcomponents/AvailabilityContext";
import { Helmet } from "react-helmet";
import BubbleDetails from "components/Recipient/BubbleDetails";
import TopBar from "components/MeetingDetails/TopBar";
// import PositionedContainer from "components/Utilities/PositionedContainer";
// import CircularButton from "components/Ui/Button/CircularButton";
// import { FiChevronUp } from "react-icons/fi";
// import PremiumPopup from "components/Popup/Premium Popup/PremiumPopup";
import FilterPopup from "components/Popup/FilterPopup/FilterPopup";

const Popups = {
    NONE: null,
    DELETE: 'delete',
    LEAVE: 'leave',
    SHARE: 'share',
    PREMIUM_FEATURES: 'premium_features',
    FILTER: 'filter',
}

/**
 * @typedef {Object} MeetingOverviewState
 * @property {Object} meetingInfo - Info about the meeting, returned from the server
 * @property {Object} gridInfo - Information about the grid, after processing the epoch data
 * @property {boolean} moreMenuOpen - Whether the more menu is open
 * @property {keyof Popups} shownPopup - The popup that is currently shown
 * @property {boolean} titleCardExpanded - Whether the title card is expanded
 * @property {boolean} hideBubbleDetails - Whether the bubble details are hidden
 */

const DispatchType = {
    MEETING_FETCHED: 'MEETING_FETCHED',
    MEETING_NOT_FOUND: 'MEETING_NOT_FOUND',
    TOGGLE_TITLE_CARD_EXPANSION: 'TOGGLE_TITLE_CARD_EXPANSION',
    OPEN_POPUP: 'OPEN_POPUP',
    CLOSE_POPUPS: 'CLOSE_POPUPS',
    CONTRACT_TITLE_BUBBLE: 'CONTRACT_TITLE_BUBBLE',
    FILTER_CHANGED: 'FILTER_CHANGED',
    FILTER_CLOSED: 'FILTER_CLOSED',
}

/**
 * @typedef {Object} MeetingOverviewReducerAction
 * @property {keyof DispatchType} type - The type of action to perform
 * @property {any} payload - The payload for the action
 */

/**
 * @callback MeetingOverviewDispatch
 * @param {MeetingOverviewReducerAction} action - The action to perform
 * @returns {void}
 */

/**
 * State manager for the meeting overview page.
 * @param {MeetingOverviewState} state 
 * @param {MeetingOverviewReducerAction} action 
 * @returns 
 */
function MeetingOverviewReducer(state, action) {

    switch (action.type) {

        /**
         * Called when the server has set the meeting info and grid info.
         */
        case DispatchType.MEETING_FETCHED:
            return {
                ...state,
                meetingInfo: action.payload.meetingInfo,
                gridInfo: action.payload.gridInfo,
            }
        
        /**
         * Called when the the page wants to render a "Meeting Not Found" page.
         * This is essentially the 404 page.
         */
        case DispatchType.MEETING_NOT_FOUND:
            return {
                ...state,
                meetingInfo: null,
                gridInfo: null,
            }
        
        /**
         * Called when the title card is clicked, and expansion is toggled.
         */
        case DispatchType.TOGGLE_TITLE_CARD_EXPANSION:
            return {
                ...state,
                titleCardExpanded: !state.titleCardExpanded,

                // if the title card is expanded, close all popups except filter
                shownPopup: !state.titleCardExpanded ? (state.shownPopup === Popups.FILTER ? state.shownPopup : Popups.NONE) : state.shownPopup,
                hideBubbleDetails: !state.titleCardExpanded,
            }
        
        /**
         * Called when the page wants to open the a popup.
         * When called, payload should be a key of the Popups object.
         */
        case DispatchType.OPEN_POPUP:
            if (action.payload === Popups.FILTER) {
                return {
                    ...state,
                    shownPopup: action.payload,
                    hideBubbleDetails: true,
                }
            }
            else {
                return {
                    ...state,
                    shownPopup: action.payload,
                }
            }
        
        /**
         * Called when the page wants to close all popups.
         */
        case DispatchType.CLOSE_POPUPS:
            return {
                ...state,
                shownPopup: Popups.NONE,
                hideBubbleDetails: false,
            }
        
        /**
         * Called when the page wants the title bubble to stop being expanded.
         */
        case DispatchType.CONTRACT_TITLE_BUBBLE:
            if (!state.titleCardExpanded) return state;
            return {
                ...state,
                titleCardExpanded: false,
                hideBubbleDetails: false,
            }
        
        case DispatchType.FILTER_CHANGED:
            return {
                ...state,
                gridInfo: epochToGrid(state.meetingInfo, parseInt(action.payload.intervalLength, 10), action.payload.currentUserUID, null, null, null, action.payload.filteredUIDs),
                hideBubbleDetails: action.payload.filteredUIDs.length === 0,
            }
        
        case DispatchType.FILTER_CLOSED:
            return {
                ...state,
                shownPopup: Popups.NONE,
                gridInfo: epochToGrid(state.meetingInfo, parseInt(action.payload.intervalLength, 10), action.payload.currentUserUID),
                hideBubbleDetails: false,
            }

        default:
            return state;
    }
}

const MeetingOverview = () => {
    const { meetingId } = useParams(); // get meeting id from the url
    const authContext = useAuth(); // get the information about the logged in user
    const navigate = useNavigate(); // get function for page navigation
    const error = useError();

    // create state variables
    // the page will be rerendered if any of these change
    /** @type {[MeetingOverviewState, MeetingOverviewDispatch]} */
    const [state, dispatch] = useReducer(MeetingOverviewReducer, {
        meetingInfo: undefined,
        gridInfo: undefined,
        shownPopup: null,
        titleCardExpanded: false,
        hideBubbleDetails: false,
    });

    const availabilityCardRef = useRef(null);
    const mainRef = useRef(null);

    // DEV MODE STUFF
    const [searchParams,] = useSearchParams();

    /**
     * Runs when the page is loaded or the meeting id changes.
     * 
     * Fetches meeting information from the server and updates page state.
     * If meeting information is updated successfully, meetingInfo and gridInfo will be set and the page will render the grid.
     * If meeting information fails to fetch sucessfully, meetingInfo and gridInfo will be set to null and the page will render the 404 page.
     */
    useEffect(() => {
        async function runEffect() {
            // fetch the overlap information from the server and update the page
            fetch(`${constants.functionsBaseUrl}/meeting/${meetingId}/overlaps`, {
                headers: {
                    Authorization: `Bearer ${await authContext.currentUser.getIdToken()}`,
                },
            }).then(res => res.json()).then(res => {
                if (res.code === 200) {
                    // convert epoch data to grid data
                    const intervalLength = searchParams.get('intervalLength') ?? '30';
                    let gridData = epochToGrid(res.data, parseInt(intervalLength), authContext.currentUser.uid);
                    dispatch({ type: DispatchType.MEETING_FETCHED, payload: { meetingInfo: res.data, gridInfo: gridData }});
                    return;
                }
                if (res.code === 404) return dispatch({ type: DispatchType.MEETING_NOT_FOUND });
                error('GODFATHER', res.code, "Meeting overlaps fetch returned an invalid response.");
            }).catch(err => {
                console.error(err);
                error('INVINCIBLE', 500, "An error occurred when fetching meeting overlaps.");
            });
        }

        runEffect();

        // eslint-disable-next-line
    }, [authContext.currentUser, meetingId]);

    /**
     * Called when the user clicks the delete button in the confirm popup.
     * 
     * Sends a request to the server to delete the meeting.
     * If the request succeeds, the user will be redirected to the home page.
     * If the request fails, the page will render 404 (meeting not found).
     */
    async function deleteMeeting() {
        fetch(`${constants.functionsBaseUrl}/meeting/${meetingId}`, {
            headers: {
                Authorization: `Bearer ${await authContext.currentUser.getIdToken()}`,
            },
            method: "DELETE"
        }).then(res => res.json()).then(res => {
            if (res.code === 200) return navigate('/home');
            if (res.code === 403) return dispatch({ type: DispatchType.MEETING_NOT_FOUND })
            error('GARBAGE', res.code, "Meeting deletion returned an invalid response.");
        }).catch(err => {
            console.error(err);
            error('WATERSLIDE', 500, "An error occurred when deleting a meeting.");
        });
    }

    /**
     * Called when the user clicks the leave button in the confirm popup.
     * 
     * Sends a request to the server to remove the user from the meeting.
     * If the request succeeds, the user will be redirected to the home page.
     * If the request fails, the page will render 404 (meeting not found).
     */
    async function leaveMeeting() {
        fetch(`${constants.functionsBaseUrl}/meeting/${meetingId}/participant/self`, {
            headers: {
                Authorization: `Bearer ${await authContext.currentUser.getIdToken()}`,
            },
            method: "DELETE"
        }).then(res => res.json()).then(res => {
            if (res.code === 200) return navigate('/home');
            if (res.code === 403) return dispatch({ type: DispatchType.MEETING_NOT_FOUND });
            error('PARLIMENT', res.code, "Recieved an invalid response when attempting to leave a meeting.");
        }).catch(err => {
            console.error(err);
            error('FORTUNATE', 500, "An error occurred when leaving a meeting.");
        });
    }

    const handleNewFilterData = useCallback((filteredUIDs) => {
        dispatch({ type: DispatchType.FILTER_CHANGED, payload: {filteredUIDs, currentUserUID: authContext.currentUser.uid, intervalLength: 30} });
    }, [dispatch, authContext.currentUser.uid])

    const handleFilterClosed = useCallback(() => {
        dispatch({ type: DispatchType.FILTER_CLOSED, payload: {currentUserUID: authContext.currentUser.uid, intervalLength: 30} });
    }, [dispatch, authContext.currentUser.uid])

    const availabilityContext = useCreateAvailabilityContext("OVERVIEW", state.gridInfo);

    // memoized functions
    const onBubbleDetailsOpen = useCallback(() => { dispatch({ type: DispatchType.CONTRACT_TITLE_BUBBLE })}, []);

    useEffect(() => {
        if (state.hideBubbleDetails && availabilityContext.active) {
            availabilityContext.clearActive();
        }
    }, [state.hideBubbleDetails, availabilityContext]);

    // if the meeting info isn't ready yet, show the loading screen
    // this is where the loading screen code goes
    if (state.meetingInfo === undefined) {
        return (
            <>
                <Helmet>
                    <title>Loading...</title>
                </Helmet>
                <FullPageLoader message="Overlap is fetching the meeting information for you, It may take few seconds!" />
            </>
        );
    }

    // meeting info is set to null if the meeting cannot be found on the server
    // this is essentially the 404 page.
    if (state.meetingInfo === null) {
        return (
            <>
                <Helmet>
                    <title>Meeting Not Found</title>
                </Helmet>
                <InvalidMeetingPage />
            </>
        );
    }

    // set a variable for the meeting invite link
    // this is used in the share menu
    const meetingInvite = `${window.location.origin}/t/invite?meeting=${meetingId}`;

    // find the user data for the leader of the meeting
    const meetingLeader = state.meetingInfo.members.find(member => member.role === 0);

    // set a variable for if the current user is the creator of the meeting
    const isCreator = meetingLeader.uid === authContext.currentUser.uid;

    return (
        <>
            <Helmet>
                <title>{state.meetingInfo.title}</title>
            </Helmet>
            <AvailabilityContext.Provider value={availabilityContext}>
                <Header />
                <main className={`${Classes.noHeader}`} ref={mainRef} onClick={() => {
                    if (state.shownPopup === Popups.FILTER) return;
                    dispatch({ type: DispatchType.CLOSE_POPUPS })
                }}>
                    {/* Top Bar */}
                    <TopBar
                        isCreator={isCreator}
                        onEdit={() => { navigate(`/editmeeting?meeting=${meetingId}`) }}
                        onDelete={() => { dispatch({ type: DispatchType.OPEN_POPUP, payload: Popups.DELETE }) }}
                        onLeave={() => { dispatch({ type: DispatchType.OPEN_POPUP, payload: Popups.LEAVE }) }}
                        onShare={() => { dispatch({ type: DispatchType.OPEN_POPUP, payload: Popups.SHARE })}}
                        onBack={() => { navigate('/home') }}
                        onFilter={() => { dispatch({ type: DispatchType.OPEN_POPUP, payload: Popups.FILTER }) }}
                        showDeleteLeave={true}
                        showEdit={true}
                        showBack={true}
                        showShare={true}
                        showPremiumMenu={true}
                    />

                    {/* Title Bubble */}
                    <div className={Classes.titleBubbleContainer}>
                        <TitleBubble
                            meetingInfo={state.meetingInfo}
                            gridInfo={state.gridInfo}
                            expanded={state.titleCardExpanded}
                            setExpanded={() => dispatch({ type: DispatchType.TOGGLE_TITLE_CARD_EXPANSION })}
                        />
                    </div>

                    <div className={Classes.clickHelpPrompt}>
                        Click a Bubble to View Availability
                    </div>

                    {/* Availability Card */}
                    <div className={Classes.availabilityCardContainer} ref={availabilityCardRef}>
                        {!state.gridInfo && <InplaceCircle />}
                        {state.gridInfo &&
                            <>
                                <AvailabilityCard
                                    data={state.gridInfo}
                                    mode={'OVERVIEW'}
                                />
                                {/* { state.shownPopup === Popups.NONE && !availabilityContext.active &&
                                    <div className={Classes.premiumFeaturesContainer}>
                                        <PositionedContainer location='southeast'>
                                            <CircularButton
                                                size={56}
                                                icon={<FiChevronUp size={22}/>}
                                                onClick={(e) => {
                                                    dispatch( { type: DispatchType.OPEN_POPUP, payload: Popups.PREMIUM_FEATURES });
                                                    e.stopPropagation();
                                                }}
                                                style={{ pointerEvents: 'auto' }}
                                            />
                                        </PositionedContainer>
                                    </div>
                                } */}
                            </>
                        }
                    </div>

                    {/* Bubble Availability Popup */}
                    { (state.shownPopup === Popups.NONE || state.shownPopup === Popups.FILTER) && !state.hideBubbleDetails &&
                        <BubbleDetails
                            gridInfo={state.gridInfo}
                            openHandler={onBubbleDetailsOpen}
                            bottomSpacing={state.shownPopup === Popups.FILTER ? 70 : null}
                        />
                    }

                    {/* Confirm Popup */}
                    { state.shownPopup === Popups.DELETE &&
                        <DeletePopup
                            onCancel={() => { dispatch({ type: DispatchType.CLOSE_POPUPS }) }}
                            onDelete={deleteMeeting}
                        />
                    }
                    { state.shownPopup === Popups.LEAVE &&
                        <LeavePopup
                            onCancel={() => { dispatch({ type: DispatchType.CLOSE_POPUPS }) }}
                            onLeave={leaveMeeting}
                        />
                    }
                    { state.shownPopup === Popups.SHARE &&
                        <SharePopup
                            shareText={`You’re invited to join me for “${state.meetingInfo.title}”. Click here to add all times you’re available to meet.`}
                            url={meetingInvite}
                            onClose={() => { dispatch({ type: DispatchType.CLOSE_POPUPS }) }}
                        />
                    }
                    {/* { state.shownPopup === Popups.PREMIUM_FEATURES &&
                        <PremiumPopup
                            onClose={() => { dispatch({ type: DispatchType.CLOSE_POPUPS }) }}
                            onOpen={(popup) => {
                                switch (popup) {
                                    case 'filter':
                                        dispatch({ type: DispatchType.OPEN_POPUP, payload: Popups.FILTER });
                                        break;
                                    default:
                                        break;
                                }
                            }}
                            availabilityCardRef={availabilityCardRef}
                        />
                    } */}
                    { state.shownPopup === Popups.FILTER &&
                        <FilterPopup
                            users={state.meetingInfo.members}
                            onClose={handleFilterClosed}
                            onFilterChange={handleNewFilterData}
                        />
                    }
                </main>
            </AvailabilityContext.Provider>
        </>
    )
}

export default MeetingOverview