
export default function FlexContainer({ children, direction, gap, horizontalAlign, verticalAlign, className, fullWidth, fullHeight, style, onClick }) {

    let styles = {
        ...style,
        flexDirection: direction
    };

    if (direction === 'row') {
        if (horizontalAlign === 'left') {
            styles.justifyContent = 'flex-start';
        }
        else if (horizontalAlign === 'middle') {
            styles.justifyContent = 'center';
        }
        else if (horizontalAlign === 'right') {
            styles.justifyContent = 'flex-end';
        }
        else if (horizontalAlign === 'space') {
            styles.justifyContent = 'space-between';
        }

        if (verticalAlign === 'top') {
            styles.alignItems = 'flex-start';
        }
        else if (verticalAlign === 'middle') {
            styles.alignItems = 'center';
        }
        else if (verticalAlign === 'bottom') {
            styles.alignItems = 'flex-end';
        }
        else if (verticalAlign === 'space') {
            styles.alignItems = 'space-between';
        }
    }

    else if (direction === 'column') {
        if (horizontalAlign === 'left') {
            styles.alignItems = 'flex-start';
        }
        else if (horizontalAlign === 'middle') {
            styles.alignItems = 'center';
        }
        else if (horizontalAlign === 'right') {
            styles.alignItems = 'flex-end';
        }
        else if (horizontalAlign === 'space') {
            styles.alignItems = 'space-between';
        }

        if (verticalAlign === 'top') {
            styles.justifyContent = 'flex-start';
        }
        else if (verticalAlign === 'middle') {
            styles.justifyContent = 'center';
        }
        else if (verticalAlign === 'bottom') {
            styles.justifyContent = 'flex-end';
        }
        else if (verticalAlign === 'space') {
            styles.justifyContent = 'space-between';
        }
    }

    if (fullWidth) {
        styles.width = '100%';
    }

    if (fullHeight) {
        styles.height = '100%';
    }

    return (
        <div className={className} style={{
            display: 'flex',
            gap: `${gap}px`,
            ...styles
        }} onClick={onClick}>
            { children }
        </div>
    )
}