import React, {useContext, useEffect, useState} from "react";
import Classes from './MeetingCreationCompleted.module.scss';
import {Link, useSearchParams} from "react-router-dom";
import {constants} from "../../../firebase";
import {fireApiSuccessEvent} from "../../../analytics";
import {useAuth} from "../../../context/authContext";
import FullPageLoader from "../../loader/FullPageLoader";
import {useMeetingContext} from "../../../pages/NewMeetingPage";
import useError from "../../../utilities/error";
import ShareMeetingStack from "../../Ui/ShareMeetingStack/ShareMeetingStack";
import SharePopup from "../../Popup/SharePopup/SharePopup";
import { FiCheckCircle } from "react-icons/fi";
import {AnalyticsApiEvent} from "../../../enum/analyticsEvent";
import { convertMinutesToTimeRange, convertToDateString, generateEpochRange, generateIntervalGrid, gridToEpoch } from "../../../utilities/dataProcessing";
// import NotificationPermission from "../../Notification/NotificationPermission";
import { AvailabilityContext } from "../../AvailabilityCard/Subcomponents/AvailabilityContext";
import MeetingQuotaMet from "./MeetingQuotaMet";

const MeetingCreationComplete = () => {
    const context = useMeetingContext();
    const authContext = useAuth();
    const [meetingResponse, setMeetingResponse] = useState(null);
    const [showQR, setShowQR] = useState(false);
    const [showMeetingQuotaMet, setShowMeetingQuotaMet] = useState(false);
    const error = useError();

    const [searchParams, ] = useSearchParams();

    const testMode = searchParams.get("testMode") === "true";

    if (testMode) {
        console.debug("WARNING! Test Mode is enabled. Meeting will not actually be created, and meeting url will be falsified.");
    }

    const availabilityContext = useContext(AvailabilityContext);

    useEffect(() => {
        async function runEffect() {

            let availability = gridToEpoch(availabilityContext.intervalGrid, availabilityContext.selected);

            // build meeting creation request
            const range = generateEpochRange(generateIntervalGrid(context.state.selectedDates.map(date => convertToDateString(date)), convertMinutesToTimeRange(context.state.currentTimeRange), 30));
            const requestBody = {
                title: context.state.meetingTitle,
                description: context.state.meetingDescription,
                range: range,
                availability,
            };

            // do not actually send request if you are in test mode
            // instead, just set the meeting response to a fake value
            if (testMode) {
                setMeetingResponse({
                    code: 200,
                    status: "Success",
                    data: {
                        "mid": "0123456789abcd"
                    }
                })
                return;
            }

            fetch(`${constants.functionsBaseUrl}/meeting`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${await authContext.currentUser.getIdToken()}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(requestBody),
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res.code === 200) {
                        fireApiSuccessEvent(AnalyticsApiEvent.MEETING_CREATION);
                        if (requestBody.description) fireApiSuccessEvent(AnalyticsApiEvent.MEETING_CREATION_WITH_DESCRIPTION);
                        return setMeetingResponse(res);
                    }
                    if (res.code === 403) {
                        // meeting quota has been met
                        setShowMeetingQuotaMet(true);
                        return;
                    }
                    error('MONITOR', res.code, 'Meeting creation returned an invalid response.');
                })
                .catch((err) => {
                    console.error(err);
                    error('HALO', 500, 'Failed to create meeting.');
                });
        }

        runEffect();

        // eslint-disable-next-line
    }, [authContext.currentUser]);

    function handleShareStackClick(string) {
        if (string === "QRShare") {
            setShowQR(true);
        }
    }

    if (showMeetingQuotaMet) {
        return <MeetingQuotaMet/>;
    }

    if (!meetingResponse) {
        return (
            <FullPageLoader message="Overlap is setting up a meeting for you, It may take few seconds!"/>
        );
    }

    const meetingInvite = `${window.location.origin}/t/invite?meeting=${meetingResponse.data.mid}`;

    const meetingQuote = `You’re invited to join me for “${context.state.meetingTitle}”. Click here to add all times you’re available to meet.`;

    return (
        <>
            <div className={Classes.CompleteWrap}>
                <div className={Classes.titleRow}>
                    <div className={Classes.title}>Success!</div>
                    <div className={Classes.successIcon}><FiCheckCircle/></div>
                </div>
                <div className={Classes.spacingContainer}>
                    <div className={Classes.shareBox}>
                        <div className={Classes.shareTitle}>Ready to share your meeting?</div>
                        <ShareMeetingStack
                            url={meetingInvite}
                            shareText={meetingQuote}
                            onClick={handleShareStackClick}
                        />
                    </div>

                    <div className={Classes.meetingInfo}>
                        {/* done function  */}
                        <p className={Classes.completedText}>
                            You can view existing meetings from the home page.
                        </p>
                        <div className={Classes.buttonContainer}>
                            <Link to={`/meeting/${meetingResponse.data.mid}`}>
                                <button className={Classes.doneButton}>
                                    Done
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            {/* meeting info box */}
            {showQR &&
                <SharePopup
                    url={meetingInvite}
                    shareText={meetingQuote}
                    onClose={() => setShowQR(false)}
                    qrOnly={true}
                />
            }

            {/* <NotificationPermission message="We'd like to send you notifications when a new participant is added to your meetings."/> */}
        </>

    );
};


export default MeetingCreationComplete