import React, { useState } from "react";
import Header from "../components/Header/Header";
import getLogo from "../utilities/getLogo";
import Classes from "./scss/WelcomePage.module.scss";
import {useNavigate} from 'react-router-dom';
import BubbleBackground from '../components/Ui/BubbleBackground/BubbleBackground';
import ResourceFooter from "../components/resourceFooter/ResourceFooter";
import { Helmet } from "react-helmet";
import GoogleBtn from "components/Ui/LoginWithBtn/Google/GoogleBtn";
import { useAuth } from "context/authContext";
import useError from "utilities/error";
import SignInWithGoogleNotAvailabilePopup from "components/Popup/BottomPopup/SignInWithGoogleNotAvailabilePopup";

const WelcomeHomePage = () => {
    const navigate = useNavigate();

    const authContext = useAuth();
    const error = useError();
    const [showBrowserPopup, setShowBrowserPopup] = useState(false);

    // is in app browser
    const standalone = window.navigator.standalone;
    const userAgent = window.navigator.userAgent.toLowerCase();
    const safari = /safari/.test( userAgent );
    const ios = /iphone|ipod|ipad/.test( userAgent );

    const isInAppBrowser = !standalone && !safari && ios;

    async function logInWithDelegate(delegate) {
        // TODO : punch loader
        try {
            await delegate();
        } catch (e) {
            // check if error has code issued by firebase
            if (e.code === 'auth/popup-closed-by-user') {
                // user closed the popup, don't freak out, it's okay
                return;
            }

            console.error(e);
            error('SCORPION', 500, 'An error occurred when awaiting delegate.');
        }
        // TODO : release loader
    }

    async function logInWithGoogle(e) {
        if (isInAppBrowser) {
            e.preventDefault();
            setShowBrowserPopup(true);
            return;
        }
        else await logInWithDelegate(authContext.logInUsingGoogle);
    }
    return (
        <>
            <Helmet>
                <title>Welcome to Overlap</title>
            </Helmet>
            <Header/>
            <BubbleBackground/>
            <main className={Classes.customMain}>
                <div className={Classes.pageContainer}>
                    <div className={Classes.titleContainer}>
                        <h1 className={Classes.secTitle}>Welcome To</h1>
                        <div className={Classes.logoWrap}>
                            <img className={Classes.Logo} src={getLogo()} alt="Overlap Logo"/>
                        </div>
                        <h2 className={Classes.slogan}>
                            Find <span>overlapping</span> times to meet
                        </h2>
                    </div>
                    <div className={Classes.bottomHalfContainer}>
                        <div className={Classes.buttonContainer}>
                            <div className={Classes.buttonWrap}>
                                <GoogleBtn className={Classes.googleButton} click={logInWithGoogle} />
                                <button className={Classes.signUpButton} onClick={() => {
                                    navigate('/signup');
                                }}>Sign Up</button>
                                <button className={Classes.loginButton} onClick={() => {
                                    navigate('/login');
                                }}>Log In</button>
                            </div>
                        </div>
                        <div className={Classes.videoContainer}>
                            <h2 className={Classes.caption}>
                                What is <img src={getLogo()} alt="Overlap Logo"/>?
                            </h2>
                            <div className={Classes.videoWrapper}>
                                <iframe
                                    width="560" height="315"
                                    src="https://www.youtube.com/embed/xnz9impK8tg?si=9qw8UJFVf2Mr91t1&rel=0"
                                    title="YouTube video player"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    referrerPolicy="strict-origin-when-cross-origin"
                                    allowFullScreen>
                                </iframe>
                            </div>
                        </div>
                    </div>
                </div>
                <ResourceFooter/>
            </main>
            { showBrowserPopup && 
                <SignInWithGoogleNotAvailabilePopup
                    onClose={() => setShowBrowserPopup(false)}
                />
            }
        </>
    );
};

export default WelcomeHomePage;
