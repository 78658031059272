import {useAuth} from "../../context/authContext";
import {Navigate, Outlet} from 'react-router-dom';

export default function PublicRoute() {
    const authContext = useAuth();
    return authContext.currentUser ? (
        <Navigate to={(new URLSearchParams(window.location.search)).get("redirect") || "/home"}/>
    ) : (
        <Outlet/>
    );
}