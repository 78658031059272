import React, {useRef, useEffect} from "react";
import Classess from "./Recipient.module.scss";
import {Form} from "react-bootstrap";
import {FiCornerDownRight} from "react-icons/fi";
import {useNavigate} from "react-router-dom";
import GoogleBtn from "../Ui/LoginWithBtn/Google/GoogleBtn";
import getLogo from "../../utilities/getLogo";


import FacebookBtn from "../Ui/LoginWithBtn/Facebook/FacebookBtn";
import {useAuth} from "../../context/authContext";
import useError from "../../utilities/error";

const Login = (props) => {
    const authContext = useAuth();

    useEffect(() => {
        if (authContext.currentUser) props.onSuccess();
    }, [authContext, props]);

    const emailRef = useRef();
    const passwordRef = useRef();
    const navigate = useNavigate();
    const error = useError();

    async function logIn(e) {
        e.preventDefault();
        // TODO : punch loader
        try {
            await authContext.logIn(emailRef.current.value, passwordRef.current.value);
            navigate((new URLSearchParams(window.location.search)).get("redirect") || "/home");
        } catch (e) {
            console.error(e);
            error('FASCINATION', 500, 'An error occurred while attempting to log in.');
        }
        // TODO : release loader
    }

    async function logInWithDelegate(delegate) {
        // TODO : punch loader
        try {
            await delegate();
        } catch (e) {
            console.error(e);
            error('SHRIMP', 500, 'An error occurred when awaiting delegate.');
        }
        // TODO : release loader
    }

    async function logInWithGoogle(_) {
        logInWithDelegate(authContext.logInUsingGoogle)
    }

    async function logInWithFacebook(_) {
        logInWithDelegate(authContext.logInUsingFacebook)
    }

    return (
        <div className={Classess.formWrap}>
            <div className=" w-100 ">
                <div>
                    <div className={Classess.formContainer}>
                        <legend className={Classess.legend}>
                            Welcome To{" "}
                            <img
                                className={Classess.Logo}
                                src={getLogo()}
                                alt="Verlap Logo"
                            />{" "}
                        </legend>
                        {/* google button  */}
                        <GoogleBtn click={logInWithGoogle}/>
                        <br/>
                        <FacebookBtn click={logInWithFacebook}/>
                        {/* or text  */}
                        <p className=" text-16px my-3 text-center ">or</p>
                        <Form className={``}>
                            <Form.Group>
                                <Form.Control
                                    ref={emailRef}
                                    className=" round-element bg-light-gray-btn border-0 mb-3"
                                    type="email"
                                    placeholder="Email"
                                />
                                <Form.Control
                                    ref={passwordRef}
                                    className=" round-element bg-light-gray-btn border-0  "
                                    type="password"
                                    placeholder="Password"
                                />
                            </Form.Group>
                            <Form.Group
                                className=" submit-wrap d-flex justify-content-between align-items-center px-3">
                                <a
                                    href="/"
                                    className=" text-light-gray text-12px"
                                >
                                    Forgot password?
                                </a>
                                <button onClick={logIn} className={Classess.btn}>
                                    log in
                                    <FiCornerDownRight
                                        strokeWidth="3.5px"
                                        className=" ms-1"
                                    />
                                </button>
                            </Form.Group>
                        </Form>
                    </div>
                </div>
                <p className=" text-center text-12px my-20px">Or</p>
                <div className=" text-center ">
                    <button onClick={props.signup} className={`${Classess.greenBtn}`}>
                        Sign Up With Email
                    </button>
                </div>
            </div>
            {/* how overlap help  */}
            {/* <HowOverlapHelping/> */}
        </div>

        // <PortalMask>
        //     <div className={Classess.formWrap}>
        //         <FormTopBar />
        //         {/* google btn  */}
        //         <div className={Classess.googleBtn}>
        //             <GoogleBtn />
        //         </div>
        //         <h1 className={Classess.orSm}>Or</h1>
        //         {/* btn wrap  */}
        //         <div>
        //             <Form>
        //                 <Form.Group className=" mb-3 ">
        //                     <Form.Control text="email" placeholder="Email" />
        //                 </Form.Group>
        //                 <Form.Group className=" mb-3 ">
        //                     <Form.Control text="text" placeholder="Password" />
        //                 </Form.Group>
        //                 <Form.Group className=" d-flex align-items-center justify-content-between px-3 ">
        //                     <Link className={Classess.forgotPass}>
        //                         forgot password?
        //                     </Link>
        //                     <button className={Classess.btn}>
        //                         log in
        //                         <FiCornerDownRight
        //                             strokeWidth="3.5px"
        //                             className=" ms-1"
        //                         />
        //                     </button>
        //                 </Form.Group>
        //             </Form>
        //         </div>
        //         <h1 className={Classess.or}>or</h1>
        //         <div className=" text-center ">
        //             <button onClick={props.signup} className={`${Classess.greenBtn}`}>
        //                 Sign Up With Email
        //             </button>
        //         </div>
        //     </div>
        // </PortalMask>
    );
};

export default Login;
