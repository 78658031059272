import { useEffect, useReducer } from 'react';
import { AvailabilityContext } from '../components/AvailabilityCard/Subcomponents/AvailabilityContext';
import useCreateAvailabilityContext from '../components/AvailabilityCard/Subcomponents/useCreateAvailabilityContext';
import Classes from './scss/InvitePage.module.scss';
import TopBar from '../components/MeetingDetails/TopBar';
import Header from '../components/Header/Header';
import TitleBubble from '../components/MeetingDetails/TitleBubble';
import { useAuth } from '../context/authContext';
import useError from '../utilities/error';
import { epochToGrid, gridToEpoch, userInMeeting } from '../utilities/dataProcessing';
import FullPageLoader from '../components/loader/FullPageLoader';
import InvalidMeetingPage from './InvalidMeetingPage';
import AvailabilityCard from '../components/AvailabilityCard/AvailabilityCard';
import InplaceCircle from '../components/loader/InplaceCircle/InplaceCircle';
import SharePopup from '../components/Popup/SharePopup/SharePopup';
import { getMeetingOverlaps, joinMeeting, updateMeetingAvailability } from '../utilities/APIs';
import DefaultButton from '../components/Ui/Button/DefaultButton';
import { FiChevronRight } from 'react-icons/fi';
import ConfirmPopup from '../components/Popup/ConfirmPopup/ConfirmPopup';
import BottomPopup from '../components/Popup/BottomPopup/BottomPopup';
import LoginWithGuest from '../components/Recipient/LoginWithGuest';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import BubbleDetails from '../components/Recipient/BubbleDetails';
import { Helmet } from 'react-helmet';
import { useUserSettings } from 'context/userSettingsContext';
import ViewAvailability from 'components/Recipient/ViewAvailability';
import MeetingBubble from 'components/MeetingDetails/MeetingBubble';

/**
 * The InviteReducer handles all the state for the invite page. This state machine is
 * much easier to handle than invidivual states for all the different things going on in
 * the page. Any button clicks or other events should dispatch an action to this reducer.
 * This system allows the entire page's behavior to be controlled from this one function.
 */
function InviteReducer(state, action) {

    switch (action.type) {

        /**
         * Updates the meetingInfo and gridInfo states with provided data.
         * Should be called when the meeting information is successfully
         * fetched from the server.
         * 
         * Payload:
         * meetingInfo: contains the meeting information directly from the server (res.data)
         * currentUserUid: the uid of the current user
         */
        case 'UPDATE_MEETING_FROM_SERVER':
            // process meeting data into grid data
            let gridData = epochToGrid(action.meetingInfo, 30, action.currentUserUid, state.guestUsername, null, action.defaultAvailability);
            return {
                ...state,
                meetingInfo: action.meetingInfo,
                gridInfo: gridData,
            }

        /**
         * Sets both the meetingInfo and gridInfo states to null.
         * Should be called if the request to the server returns a 404.
         * The page should notice the null gridData and render the 404 page.
         */
        case 'NO_MEETING_DATA':
            // if no meeting data is found, set meetingInfo and gridInfo
            // to null, enabling the 404 page.
            return {
                meetingInfo: null,
                gridInfo: null,
            }

        /**
         * Sets the title bubble to either expanded or not expanded.
         * Payload:
         * - titleBubbleExpanded [boolean]
         */
        case 'SET_TITLE_BUBBLE_EXPANDED':
            return {
                ...state,
                titleBubbleExpanded: action.expanded,
            }

        /**
         * Opens the share popup.
         */
        case 'OPEN_SHARE_POPUP':
            return {
                ...state,
                showSharePopup: true,
            }

        /**
         * Closes the share popup.
         */
        case 'CLOSE_SHARE_POPUP':
            return {
                ...state,
                showSharePopup: false,
            }

        /**
         * Runs when user is logged in or view availability is clicked to hide intro popup.
         */
        case 'HIDE_INTRO_POPUP':
            return {
                ...state,
                showIntroPopup: false
            }

        /**
         * Runs when the add availability button is clicked. Does two different
         * things depending on whether the user is logged in.
         * 
         * Payload:
         * - loggedIn (boolean)
         */
        case 'ADD_AVAILABILITY_CLICKED':

            // if the user is logged in, just set edit mode
            if (action.loggedIn) {
                return {
                    ...state,
                    editMode: true,
                }
            }

            // if the user is not logged in, set edit mode and open the login popup
            else {
                return {
                    ...state,
                    showLoginPopup: true,
                    showIntroPopup: false,
                }
            }

        /**
         * Called when edit mode should be exited and the page should return to it's original state.
         */
        case 'CANCEL_EDIT':
            return {
                ...state,
                editMode: false,
                isGuestUser: false,
                guestUsername: null,
                guestAccessCode: null,
            }

        /**
         * Run when the page contacts the server to do submission.
         * Hides the no availability popup and puts the page into a loading state.
         */
        case 'AVAILABILITY_SUBMITTED':
            return {
                ...state,
                meetingInfo: undefined,
                gridInfo: undefined,
                showNoAvailabilityWarning: false,
            }

        /**
         * Shows the no availability warning popup.
         */
        case 'SHOW_NO_AVAILABILITY_WARNING':
            return {
                ...state,
                showNoAvailabilityWarning: true,
            }

        case 'HIDE_NO_AVAILABILITY_WARNING':
            return {
                ...state,
                showNoAvailabilityWarning: false,
            }

        case 'RESET_PAGE_TO_OVERVIEW':
            return {
                ...state,
                editMode: false,
                showLoginPopup: false,
                isGuestUser: false,
                guestUsername: null,
                guestAccessCode: null,
                showNoAvailabilityWarning: false,
                gridInfo: undefined,
                meetingInfo: undefined,
                resetTrigger: !state.resetTrigger,
            }

        case 'CANCEL_LOGIN':
            return {
                ...state,
                showLoginPopup: false,
                // showIntroPopup: true,
            }

        case 'USER_LOGIN':
            return {
                ...state,
                showLoginPopup: false,
                editMode: true,
                gridInfo: undefined,
                meetingInfo: undefined,
            }

        case 'USER_LOGIN_GUEST':
            return {
                ...state,
                showLoginPopup: false,
                isGuestUser: true,
                editMode: true,
                guestUsername: action.guestUsername,
                guestAccessCode: action.guestAccessCode,
            }
        
        case 'GUEST_SUBMISSION_COMLPETE':
            return {
                ...state,
                editMode: false,
                showLoginPopup: false,
                isGuestUser: false,
                guestUsername: null,
                guestAccessCode: null,
                showNoAvailabilityWarning: false,
                gridInfo: undefined,
                meetingInfo: undefined,
                resetTrigger: !state.resetTrigger,
                hideAddAvailabilityButton: true,
                showEditButton: true,
            }

        // If no valid action type was given, do not modify state
        default:
            return state;
    }
}

/**
 * This page is loaded for the /invite route. It displays a meeting and allows a user to add their availability, to edit their availability, or to login.
 */
export default function InvitePage() {

    // get relavent page information
    const meetingID = (new URLSearchParams(window.location.search)).get("meeting");
    const authContext = useAuth();
    const error = useError();
    const navigate = useNavigate();
    const serverSettings = useUserSettings();

    // set up state
    const [state, dispatch] = useReducer(InviteReducer, {
        titleBubbleExpanded: false, // whether or not the title bubble is expanded
        meetingInfo: undefined, // contains meeting information
        gridInfo: undefined, // contains processed grid information
        showSharePopup: false, // whether or not to show the share popup
        editMode: false, // whether or not the grid is in edit mode,
        showLoginPopup: false, // whether to display the login popup
        showNoAvailabilityWarning: false, // whether to display the "No Availability" warning dialog
        showEditButton: false, // whether to show the edit button
        showIntroPopup: true, //whether to show intro popup
        isGuestUser: false, // used if the user logs in as guest
        guestUsername: null, // stores guest username
        guestAccessCode: null, // stores guest access code,
        hideAddAvailabilityButton: false, // if true, the add availability button will be hidden

        resetTrigger: false, // the state reducer can change this value to cause the page to rerender the grid
    });

    // set up availability context
    const availabilityContext = useCreateAvailabilityContext("OVERVIEW", state.gridInfo);
    const availabilityContextEdit = useCreateAvailabilityContext("EDIT", state.gridInfo);

    // fetch meeting information
    useEffect(() => {

        async function fetchAll() {

            // get user's default availability
            let defaultAvailability = null;
            if (authContext.currentUser) {
                dispatch({type: 'HIDE_INTRO_POPUP'}); //handles hiding of intro popup if user is logged in
                try {
                    if (await serverSettings.defaultAvailabilityEnabled) {
                        defaultAvailability = await serverSettings.defaultAvailability;
                    }
                } catch (e) {
                    console.error('Something went wrong fetching default avaiability, SKIPPING: ', e);
                }
            }

            // fetch meeting overlap information from the server
            getMeetingOverlaps(meetingID, await authContext.currentUser?.getIdToken(), {
                useAuth: authContext.currentUser !== null,
                onSuccess: (data) => {
                    // if the fetch was successful, tell state reducer
                    dispatch({
                        type: 'UPDATE_MEETING_FROM_SERVER',
                        meetingInfo: data,
                        currentUserUid: authContext.currentUser?.uid,
                        defaultAvailability,
                    });
                },
                onMeetingNotFound: (data) => {
                    // if the meeting was not found, tell state reducer
                    dispatch({
                        type: 'NO_MEETING_DATA'
                    })
                },
                onOtherResponse: (response) => {
                    // if some other response is returned, show an error page
                    error('TRANSISTOR', response.code, "Failed to fetch information about meeting overlaps from the server.");
                },
                onClientError: (err) => {
                    console.error(err);
                    error('WAVEFORM', 500, "Failed to fetch information about meeting overlaps because fetch code encountered an error.");
                }
            });
        }

        // authContext.currentUser can be 3 things:
        // false: if the authContext hasn't loaded yet
        // null: if the user is not logged in
        // a user object: if the user is logged in
        if (authContext.currentUser !== false) {
            fetchAll();
        }

    }, [authContext.currentUser, error, meetingID, state.resetTrigger, state.isGuestUser, serverSettings]);

    /**
     * This function will be run with the authContext.currentUser changes.
     * If the current user is not logged in, reset the page to the overview mode.
     * This is to handle the case when a user logs out of Overlap from another tab while
     * in the process of joining a meeting.
     */
    useEffect(() => {
        if (authContext.currentUser === null && state.editMode === true && state.showLoginPopup === false && state.isGuestUser === false) {
            dispatch({ type: 'RESET_PAGE_TO_OVERVIEW' });
        }
    }, [authContext.currentUser, state.editMode, state.showLoginPopup, state.isGuestUser]);

    /**
     * This function will be run when the Add Availability button is clicked.
     * 
     * If the user is logged in, then the recipient page is loaded.
     * 
     * If the user is not logged in, the state reducer is told to bring up the sign in options.
     */
    function addAvailability() {
        // enable edit mode
        dispatch({
            type: 'ADD_AVAILABILITY_CLICKED',
            loggedIn: authContext.currentUser !== false && authContext.currentUser !== null,
        });
    }

    /**
     * This function will be run when the submit button is clicked.
     * 
     * If there is no availability, the availability popup will be shown.
     * Otherwise, the availability is submitted.
     */
    function submitButtonClicked() {

        // process availability
        const epochData = gridToEpoch(availabilityContextEdit.intervalGrid, availabilityContextEdit.selected);

        // if there is no availability, then show the no availability warning
        if (epochData.length === 0) {
            dispatch({ type: 'SHOW_NO_AVAILABILITY_WARNING' });
        }

        else {
            dispatch({ type: 'AVAILABILITY_SUBMITTED' });
            submitAvailability(epochData);
        }
    }

    /**
     * Makes the server call to actually submit the availability of the user.
     */
    function submitAvailability(epochData) {

        joinMeeting(meetingID, authContext.currentUser?.getIdToken(), epochData, state.guestUsername, state.guestAccessCode, {
            isGuest: state.isGuestUser,

            onSuccess: (data) => {
                submissionSuccess();
            },
            onOtherResponse: (response) => {
                // if the server responded with some other code, show the error page
                console.error(response);
                error('PENCIL', response.code, "Received invalid response when joining meeting.");
            },
            onClientError: (err) => {
                // if the code that calls the api broke while calling
                console.error(err);
                error('SKYSCRAPER', 500, "Something went wrong when attempting to join a meeting.");
            },

            onUserAlreadyInMeeting: (data) => {
                // if the user was already in the meeting, call the update api instead
                updateMeetingAvailability(meetingID, authContext.currentUser?.getIdToken(), epochData, state.guestUsername, state.guestAccessCode, {
                    isGuest: state.isGuestUser,
                    onSuccess: (data) => {
                        submissionSuccess();
                    },
                    onOtherResponse: (response) => {
                        // if the server responded with some other code, show the error page
                        console.error(response);
                        error('JOSHUA TREE', response.code, "Received invalid response when update meeting availability.");
                    },
                    onClientError: (err) => {
                        // if the code that calls the api broke while calling
                        console.error(err);
                        error('ANTICIPATE', 500, "Something went wrong when attempting to update meeting availablity.");
                    },
                })
            },
        });
    }

    function submissionSuccess() {
        // if the user's availability was successfully added to the meeting
        toast.success("Your availability has been added");

        // if the user is not a guest, redirect them to the meeting overview page
        if (!state.isGuestUser) {
            navigate(`/meeting/${meetingID}`);
        }
        
        // if the user is a guest, call "GUEST_SUBMISSION_COMPLETE" and reset the page
        else {
            dispatch({ type: 'GUEST_SUBMISSION_COMLPETE' });
        }
    }

    // render loading screen if meetingInfo is undefined
    if (state.meetingInfo === undefined) {
        return (
            <>
                <Helmet>
                    <title>Loading...</title>
                </Helmet>
                <FullPageLoader message="Overlap is fetching the meeting information for you, It may take few seconds!" />
            </>
        );
    }

    // meeting info is set to null if the meeting cannot be found on the server
    // this is essentially the 404 page.
    if (state.meetingInfo === null) {
        return (
            <>
                <Helmet>
                    <title>Meeting Not Found</title>
                </Helmet>
                <InvalidMeetingPage />
            </>
        );
    }

    // set a variable for the meeting invite link
    const meetingInvite = `${window.location.origin}/t/invite?meeting=${meetingID}`;

    let addButtonText;
    if (authContext.currentUser) {
        // check if current user is in meeting
        if (userInMeeting(state.meetingInfo, authContext.currentUser.uid)) {
            addButtonText = 'Edit Availability';
            navigate(`/meeting/${meetingID}`);
        } else {
            addButtonText = 'Add Availability';
        }
    } else {
        addButtonText = 'Add or Edit Availability';
    }

    return (
        <>
            <Helmet>
                <title>{`${state.meetingInfo.title}`}</title>
            </Helmet>
            <AvailabilityContext.Provider value={state.editMode ? availabilityContextEdit : availabilityContext}>
                <Header />
                <main className={Classes.noFooterMain}>
                    <div className={Classes.pageWrap}>
                        <TopBar
                            isCreator={false}

                            showEdit={state.showEditButton}
                            showDeleteLeave={false}
                            showShare={true}
                            showBack={state.editMode}

                            onBack={() => {
                                dispatch({ type: 'CANCEL_EDIT' })
                            }}
                            onShare={() => {
                                dispatch({ type: 'OPEN_SHARE_POPUP' })
                            }}
                            onEdit={addAvailability}
                        />
                    </div>

                    <div className={Classes.titleBubbleContainer}>
                        <TitleBubble
                            meetingInfo={state.meetingInfo}
                            gridInfo={state.gridInfo}
                            expanded={state.titleBubbleExpanded}
                            setExpanded={(newVal) => dispatch({ type: 'SET_TITLE_BUBBLE_EXPANDED', expanded: newVal })}
                        />
                    </div>

                    <div className={Classes.instructionBox}>
                        <div className={Classes.instructionTitle}>
                            {state.editMode ? 'Add Your Availability' : 'View Meeting Times'}
                        </div>
                        <div className={Classes.instructionSubtitle}>
                            {state.editMode ? 'Click and Drag' : 'Click a Bubble'}
                        </div>
                    </div>

                    {/* Availability Card */}
                    {/**
                     The grid really doesn't like having it's entire context pulled out from under it.
                     I've solved this issue by simply having two grids and two contexts. The
                     AvailabilityProvider simply swaps between the two contexts and the right grid
                     is loaded by the render function. This is slightly wasteful of
                     system resources but avoids having to make major modifications to the grid
                     code.

                     TLDR; Two grids is intentional
                     */}
                    <div className={Classes.availabilityCardContainer}>
                        {!state.gridInfo && <InplaceCircle />}
                        {/* Overview grid */}
                        {state.gridInfo && !state.editMode &&
                            <AvailabilityCard
                                data={state.gridInfo}
                                mode={'OVERVIEW'}
                            />
                        }
                        {/* Edit grid */}
                        {state.gridInfo && state.editMode &&
                            <AvailabilityCard
                                data={state.gridInfo}
                                mode={'EDIT'}
                            />
                        }
                    </div>

                    {/* Add Availability Button */}
                    <div className={Classes.buttonContainer}>
                        {!state.editMode && !state.hideAddAvailabilityButton &&
                            <>
                                <DefaultButton
                                    text={addButtonText}
                                    fullWidth={true}
                                    hasShadow={true}
                                    onClick={addAvailability}
                                />
                            </>

                        }
                        {state.editMode &&
                            <DefaultButton
                                text="Submit"
                                rightIcon={<FiChevronRight />}
                                rightLarge={true}
                                hasShadow={true}
                                darker={true}
                                onClick={submitButtonClicked}
                            />
                        }
                    </div>
                    <BubbleDetails
                        gridInfo={state.gridInfo}
                        bottomSpacing={(!state.editMode && !state.hideAddAvailabilityButton) ? 100 : 15}
                    />
                </main>

                {state.showLoginPopup &&
                    <BottomPopup
                        onClose={() => {
                            dispatch({ type: 'CANCEL_LOGIN' });
                        }}
                    >
                        <LoginWithGuest
                            members={state.gridInfo?.members}
                            onNormalLogin={() => {
                                dispatch({ type: 'USER_LOGIN' });
                            }}
                            onGuestLogin={(guestName, accessCode) => {
                                dispatch({ type: 'USER_LOGIN_GUEST', guestUsername: guestName, guestAccessCode: accessCode });
                            }}
                            allowGuest={true}
                            meetingID={meetingID}
                        />
                    </BottomPopup>
                }

                {state.showSharePopup &&
                    <SharePopup
                        shareText={`You’re invited to join me for “${state.meetingInfo.title}”. Click here to add all times you’re available to meet.`}
                        url={meetingInvite}
                        onClose={() => {
                            dispatch({ type: 'CLOSE_SHARE_POPUP' })
                        }}
                    />
                }

                {state.showIntroPopup &&
                  <BottomPopup onClick={() => { dispatch({ type: 'HIDE_INTRO_POPUP' })}}>
                    <MeetingBubble
                        meetingInfo={state.meetingInfo}
                        gridInfo={state.gridInfo}
                    />
                    <DefaultButton
                        text={addButtonText}
                        fullWidth={true}
                        className={Classes.popupAddAvailabilityButton}
                        onClick={addAvailability}
                    />
                    <ViewAvailability onClick={() => dispatch({ type: 'HIDE_INTRO_POPUP'})} />
                  </BottomPopup>
                }

                {state.showNoAvailabilityWarning &&
                    <ConfirmPopup
                        title="No Availability?"
                        message="Are you sure you want to submit a time table with no availability? You won't be able to view your overlapping availability with your group."
                        buttonText="Submit"
                        onCancel={() => {
                            dispatch({ type: 'HIDE_NO_AVAILABILITY_WARNING' });
                        }}
                        onConfirm={() => {
                            dispatch({ type: 'AVAILABILITY_SUBMITTED' });
                            submitAvailability();
                        }}
                    />
                }
            </AvailabilityContext.Provider>
        </>
    )
}