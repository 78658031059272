import React from "react";
import Classess from "./Calender.module.scss";
import Month from "./Month";

const WeekBar = () => {
    const weekList = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];

    return (
        <div className={`shadow-card ${Classess.dayList}`}>
            {weekList.map((day, index) => {
                return (
                    <div className={Classess.day} key={index}>
                        {day}
                    </div>
                );
            })}
        </div>
    );
};

const Calender = (props) => {

    return (
        <React.Fragment>
            <div
                className={` shadow-card  ${Classess.Calender} ${
                    !props.error
                        ? " border border-transparent "
                        : " border border-danger"
                } `}
            >
                <WeekBar/>
                <Month {...props} />
            </div>
            <p className="alert">{props.error ? props.error : null}</p>
        </React.Fragment>
    );
};

export default Calender;
