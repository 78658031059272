import { useState } from 'react';
import Header from '../components/Header/Header';
import Classes from './scss/ChangePassword.module.scss';
import FormAlert from '../components/Ui/FormAlert/FormAlert';
import { getErrorMessageList, useVerify, verifyAll } from '../utilities/verification';
import FullPageLoader from '../components/loader/FullPageLoader';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { FiChevronLeft } from 'react-icons/fi';
import {constants} from "../firebase";
import { useAuth } from '../context/authContext';

export default function ChangePassword(props) {

    // hooks
    const navigate = useNavigate();
    const authContext = useAuth(); // get the information about the logged in user

    // state
    const [showLoader, setShowLoader] = useState(false);
    const [messages, setMessages] = useState([]);

    // verification
    const [currentPasswordRef, currentPasswordObj] = useVerify();
    const [newPasswordRef, newPasswordObj] = useVerify();
    const [confirmNewPasswordRef, confirmNewPasswordObj] = useVerify();

    currentPasswordObj.notEmpty("Current Password is required.");
    newPasswordObj.notEmpty("New Password is required.");
    confirmNewPasswordObj.notEmpty("Confirm New Password is required.");

    confirmNewPasswordObj.matchesField(newPasswordRef, "Passwords do not match.");

    // handlers
    async function save(event) {
        event.preventDefault();

        // return if any fields are invalid
        const [valid, ] = await verifyAll([currentPasswordObj, newPasswordObj, confirmNewPasswordObj]);
        updateErrorMessages();
        if (!valid) return;

        // submit changes to the server
        setShowLoader(true); // show loader while submitting changes

        let response = await fetch(`${constants.functionsBaseUrl}/users/self/password`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${await authContext.currentUser.getIdToken()}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "curent_password": currentPasswordObj.value, // misspelled in the api
                "new_password": newPasswordObj.value
            })
        })
        console.debug(response);

        // TODO: Greatly increase user feedback when errors occur
        if (!response.ok) {

            toast.error('Failed to update password.');
            console.debug('Failed to update password!');
            console.debug(response);
            console.debug(await response.json());

            setShowLoader(false);
        }

        // change password success
        toast.success("Password changed successfully!");
        setShowLoader(false);
        
        navigate('/account/settings');

        return false;
    }

    async function updateErrorMessages() {
        const messages = getErrorMessageList([currentPasswordObj, newPasswordObj, confirmNewPasswordObj]);
        
        setMessages(messages);
    }

    return (
        <>
            <Header/>
            <main>
                <div className={Classes.wrapper}>
                    <div className={Classes.titleRow}>
                        <div className={Classes.backButton} onClick={() => navigate('/account/settings')}><FiChevronLeft/></div>
                        <div className={Classes.title}>Change Password</div>
                        <div className={Classes.spacer}></div>
                    </div>
                    {showLoader && <FullPageLoader/>}
                    <form className={`${Classes.pageWrap} ${showLoader ? Classes.gone : ''}`} onSubmit={save}>
                        <div className={Classes.containerWrap}>
                            <div className={Classes.inputWrap}>
                                <label htmlFor="input1" className={Classes.inputLabel}>Current Password</label>
                                <input className={Classes.input} type="password" id="input1" name="currentpassword" ref={currentPasswordRef}
                                />
                            </div>
                            <div className={Classes.inputWrap}>
                                <label htmlFor="input2" className={Classes.inputLabel}>New Password</label>
                                <input className={Classes.input} type="password" id="input2" name="newpassword" ref={newPasswordRef}/>
                            </div>
                            <div className={Classes.inputWrap}>
                                <label htmlFor="input3" className={Classes.inputLabel}>Confirm New Password</label>
                                <input className={Classes.input} type="password" id="input3" name="confirmnewpassword" ref={confirmNewPasswordRef}/>
                            </div>
                        </div>
                        { messages.length > 0 &&
                            <div className={Classes.alertWrap}>
                                <FormAlert>{messages[0]}</FormAlert>
                            </div>
                        }
                        <button className={Classes.saveButton} onClick={save}>Save Changes</button>
                    </form>
                </div>
            </main>
        </>
    )
}