import useServerSettings from "utilities/settingsAPIWrapper";
import { useAuth } from "./authContext";
import { createContext, useContext } from "react";

const UserSettingsContext = createContext();

export function useUserSettings() {
    return useContext(UserSettingsContext);
}

export function UserSettingsProvider({ children }) {

    const authContext = useAuth();
    const serverSettings = useServerSettings(authContext.currentUser);

    return (
        <UserSettingsContext.Provider value={serverSettings}>
            {children}
        </UserSettingsContext.Provider>
    )
}