import { interpolateColor } from 'utilities/colors';
import Classes from './AvailabilityLegend.module.scss';

export default function AvailabilityLegend({ mode, maxOpacity, maxAvailability }) {

    let legendItems = [];

    // set legend items depending on grid mode
    if (maxAvailability === 0) {
        maxOpacity = 1;
    }

    if (mode === 'OVERVIEW') {
        let maxColor = interpolateColor('#F5F5F5', '#3EA680', maxOpacity);

        legendItems = [
            { color: '#F5F5F5', text: 'Not Available' },
            { color: maxColor, text: 'Available' },
        ];
    }
    else if (mode === 'EDIT') {
        legendItems = [
            { color: '#F5F5F5', text: 'Not Available' },
            { color: '#8669df', text: 'Available' },
        ];
    }

    // if overview, show the legend line
    // if (mode === 'OVERVIEW') {
    //     return (
    //         <div className={Classes.legendContainer}><div className={Classes.legendBarContainer}>
    //             <div className={Classes.legendLineText}><FiUser/>0</div>
    //             <div className={Classes.legendLine}></div>
    //             <div className={Classes.legendLineText}><FiUser/>{memberCount}</div>
    //         </div></div>
    //     )
    // }

    // if edit, show the legend
    if (mode === 'EDIT' || mode === 'OVERVIEW') {
        return (
            <div className={Classes.legendContainer}>
                { legendItems.map((item, index) => {
                    return (
                        <div key={index} className={Classes.legendItem}>
                            <div className={Classes.legendBubble} style={{backgroundColor: item.color}}></div>
                            <div className={Classes.legendText}>{item.text}</div>
                        </div>
                    );
                })}
            </div>
        );
    }

    // if legenditems is empty, do not show the legend
    if (!legendItems) {
        return (<></>);
    }
}