import { deepClone } from '../../../utilities/clone';

/**
     * Fills the given matrix with the given value from the start corner to the stop corner.
     * @private
     * @param {[any][any]} matrix
     * @param {[row,col]} start 
     * @param {[row,col]} stop 
     * @param {any} value 
     */
function _fillRect(matrix, start, stop, value) {

    let left = Math.min(start[0], stop[0]);
    let right = Math.max(start[0], stop[0]);
    let top = Math.min(start[1], stop[1]);
    let bottom = Math.max(start[1], stop[1]);

    for (let row = top; row < bottom + 1; row++) {
        for (let col = left; col < right + 1; col++) {
            matrix[col][row] = value;
        }
    }
}

/**
 * Generates a 2D matrix of size rows x cols filled with the fill value.
 * @private
 * @param {number} rows 
 * @param {number} cols 
 * @param {any} fill
 * @returns {[any][any]}
 */
function _generate2DMatrix(rows, cols, fill) {
    return [...Array(cols)].map(_ => Array(rows).fill(fill));
}

/**
 * Sets every value in the given matrix to false.
 * @param {[any][any]} matrix 
 */
function _resetMatrix(matrix) {
    for (let row = 0; row < matrix.length; row++) {
        matrix[row].fill(false);
    }
}

/**
 * A reducer that manages state for the AvailabilityCard component. Does not manage individual bubble states.
 * @param {{
    * selected: [bool][bool],
    * active: ([bool][bool]|bool),
    * pointerDown: bool,
    * pointerStartPosition: [row, col],
    * pointerLastPosition: [row, col],
    * mode: 'OVERVIEW'|'EDIT'
 * }} state
 * @param {{
    * type: 'BUBBLE_CLICKED','BUBBLE_POINTER_DOWN','BUBBLE_POINTER_MOVE','BUBBLE_POINTER_UP','OUTSIDE_CLICK',
    * position: [row, col]?
 * }} action
 */
export default function availabilityReducer(state, action) {

    let newActive, newSelected, setValue;

    // Don't do anything at all until the grid actually exists!
    if (!state.gridRef) return state;

    window.availabilityState = state;

    switch (action.type) {
        // called by the bubbleClicked function
        case 'BUBBLE_CLICKED':
            // In overview mode, if the bubble is clicked,
            // it should be set to active if it is not active,
            // and if it is active, it should be set to inactive.
            if (state.mode === 'OVERVIEW') {
                if (state.active === String(action.position)) {
                    return {
                        ...state,
                        active: null
                    }
                } else {
                    return {
                        ...state,
                        active: String(action.position)
                    }
                }
            }
            return state;
        
        case 'BUBBLE_POINTER_DOWN':
            if (state.mode !== 'EDIT') return state;

            // if the pointer is already down, first fill the selected matrix
            if (state.pointerDown) {
                // get the value at the start position in the selected matrix
                // the selected bubbles will be set to the opposite of this value
                setValue = (state.selected[state.pointerStartPosition[0]][state.pointerStartPosition[1]]);
                // fill the selected matrix
                newSelected = deepClone(state.selected);
                _fillRect(newSelected, state.pointerStartPosition, state.pointerLastPosition, !setValue);

                // call the onSelectedChange function
                // then continue as normal
            } else {
                newSelected = state.selected; 
            }

            // clear the active matrix
            newActive = deepClone(state.active);
            _resetMatrix(newActive);

            // set the bubble at the pointer position to active
            newActive = deepClone(state.active);
            newActive[action.position[0]][action.position[1]] = true;

            return {
                ...state,
                pointerDown: true,
                pointerStartPosition: action.position,
                pointerLastPosition: action.position,
                active: newActive,
                selected: newSelected,
            }
        
        case 'BUBBLE_POINTER_MOVE':
            if (state.mode !== 'EDIT') return state;

            // if the pointer is down, update the last position
            if (state.pointerDown) {
                // if the last position is the same as the newly updated position, do nothing
                if (state.pointerLastPosition === action.position) return state;

                // otherwise, redraw the active matrix
                newActive = deepClone(state.active);
                _resetMatrix(newActive);
                _fillRect(newActive, state.pointerStartPosition, action.position, true);

                return {
                    ...state,
                    pointerLastPosition: action.position,
                    active: newActive,
                }
            }
            return state;
        
        case 'BUBBLE_POINTER_UP':

            if (state.mode !== 'EDIT') return state;

            if (!state.pointerDown) return state;

            // clear the active matrix
            newActive = deepClone(state.active);
            _resetMatrix(newActive);
            // get the value at the start position in the selected matrix
            // the selected bubbles will be set to the opposite of this value
            setValue = (state.selected[state.pointerStartPosition[0]][state.pointerStartPosition[1]]);
            // fill the selected matrix
            newSelected = deepClone(state.selected);
            _fillRect(newSelected, state.pointerStartPosition, action.position, !setValue);

            return {
                ...state,
                selected: newSelected,
                active: newActive,
                pointerDown: false,
                pointerLastPosition: action.position,
            };
        
        case 'OUTSIDE_CLICK':
            // if overview mode, clear the active bubble
            if (state.mode === 'OVERVIEW') {
                return {
                    ...state,
                    active: null
                }
            }

            if (state.mode === 'EDIT') {
                if (!state.pointerDown) return state;

                newActive = deepClone(state.active);

                // clear the active matrix
                _resetMatrix(newActive);
                // get the value at the start position in the selected matrix
                // the selected bubbles will be set to the opposite of this value
                setValue = (state.selected[state.pointerStartPosition[0]][state.pointerStartPosition[1]]);
                // fill the selected matrix
                newSelected = deepClone(state.selected);
                _fillRect(newSelected, state.pointerStartPosition, state.pointerLastPosition, !setValue);

                return {
                    ...state,
                    pointerDown: false,
                    active: newActive,
                    selected: newSelected,
                }
            }

            return state;
        
        case 'GRID_CHANGE':
            // if the grid changes size or value, reset the selected and active matrices
            let data = action.data;
            return {
                ...state,
                selected: data?.initialSelected ?? _generate2DMatrix(data?.bubbles[0].length, data?.bubbles.length, false),
                active: state.mode === 'OVERVIEW' ? null : _generate2DMatrix(data?.bubbles[0].length, data?.bubbles.length, false),
                pointerDown: false,
                pointerStartPosition: null,
                pointerLastPosition: null,
                intervalGrid: data?.intervalGrid,
            }
        
        case 'CLEAR_ACTIVE':
            if (state.mode === 'OVERVIEW') {
                return {
                    ...state,
                    active: null,
                }
            } else {
                return {
                    ...state,
                    active: _generate2DMatrix(state.active[0].length, state.active.length, false),
                }
            }

        default:
            return state;
    }
}