import AvailabilityBubble from './AvailabilityBubble';
import Classes from './AvailabilityGrid.module.scss';

export default function AvailabilityGrid(props) {
    
    const { bubbles } = props;

    return (
        <>
            <div className={Classes.gridContainer}>
                { bubbles.map((column, col) => {
                    return (
                        <div className={Classes.column} key={col}>
                            { column.map((bubble, row) => {
                                return <AvailabilityBubble disabled={bubble.disabled} skipRow={bubble.skipRow} colorOpacity={bubble.colorOpacity} position={bubble.position} key={row}/>
                            })}
                        </div>
                    )
                })}
            </div>
        </>
    );
}