import React from 'react';
import {useEffect, useState} from "react";
import {constants} from "../firebase";
import {useAuth} from "../context/authContext";
import NotFoundPage from "./NotFoundPage";
import Header from "../components/Header/Header";
import useError from "../utilities/error";

const Dashboard = () => {
    const authContext = useAuth();
    const [signInUsersAnalytics, setSignInUsersAnalytics] = useState();
    const error = useError();

    useEffect(() => {
        async function runEffect() {
            if (authContext.currentUser) {
                fetch(`${constants.functionsBaseUrl}/analytics/users-details`, {
                    headers: {Authorization: `Bearer ${await authContext.currentUser.getIdToken()}`,}
                }).then(res => {
                    if (res.status !== 200) {
                        error('FACTORY', res.status, "Recieved invalid response when fetching user analytics.");
                        return;
                    }
                    else return res.json();
                })
                    .then(res => setSignInUsersAnalytics(res))
                    .catch(err => {
                        console.error(err);
                        error('FORMULA', 500, "An error occured when fetching user analytics.");
                    });
            }
        }
        runEffect();
    }, [authContext.currentUser, error]);

    return (
        <>  
            <Header/>
            {!signInUsersAnalytics && <NotFoundPage/>}

            {signInUsersAnalytics &&
                <div style={{marginTop: "100px"}}>
                    <h5>Total SignedIn Users: {signInUsersAnalytics.total}</h5>
                    <table style={{minWidth: "800px"}}>
                        <tr>
                            <th>Display Name</th>
                            <th>Email</th>
                        </tr>
                        {
                            signInUsersAnalytics.users.map(userData => {
                                return (
                                    <tr>
                                        <td>{userData.displayName}</td>
                                        <td>{userData.email}</td>
                                    </tr>
                                )
                            })
                        }
                    </table>
                </div>
            }
        </>
    )
}

export default Dashboard