import { toast } from 'react-hot-toast';

/**
 * Uses either navigator.share or the clipboard to either share or copy the url.
 * Should be used by share buttons to unify their behavior.
 * @param {string} title 
 * @param {string} text 
 * @param {string} url 
 * @param {string} alertText
 */
function share(title, text, url, alertText) {

    let data = {
        title,
        text,
        url
    };

    // copy link to clipboard if the browser supports it
    if (navigator.clipboard) {
        navigator.clipboard.writeText(url)
        
        .then(() => { // if clipboard write succeeds
            toast.success(alertText, { duration: 1500 });

            // if the browser supports the share API, and the device is capable of producing share popups, then use the share API
            if (navigator.canShare && navigator.canShare(data)) {
                navigator.share(data);
            } else {
                console.warn("Browser does not support the Share API.");
            }
        })
        
        .catch(() => { // if clipboard write fails
            toast.error("Failed to copy to clipboard.", { duration: 1500 });
            console.error("Failed to copy to clipboard.");
        });
    }

    // in case an ancient browser is used, make sure to have some fallback
    // note that this should never happen on any modern browser
    else {
        toast.error("Browser does not support clipboard access.", { duration: 1500 });
        console.error("Browser does not support clipboard access.");
    }
}

export function copyToClipboard(text, successAlertText = null) {
    if (navigator.clipboard) {
        navigator.clipboard.writeText(text)
        
        .then(() => { // if clipboard write succeeds
            if (successAlertText) {
                toast.success(successAlertText, { duration: 1500 });
            }
        })
        
        .catch(() => { // if clipboard write fails
            toast.error("Failed to copy to clipboard.", { duration: 1500 });
            console.error("Failed to copy to clipboard.");
        });
    }

    // in case an ancient browser is used, make sure to have some fallback
    // note that this should never happen on any modern browser
    else {
        toast.error("Browser does not support clipboard access.", { duration: 1500 });
        console.error("Browser does not support clipboard access.");
    }
}

export function canShare(title, text, url) {
    let data = {
        title,
        text,
        url
    };

    return navigator.canShare && navigator.canShare(data);
}

export function openShareMenu(title, text, url) {
    let data = {
        title,
        text,
        url
    };

    // if the browser supports the share API, and the device is capable of producing share popups, then use the share API
    if (navigator.canShare && navigator.canShare(data)) {
        navigator.share(data);
    } else {
        toast.error("Your browser does not support this feature.", { duration: 1500 });
        console.warn("Browser does not support the Share API.");
    }
}

export default share;