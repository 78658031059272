import React, {useRef, useEffect} from "react";
import Classess from "./Recipient.module.scss";
import {Form, Row, Col} from "react-bootstrap";
import {useAuth} from "../../context/authContext";
import getLogo from "../../utilities/getLogo";
import {FiCornerDownRight} from "react-icons/fi";
import useError from "../../utilities/error";

const Signup = (props) => {
    const authContext = useAuth();
    const error = useError();

    useEffect(() => {
        if (authContext.currentUser) props.onSuccess();
    }, [authContext, props]);

    const firstnameRef = useRef(),
        lastnameRef = useRef(),
        emailRef = useRef(),
        passwordRef = useRef(),
        passwordConfirmRef = useRef();

    async function validateAndSignUp(e) {
        e.preventDefault();
        const firstname = firstnameRef.current.value,
            lastname = lastnameRef.current.value,
            email = emailRef.current.value,
            password = passwordRef.current.value,
            passwordConfirm = passwordConfirmRef.current.value;

        if (!firstname) return alert("first name cannot be empty!");
        if (!email) return alert("email address cannot be empty!");
        if (!password) return alert("password cannot be empty!");

        if (password !== passwordConfirm) return alert("passwords do not match!");
        // TODO : punch loader
        try {
            // TODO: Use the user APIs from backend instead of firebase because here is a possible race condition that could skip "updateUserProfile" operation.
            //       Javascript's event loop architecture preventing this race condition so, I keeping this for future task.
            await authContext.signUp(email, password);
            await authContext.updateUserProfile({displayName: `${firstname} ${lastname}`.trim()});
        } catch (e) {
            console.error(e);
            error('SOLIDIFY', 500, 'An error occurred while attempting to sign up a new user.');
        }
        // TODO : release loader
    }

    return (
        <div className={Classess.formWrap}>
            <div className="w-100 ">
                <div>
                    <div className={Classess.formContainer}>
                        <legend className={Classess.legend}>
                            Welcome To{" "}
                            <img className={Classess.Logo} src={getLogo()} alt="Verlap Logo"/>{" "}
                        </legend>
                        <Form className={``}>
                            <Form.Group>
                                <Row className=" gx-3 mb-3 ">
                                    <Col>
                                        <Form.Control
                                            ref={firstnameRef}
                                            className=" round-element bg-light-gray-btn border-0"
                                            placeholder="First Name"
                                        />
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            ref={lastnameRef}
                                            className=" round-element bg-light-gray-btn border-0  "
                                            placeholder="Last Name"
                                        />
                                    </Col>
                                </Row>
                                <Form.Control
                                    ref={emailRef}
                                    className=" round-element bg-light-gray-btn border-0 mb-3"
                                    type="email"
                                    placeholder="Email"
                                />
                                <Form.Control
                                    ref={passwordRef}
                                    className=" round-element bg-light-gray-btn border-0 mb-3"
                                    type="password"
                                    placeholder="Password"
                                />
                                <Form.Control
                                    ref={passwordConfirmRef}
                                    className=" round-element bg-light-gray-btn border-0  "
                                    type="password"
                                    placeholder="Re-Enter Password"
                                />
                            </Form.Group>
                            <Form.Group className=" text-end ">
                                <button className={Classess.btn} onClick={validateAndSignUp}>
                                    Sign Up
                                    <FiCornerDownRight
                                        strokeWidth="3.5px"
                                        className="ms-1"
                                    />
                                </button>
                            </Form.Group>
                        </Form>
                    </div>
                </div>
                <p className=" text-center text-12px my-20px">Or</p>
                <div className=" text-center ">
                    <button onClick={props.login} className={`${Classess.greenBtn}`}>log in</button>
                </div>
            </div>
        </div>

        // <PortalMask>
        // <div className={Classess.formWrap}>
        //     <FormTopBar/>
        //     {/* btn wrap  */}
        //     <div className={Classess.formContent}>
        //         <Form>
        //             <Row className=" gx-3 gy-0 mb-3 ">
        //                 <Col>
        //                     <Form.Group>
        //                         <Form.Control placeholder="First Name"/>
        //                     </Form.Group>
        //                 </Col>
        //                 <Col>
        //                     <Form.Group>
        //                         <Form.Control placeholder="Last Name"/>
        //                     </Form.Group>
        //                 </Col>
        //             </Row>
        //             <Form.Group className=" mb-3 ">
        //                 <Form.Control text="email" placeholder="Email"/>
        //             </Form.Group>
        //             <Form.Group className=" mb-3 ">
        //                 <Form.Control text="text" placeholder="Password"/>
        //             </Form.Group>
        //             <Form.Group className=" mb-3 ">
        //                 <Form.Control
        //                     text="text"
        //                     placeholder="Re-Enter Password"
        //                 />
        //             </Form.Group>
        //             <Form.Group className=" text-end ">
        //                 <button className={Classess.btn}>
        //                     log in
        //                     <FiCornerDownRight
        //                         strokeWidth="3.5px"
        //                         className=" ms-1"
        //                     />
        //                 </button>
        //             </Form.Group>
        //         </Form>
        //     </div>
        //     <h1 className={Classess.or}>or</h1>
        //     <div className=" text-center ">
        //         <button onClick={props.login} className={`${Classess.greenBtn}`}>log in</button>
        //     </div>
        // </div>
        // </PortalMask>
    );
};

export default Signup;
