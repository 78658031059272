import React from "react";
import { Nav } from "react-bootstrap";
import Classess from "./MobileNav.module.scss";
import { FiHome, FiPlusSquare, FiUser } from "react-icons/fi";
import { NavLink } from "react-router-dom";

const MobileNav = () => {
    const NavbarObj = [
        {
            Icon: FiHome,
            url: "home",
        },
        {
            Icon: FiPlusSquare,
            url: "newmeeting",
        },
        {
            Icon: FiUser,
            url: "account",
        },
    ];

    return (
        <Nav className={Classess.mobNavbar}>
            {NavbarObj.map((ItemLink, index) => {
                return (
                    <NavLink
                        key={index}
                        to={ItemLink.url}
                        className={(linkStatus) =>
                            linkStatus.isActive
                                ? `${Classess.navLink} ${Classess.active}`
                                : `${Classess.navLink}`
                        }
                    >
                        <ItemLink.Icon className={Classess.NavIcon} />
                    </NavLink>
                );
            })}
            
        </Nav>
    );
};

export default MobileNav;
