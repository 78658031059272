// // @ts-check
import { DateTime, Interval } from "luxon";

const FIRST_DENSITY_WEIGHT = 1.2;
const SECOND_DENSITY_WEIGHT = 0.75;
const OTHER_DENSITY_WEIGHT = 0.3;

/**
 * @typedef EpochData - Represents data from the backend.
 * @property {EpochRange[]} range
 * @property {EpochMember[]} members
 * 
 * @typedef EpochRange - Represents the start and stop times of each column of the table (in epoch timestamps)
 * @property {number} start - The start time of the column (as an epoch timestamp in seconds)
 * @property {number} end - The stop time of the column (as an epoch timestamp in seconds)
 * 
 * @typedef EpochMember - Represents a single member of the meeting and their availability.
 * @property {string} displayName - The up-to-date display name of the member.
 * @property {string} oldDisplayName - The display the member had when they joined the meeting.
 * @property {string} uid - The unique identifier of the member.
 * @property {number} role - The role of the member. Determines what level of permissions they have to control the meeting.
 * @property {string} profileUrl - The url of the user's profile picture
 * @property {EpochAvailability[]} availability - The availability of the member.
 * 
 * @typedef EpochAvailability - Represents one "start-stop" interval of availability for a member. Represented as epoch timestamps.
 * @property {number} start - The start time of the interval (as an epoch timestamp in seconds)
 * @property {number} end - The stop time of the interval (as an epoch timestamp in seconds)
 */

/**
 * @typedef GridData - Represents the data in the grid format.
 * @property {boolean[][]} initialSelected - The grid of boolean values representing bubbles that should be selected on page load.
 * @property {GridBubble[][]} bubbles - Represents the grid of bubbles. Each bubble contains it's own information.
 * @property {GridHeader[]} headers - The headers of the grid.
 * @property {string[]} times - A list of times to be displayed in the time column. The first times will be displayed at the top of the column. Empty strings not be displayed. The string "SKIP" will cause a row skip to be displayed.
 * @property {GridAvailability[][]} availability - The availability of each member at each bubble.
 * @property {GridMember[]} members - The members of the meeting.
 * @property {Interval[][]} intervalGrid - The grid of intervals. Each interval contains a start and end time.
 * 
 * @typedef GridBubble - Represents a single bubble in the grid.
 * @property {boolean?} disabled - Whether or not the bubble is disabled.
 * @property {boolean?} skipRow - Whether or not the bubble is actually part of a row skip.
 * @property {number?} colorOpacity - The range of the bubble's color. 0 is gray, 1 is dark green.
 * @property {number[]} position - The position of the bubble in the grid. [col, row]
 * 
 * @typedef GridHeader - Represents the header of one column of the grid.
 * @property {string} date - The text displayed in the date section of the headers.
 * @property {string} day - The text displayed in the day section of the headers.
 * 
 * @typedef GridAvailability - Represents the availability of a single bubble.
 * @property {boolean[]} available - A list of booleans representing whether or not each member is available at this time. Each index of the list corresponds to the index of the member in the members list.
 * 
 * @typedef GridMember - Represents a single member in the meeting.
 * @property {string} displayName - The up-to-date display name of the member.
 * @property {string} oldDisplayName - The display the member had when they joined the meeting.
 * @property {string} uid - The unique identifier of the member.
 * @property {number} role - The role of the member. Determines what level of permissions they have to control the meeting.
 * @property {string} profileUrl - The url of the user's profile picture
 */

/**
 * @typedef {Object} DefaultData
 * A list of default availability ranges for each day of the week.
 * @property {DefaultDay} [Monday]
 * @property {DefaultDay} [Tuesday]
 * @property {DefaultDay} [Wednesday]
 * @property {DefaultDay} [Thursday]
 * @property {DefaultDay} [Friday]
 * @property {DefaultDay} [Saturday]
 * @property {DefaultDay} [Sunday]
 */

/**
 * @typedef DefaultDay
 * Represents the default availability for a single day of the week.
 * @property {EpochRange} range - The range of that day.
 * @property {EpochRange[]} availability - The availability of the member for that day, represented as a list of epoch ranges.
 */

/**
 * Convert from the backend data format (epoch) to the frontend data format (grid).
 * Takes in the interval length the epoch data.
 * @param {EpochData} epochData - The data from the server, in "epoch" format. See the type declarations in utils/dataProcessing.js for more information.
 * @param {number} intervalLength - The length of each interval in the grid, in minutes. For example, if the interval length is 15, then each column in the grid represents 15 minutes. Should be a clean division of an hour, but I'm not your mom. Best results when used properly.
 * @param {string} currentUserUID - The uid of the current user. Used to determine initialSelected. InitialSelected will be the availability of the current user.
 * @param {string|null} nameOverride - If set to a meeting user's name, that user's availability will be displayed as the intialSelected instead of the matching user uid. Useful for guests.
 * @param {string|null} timezone - The timezone to render the grid in. If null, the grid will be rendered in the local timezone.
 * @param {DefaultData} [defaultAvailability=null] - The default availability of the user. This will be automatically applied to the grid.
 * @returns {GridData} - The data in the grid format. See the type declarations in utils/dataProcessing.js for more information.
 */
export function epochToGrid(epochData, intervalLength, currentUserUID, nameOverride = null, timezone = null, defaultAvailability = null, filteredUIDs = []) {

    // temporary variables used internally
    // intervals - a list of all the intervals in the epoch range
    // columns - a list of all the columns in the grid, each column is a list of intervals
    // headers - a list of all the headers in the grid, each header is an object with a day and date property
    // uniqueTimeRanges - a set of all the unique time ranges in the grid, represented as strings. Each string is in the format "HH:mm-HH:mm"
    // disabledIntervals - a list of all the intervals that are disabled in the grid
    // intervalGrid - a 2d matrix of intervals, each interval is a 15 minute interval

    // 1. Generate the matrixes for the bubbles and availability.

    // let startTime = performance.now();

    // console.debug("EPOCH TO GRID STARTING: ");
    // console.debug(`Recieved interval length of ${intervalLength} minutes.`);
    // console.debug("Epoch data: ", epochData);

    // 1.1. Convert epoch timestamps into Luxon intervals
    let intervals = epochData.range.map((range) => {
        // get the new start and end times in the local timezone
        let newStart, newEnd;
        if (timezone) {
            newStart = DateTime.fromSeconds(range.start).setZone(timezone);
            newEnd = DateTime.fromSeconds(range.end).setZone(timezone);
        } else {
            newStart = DateTime.fromSeconds(range.start).toLocal();
            newEnd = DateTime.fromSeconds(range.end).toLocal();
        }

        // create and store an interval from these datetimes
        return Interval.fromDateTimes(newStart, newEnd);
    });

    // console.debug("1.1. Convert epoch timestamps into Luxon intervals: ", intervals);

    // 1.3. If an interval contains the start of the next day, split it into two different intervals and stick the first interval in the current column, then the second interval in the next column.
    let columns = [];
    intervals.forEach((interval, index) => {

        // handle undefined cases. To be clear, these should NEVER happen unless something has gone very wrong, hence the console warnings
        if (!interval || !interval.start || !interval.end) {
            console.warn(`epochToGrid WARNING! Interval either does not exist or is not bounded! Data processing may fail!`);
            return;
        }

        // get the start of the next day by using the start time
        let dayDivider;
        if (timezone) {
            dayDivider = interval.start.plus({days: 1}).startOf('day').setZone(timezone);
        } else {
            dayDivider = interval.start.plus({days: 1}).startOf('day').toLocal();
        }

        // see if the interval contains this divider
        if (interval.contains(dayDivider)) {
            // if it does, then it needs to be split into seperate days
            let firstInterval = Interval.fromDateTimes(interval.start, dayDivider);
            let secondInterval = Interval.fromDateTimes(dayDivider, interval.end);
            if (columns.length < index + 1) columns.push([]);
            columns[index].push(firstInterval);
            if (columns.length < index + 2) columns.push([]);
            columns[index + 1].push(secondInterval);
        } else {
            // interval is fine, and is contained within the current day. No issues.
            if (columns.length < index + 1) columns.push([]);
            columns[index].push(interval);
        }
    });

    // console.debug("1.3. If an interval contains the start of the next day, split it into two different intervals and stick the first interval in the current column, then the second interval in the next column: ", columns);

    // 1.4. Generate column headers
    let headers = columns.map((column) => {
        // get the day of the week and the date of the column. Use the first interval
        let interval = column[0];

        let weekDay = interval.start?.weekdayShort;
        let columnDate = interval.start?.toLocaleString({ month: 'short', day: '2-digit'});

        return {
            day: weekDay ?? 'Err',
            date: columnDate ?? '??'
        }
    });

    // console.debug("1.4. Generate column headers: ", headers);

    // 1.5. Loop through all columns to find all unique time ranges (irrespective of date).
    let uniqueTimeRanges = new Set(); // use a set for performance reasons
    for (let i = 0; i < columns.length; i++) { // loop through each column
        for (let j = 0; j < columns[0].length; j++) { // loop through each interval in the column
            let interval = columns[i][j];

            // create a time string and store it if it doesn't already exist in the time range.
            let intervalString = `${interval.start?.toFormat("HH:mm")}-${interval.end?.toFormat("HH:mm")}`
            if (!uniqueTimeRanges.has(intervalString)) {
                uniqueTimeRanges.add(intervalString);
            }
        }
    }

    // console.debug("1.5. Loop through all columns to find all unique time ranges (irrespective of date): ", uniqueTimeRanges);

    // 1.6. Loop through all columns. If a given column is missing a range from localTimeRanges, add it to the column. Also add the interval to the disabledIntervals array for later.
    let disabledIntervals = []; // store all the intervals that are disabled
    for (let i = 0; i < columns.length; i++) {
        let column = columns[i];
        // if the column already has the same number of entries as the number of unique time ranges, skip it
        if (column.length === uniqueTimeRanges.size) continue;

        // get the first datetime in the column for reference
        let firstDateTime = column[0].start;

        let timeList = new Set(); // create a list of all the time ranges in the column
        for (let j = 0; j < column.length; j++ ) { // iterate through each interval in the column
            let interval = column[j];
            let intervalString = `${interval.start?.toFormat("HH:mm")}-${interval.end?.toFormat("HH:mm")}`;
            timeList.add(intervalString);
        }

        // get the values that are only in the uniqueTimeRanges set
        let differences = [...uniqueTimeRanges].filter(x => !timeList.has(x));
        
        differences.forEach((intervalString) => {
            let [startString, endString] = intervalString.split('-');
            let [startHour, startMinute] = startString.split(':');
            let [endHour, endMinute] = endString.split(':');
            let start = firstDateTime?.set({hour: startHour, minute: startMinute});
            let end = firstDateTime?.set({hour: endHour, minute: endMinute});

            if (start.toMillis() > end.toMillis()) { // if start is after end
                end = end.plus({days: 1}); // add a day to end
            }

            // verify that nothing has gone terribly wrong here
            if (!start || !end) {
                console.warn(`epochToGrid WARNING! Could not generate disabled interval! Data processing may fail! This could be caused by empty columns!`);
                return;
            }

            // create the interval and store it in the column and in the disabledIntervals array
            let interval = Interval.fromDateTimes(start, end);
            column.push(interval);
            disabledIntervals.push(interval);
        })
    }

    // console.debug("Disabled intervals: ", disabledIntervals);

    // console.debug("1.6. Loop through all columns. If a given column is missing a range from localTimeRanges, add it to the column. Also add the interval to the disabledIntervals array for later: ", columns);

    // 1.7. Sort all the intervals in each column by start time
    columns = columns.map((column) => {
        return column.sort((a, b) => {
            if (!a.start || !b.start) return 0; // to silence the linter
            return a.start.toMillis() - b.start.toMillis();
        })
    })

    // console.debug("1.7. Sort all the intervals in each column by start time: ", columns);

    // 1.9. Generate the grid of interval objects. Each interval should have a position. If the interval is part of a row skip, it should have a skipRow property. If the interval is disabled, it should have a disabled property. If the interval is not disabled, it should have a colorOpacity property.
    let intervalGrid = [];
    columns.forEach((column, columnIndex) => {
        intervalGrid.push([]);
        column.forEach((interval) => {
            let dividedIntervals = interval.splitBy({minutes: intervalLength});
            dividedIntervals.forEach((dividedInterval) => {
                intervalGrid[columnIndex].push(dividedInterval);
            });
            // if this is not the last interval in the column, add a row skip
            if (column.indexOf(interval) !== column.length - 1) {
                intervalGrid[columnIndex].push('SKIP');
            }
        });
    });
    // console.log('Generated interval grid: ', intervalGrid);

    // 1.10. Generate the bubbles matrix. Each bubble should have a position. If the bubble is part of a row skip, it should have a skipRow property. If the bubble is disabled, it should have a disabled property. If the bubble is not disabled, it should have a colorOpacity property.
    let bubbles = [];
    intervalGrid.forEach((column, columnIndex) => {
        bubbles.push([]);
        column.forEach((interval, rowIndex) => {
            if (interval === 'SKIP') {
                bubbles[columnIndex].push({
                    skipRow: true,
                    position: [columnIndex, rowIndex]
                })
            } else {
                let disabled = false;
                disabledIntervals.forEach((disabledInterval) => {
                    if (disabledInterval.engulfs(interval)) disabled = true;
                })
                if (disabled) {
                    bubbles[columnIndex].push({
                        disabled: true,
                        position: [columnIndex, rowIndex]
                    })
                } else {
                    bubbles[columnIndex].push({
                        position: [columnIndex, rowIndex]
                    })
                }
            }
        })
    });
    // console.log('Generated bubbles without opacity: ', bubbles);

    // Filter members if filteredUIDs is provided
    let processedMembers = (filteredUIDs && filteredUIDs.length > 0)
        ? epochData.members.filter(member => filteredUIDs.includes(member.uid))
        : epochData.members;

    // Sort filtered members by role, then by display name
    processedMembers.sort((a, b) => {
        if (a.role === 0 && b.role !== 0) {
            return -1;
        }
        if (a.role !== 0 && b.role === 0) {
            return 1;
        }
        if (a.displayName < b.displayName) {
            return -1;
        }
        if (a.displayName > b.displayName) {
            return 1;
        }
        return 0;
    });

    // 1.11. Generate the color opacity for each bubble.
    let maxAvailability = 0;
    let availabileCountGrid = [];
    let densityList = new Set();
    intervalGrid.forEach((column, columnIndex) => {
        availabileCountGrid.push([]);
        column.forEach((interval) => {
            if (interval === 'SKIP') {
                availabileCountGrid[columnIndex].push(0);
            } else {
                let availabilityCount = 0;
                processedMembers.forEach((member) => {
                    member.availability.forEach((availabiltyInterval) => {
                        // create luxon interval from the availability interval
                        let startTime, stopTime;
                        if (timezone) {
                            startTime = DateTime.fromSeconds(availabiltyInterval.start).setZone(timezone);
                            stopTime = DateTime.fromSeconds(availabiltyInterval.end).setZone(timezone);
                        } else {
                            startTime = DateTime.fromSeconds(availabiltyInterval.start).toLocal();
                            stopTime = DateTime.fromSeconds(availabiltyInterval.end).toLocal();
                        }
                        let availabilityLuxonInterval = Interval.fromDateTimes(startTime, stopTime);
                        if (availabilityLuxonInterval.engulfs(interval)) availabilityCount++;
                    })
                })
                availabileCountGrid[columnIndex].push(availabilityCount);
                densityList.add(availabilityCount); // add count of this bubble to the density list
                if (availabilityCount > maxAvailability) maxAvailability = availabilityCount;
            }
        })
    })
    // console.log("Generated availability count grid: ", availabileCountGrid);
    // console.log("Max availability: ", maxAvailability);

    // convert the density list to an array and sort it in descending order
    densityList = Array.from(densityList).sort((a, b) => (b - a));

    // 1.12. Divide each availability count by the max availability to get the color opacity.
    bubbles.forEach((column, columnIndex) => {
        column.forEach((bubble, rowIndex) => {
            if (bubble.disabled || bubble.skipRow) return;
            let availabilityCount = availabileCountGrid[columnIndex][rowIndex];
            bubbles[columnIndex][rowIndex].colorOpacity = getBubbleOpacity(availabilityCount, maxAvailability, densityList);
        })
    })
    // console.log("Generated bubbles with opacity: ", bubbles);

    // 1.13. Generate the time rows
    let times = [];
    if (intervalGrid.length === 0) {
        console.warn("epochToGrid WARNING! Interval grid is empty! Data processing may fail!");
        return;
    }
    intervalGrid[0].forEach((interval) => {
        if (interval === 'SKIP') {
            times.push('SKIP');
        } else {
            if (interval.start.minute === 0) {
                times.push(`${interval.start.toFormat("h")}${interval.start.toFormat("a").toLowerCase()}`);
            } else {
                times.push("");
            }
        }
    })
    // console.log("Generated times: ", times);

    // 1.14. Generate the members list
    let members = processedMembers.map((member) => {
        return {
            displayName: member.displayName,
            oldDisplayName: member.oldDisplayName,
            uid: member.uid,
            role: member.role,
            profileUrl: member.profileUrl
        }
    })

    // 1.15. Generate the availability matrix
    let availability = [];
    let initialSelected = [];

    // loop through each interval in the intervals matrix
    let hasInitialSelected = false;
    intervalGrid.forEach((column, columnIndex) => {
        availability.push([]);
        initialSelected.push([]);
        column.forEach((interval, rowIndex) => {
            availability[columnIndex].push([]);
            initialSelected[columnIndex].push(false);
            // loop through each member in the processedMembers matrix.
            processedMembers.forEach((member, memberIndex) => {
                // loop through each range in the member's availability
                let memberAvailabile = false;
                member.availability.forEach((intervalObj) => {
                    if (memberAvailabile) return;
                    let startTime, stopTime;
                    if (timezone) {
                        startTime = DateTime.fromSeconds(intervalObj.start).setZone(timezone);
                        stopTime = DateTime.fromSeconds(intervalObj.end).setZone(timezone);
                    } else {
                        startTime = DateTime.fromSeconds(intervalObj.start).toLocal();
                        stopTime = DateTime.fromSeconds(intervalObj.end).toLocal();
                    }
                    let luxonInterval = Interval.fromDateTimes(startTime, stopTime);
                    if (luxonInterval.engulfs(interval)) {
                        availability[columnIndex][rowIndex].push(memberIndex);
                        if (member.uid === currentUserUID || member.displayName === nameOverride) {
                            initialSelected[columnIndex][rowIndex] = true;
                            hasInitialSelected = true;
                        }
                        return;
                    }
                })
            })
        })
    })

    // build grid data!
    let gridData = {
        initialSelected,
        bubbles,
        headers,
        times,
        availability,
        members,
        intervalGrid,
        disabledIntervals,
        densityList,
        maxAvailability,
    }

    if (!hasInitialSelected && defaultAvailability) {
        gridData = applyDefaultAvailabilityToGridData(gridData, defaultAvailability, timezone);
    }

    return gridData;
}

/**
 * Converts a given interval grid and selected grid into an group of epoch intervals.
 * @param {Interval[][]} intervalGrid 
 * @param {boolean[][]} selectedGrid 
 * @returns {EpochAvailability[]} - A list of epoch intervals.
 */
export function gridToEpoch(intervalGrid, selectedGrid) {

    let currentInterval = {
        start: null,
        end: null
    };
    let epochIntervals = [];

    // loop through each column in the selected grid
    selectedGrid.forEach((column, columnIndex) => {
        column.forEach((selected, rowIndex) => {
            // if we're inside an interval
            if (currentInterval.start !== null) {
                // if the bubble is not selected, end the interval at the previous bubble
                if (!selected) {
                    // if the previous bubble was a skip row, go one higher
                    if (intervalGrid[columnIndex][rowIndex - 1] === 'SKIP') {
                        rowIndex--;
                    }
                    currentInterval.end = intervalGrid[columnIndex][rowIndex - 1].end.toMillis() / 1000;
                    epochIntervals.push(currentInterval);
                    currentInterval = {
                        start: null,
                        end: null
                    }
                }

                // otherwise if the bubble is selected, do nothing
            }

            // if we're not inside an interval
            else {
                // if the bubble is selected, set the start time to the current bubble
                if (selected) {
                    // ignore if the bubble is a skip row
                    if (intervalGrid[columnIndex][rowIndex] === 'SKIP') return;
                    currentInterval.start = intervalGrid[columnIndex][rowIndex].start.toMillis() / 1000;
                }
            }
        })

        // at the end of each column, if we're inside an interval, end the interval at the last bubble
        if (currentInterval.start !== null) {
            currentInterval.end = intervalGrid[columnIndex][column.length - 1].end.toMillis() / 1000;
            epochIntervals.push(currentInterval);
            currentInterval = {
                start: null,
                end: null
            }
        }
    })

    return epochIntervals;
}

/**
 * Takes in surface information about a grid, such as dates, time range, timezone, and bubble length, and returns a valid.
 * @param {string[]} dates - A list of dates in the format "MM/DD/YYYY". For example "12/31/2021"
 * @param {string} timeRange - A string representing the time range in the format "HH:mm-HH:mm". For example "09:00-17:00"
 * @param {number} intervalLength - The length of each interval in the grid, in minutes.
 * @param {string|null} timezone - The timezone to use when generating the intervals. If null, the intervals will be generated in the local timezone.
 * @returns {Interval[][]} - A 2d matrix of intervals.
 */
export function generateIntervalGrid(dates, timeRange, intervalLength, timezone) {
    // convert the dates into luxon date objects
    let luxonDates = dates.map((date) => {
        let [month, day, year] = date.split('/');
        let datetime = DateTime.fromObject({
            month: parseInt(month),
            day: parseInt(day),
            year: parseInt(year)
        })
        if (timezone) {
            datetime = datetime.setZone(timezone);
        } else {
            datetime = datetime.toLocal();
        }
        return datetime;
    })

    // get timerange information
    let [startString, endString] = timeRange.split('-');
    let [startHour, startMinute] = startString.split(':');
    let [endHour, endMinute] = endString.split(':');

    // iterate through each luxon day
    let dayIntervals = luxonDates.map((dateObj) => {
        // generate a luxon interval for the time range
        let start = dateObj.set({hour: startHour, minute: startMinute});
        let end = dateObj.set({hour: endHour, minute: endMinute});
        let interval = Interval.fromDateTimes(start, end);
        return interval;
    })

    // generate the interval grid
    let intervalGrid = dayIntervals.map((interval) => {
        let dividedIntervals = interval.splitBy({minutes: intervalLength});
        return dividedIntervals;
    });

    return intervalGrid;
}

/**
 * Takes in surface information about a grid, such as dates, time range, timezone, and bubble length and returns a valid EpochData object.
 * @param {string[]} dates - A list of dates in the format "MM/DD/YYYY". For example "12/31/2021"
 * @param {string} timeRange - A string representing the time range in the format "HH:mm-HH:mm". For example "09:00-17:00"
 * @param {number} intervalLength - The length of each interval in the grid, in minutes.
 * @param {string|null} timezone - The timezone to use when generating the intervals. If null, the intervals will be generated in the local timezone.
 * @return {EpochData} - An epochdata with the correct range and no members.
 */
export function generateBlankEpochData(dates, timeRange, intervalLength, timezone) {
    // generate the interval grid
    let intervalGrid = generateIntervalGrid(dates, timeRange, intervalLength, timezone);

    // generate the epoch data
    let epochData = {
        range: [],
        members: []
    };

    // generate the range
    intervalGrid.forEach((column) => {
        let start = column[0].start.toMillis() / 1000;
        let end = column[column.length - 1].end.toMillis() / 1000;
        epochData.range.push({
            start,
            end
        })
    })

    // generate the members
    epochData.members = [];

    return epochData;
}

/**
 * Generates a epoch range from a grid of intervals.
 * @param {Interval[][]} intervalGrid
 * @returns {EpochRange[]} - A list of epoch ranges.
 */
export function generateEpochRange(intervalGrid) {
    let epochRange = [];
    intervalGrid.forEach((column) => {
        let start = column[0].start.toMillis() / 1000;
        let end = column[column.length - 1].end.toMillis() / 1000;
        epochRange.push({
            start,
            end
        })
    })
    return epochRange;
}

/**
 * Converts values like [540, 1020] into string timerange values for processing.
 * @param {number[]} minutesArray 
 * @returns {String} - A string timerange value like "09:00-17:00"
 */
export function convertMinutesToTimeRange(minutesArray) {
    function formatTime(minutes) {
        let hours = Math.floor(minutes / 60);
        let mins = minutes % 60;
        return `${hours.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}`;
    }

    return `${formatTime(minutesArray[0])}-${formatTime(minutesArray[1])}`;
}

// utility function for converting the dates in the format "2021-10-04T07:00:00.000Z" to "10/04/2021"
/**
 * Converts a date string in the format "2021-10-04T07:00:00.000Z" to "10/04/2021" for proccessing.
 * @param {String} dateString 
 * @returns {String}
 */
export function convertToDateString(dateString) {
    // Parse the input string
    const date = new Date(dateString);

    // Extracting the month, day, and year
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();

    // Return the formatted string
    return `${month}/${day}/${year}`;
}

/**
 * Takes in a gridData object and applies the epochData to it's initialSelected grid.
 * Used in meeting creation to set the initial selected values to the previous selected values when changing grid size.
 * 
 * @param {GridData} gridData A GridData object with the initialSelected grid all false.
 * @param {EpochRange[]} epochData A list of EpochRanges representing the previous selected values.
 * @returns {GridData}
 */
export function applyEpochToGridData(gridData, epochData) {

    let epochIntervals = [];
    // loop through each item in epochData
    epochData.forEach((interval) => {
        // create a luxon interval from the epoch interval
        let startTime = DateTime.fromSeconds(interval.start);
        let stopTime = DateTime.fromSeconds(interval.end);
        let luxonInterval = Interval.fromDateTimes(startTime, stopTime);
        epochIntervals.push(luxonInterval);
    })

    // loop through each column in the intervalGrid
    gridData.intervalGrid.forEach((column, columnIndex) => {
        // loop through each interval in the column
        column.forEach((interval, rowIndex) => {
            // if the interval is a skip row, do nothing
            if (interval === 'SKIP') return;

            // loop through each epoch interval
            epochIntervals.forEach((epochInterval) => {
                // if the epoch interval engulfs the interval, set the initialSelected value to true
                if (epochInterval.engulfs(interval)) {
                    gridData.initialSelected[columnIndex][rowIndex] = true;
                }
            })
        })
    })

    return gridData;
}


/**
 * Takes in a gridData object and a defaultAvailability (DefaultData) object, and applies it to the grid's initialSelected.
 * Returns the updated gridData.
 * @param {GridData} gridData
 * @param {DefaultData} defaultAvailability
 * @param {string} [timezone=null]
 * @returns {GridData}
 */
export function applyDefaultAvailabilityToGridData(gridData, defaultAvailability, timezone=null) {
    if (!defaultAvailability) { return gridData; }
    
    // create an array of weekday names, where each index corresponds to a column in the meeting
    let meetingDays = gridData.intervalGrid.map((intervalCol) => {
        let interval = intervalCol[0]; // get the first item in the column
        return interval.start.startOf('day').weekdayLong; // determine which day the interval is on and return it to add it to the array
    })

    let epochsToApply = [];

    // we now have a list of the days in the meeting. For each day in the meeting (ie each column in the gridData), we need to grab the listed default availability and apply it using the applyEpochToGridData function.
    for (let i = 0; i < gridData.intervalGrid.length; i++) {
        let columnDay = meetingDays[i]; // get the day of the week for this column
        let startOfDay = gridData.intervalGrid[i][0].start.startOf('day'); // get the start of the day for the column
        
        // if the given default availability has that day listed
        if (defaultAvailability[columnDay]) {
            let defaultStartOfDay = DateTime.fromSeconds(defaultAvailability[columnDay].range.start); // get the start of the day for the default availability

            // for each epoch in the default availability, we need to subtract the default availability start from them, then add the start of the current day back to them
            defaultAvailability[columnDay].availability.forEach((defaultRange) => {
                let defaultInterval = epochToLuxon(defaultRange, timezone); // convert to interval
                
                // get the difference between the epoch and the default start of day (how far into the day the epoch is)
                let intervalOffset = Interval.fromDateTimes(defaultStartOfDay, defaultInterval.start).length("minutes");
                let defaultIntervalLength = defaultInterval.length('minutes');

                // create a new datetime to represent the new start time of the epochrange
                // it is set at the start of the actual day plus the time that default interval is offset by
                // also create a datetime to represent the new end time of the epoch range, which is the start datetime but extended by the correct amount so it is the same length
                let newRangeStart = startOfDay.plus({'minutes': intervalOffset});
                let newRangeEnd = newRangeStart.plus({'minutes': defaultIntervalLength});

                // convert back into an epoch range and return
                let epochRange = {
                    start: newRangeStart.toSeconds(),
                    end: newRangeEnd.toSeconds()
                }

                epochsToApply.push(epochRange);
            })
        }
    }
    // now that we have availability, we can add it using applyEpochToGridData
    gridData = applyEpochToGridData(gridData, epochsToApply);

    // after that loop, all the default availability should've been added (fingers crossed)
    return gridData;
}

/**
 * Checks whether the given user is in the given meeting by checking for
 * the user's UID. If the user is a guest, their UID is their guest username.
 * @param {EpochData} meetingInfo - The meeting information returned from the server.
 * @param {string} uid - The uid of the user to check for, or the display name of the user, if they are a guest.
 */
export function userInMeeting(meetingInfo, uid) {
    // check if the user is a guest
    if (!meetingInfo?.members) { return false; }
    if (meetingInfo.members.some((member) => member.uid === uid)) {
        return true;
    }
}

/**
 * Generates a grid of sunday, monday, tuesday, wednesday, thursday, friday, and saturday. It has no dates.
 * @param {number} intervalLength - The length of each interval in the grid, in minutes.
 * @returns {[GridData, EpochRange[]]} - An array where the first argument is the grid data to pass to the availability grid and the second argument is the range of the grid, which is required for default availability processing functions.
 */
export function generateDefaultGrid(intervalLength) {
    // generate the dates
    let dates = [];
    for (let i = 0; i < 7; i++) {
        let date = DateTime.now().startOf('week').plus({days: i - 1});
        dates.push(date.toFormat("MM/dd/yyyy"));
    }

    // generate the time range
    let timeRange = "00:00-24:00";

    // generate the interval grid
    let epochData = generateBlankEpochData(dates, timeRange, intervalLength);
    let gridData = epochToGrid(epochData, intervalLength);

    for (let i = 0; i < 7; i++) {
        gridData.headers[i].date = '';
    }

    return gridData;
}

/**
 * Takes in an interval grid, selected grid, and range and returns a default availability list that can be sent to the backend.
 * @param {Interval[][]} intervalGrid - The interval grid of the availability grid.
 * @param {boolean[][]} selectedGrid - The selected grid of the availability grid.
 * @param {string} [timezone=null] - The timezone that the user is in. If not set, the user's local timezone will be used.
 * @returns {DefaultData} - A list of default availability ranges for each day of the week. Each index in the list corresponds to the day of the week as represented by Luxon.
 */
export function gridToDefaultAvailability(intervalGrid, selectedGrid, timezone) {

    // create the scaffold for the default data
    /** @type {DefaultData} */
    let defaultData = {};

    // for each column in the input grid, determine which day of the week it is, and store the range in DefaultData
    intervalGrid.forEach((dayColumn) => {
        let dayInterval = dayColumn[0]; // convert to interval
        let day = dayInterval.start.weekdayLong; // get the day of the week

        // store the range of the day in the default data
        defaultData[day] = {};
        defaultData[day].range = {
            start: dayInterval.start.startOf('day').toSeconds(),
            end: dayInterval.end.plus({ days: 1 }).startOf('day').toSeconds(),
        };
        defaultData[day].availability = [];
    })

    // get the availability of the grid
    let availability = gridToEpoch(intervalGrid, selectedGrid);

    // go through each availability item, determine which day it is, and add it to the appropriate list
    availability.forEach((epochRange, index) => {
        let interval = epochToLuxon(epochRange, timezone);
        let day = interval.start.weekdayLong;

        // store in the availability list of the correct day
        defaultData[day].availability.push(epochRange);
    });

    return defaultData;
}

/**
 * Merges two DefaultData objects. If the two objects have a day in common, that day will overwritten by the second object's day.
 * @param {DefaultData} oldData - The old default data object
 * @param {DefaultData} newData - The new default data object
 * @returns {DefaultData}
 */
export function mergeDefaultAvailability(oldData, newData) {

    // go through each day in the new data
    for (let day in Object.keys(newData)) {
        // overwrite the values in the oldData
        oldData[day] = newData[day];
    }

    // do a deep clone of the oldData just in case
    oldData = {...oldData}
    return oldData;
}

/**
 * Converts an EpochRange into a Luxon Interval
 * @param {EpochRange} epochRange - The range to convert
 * @param {string} [timezone=null] - The timezone the user is in. If not set, the user's local timezone will be used.
 * @returns {Interval} - A Luxon Interval
 */
export function epochToLuxon(epochRange, timezone = null) {
    let start, end;
    if (timezone) {
        start = DateTime.fromSeconds(epochRange.start).setZone(timezone);
        end = DateTime.fromSeconds(epochRange.end).setZone(timezone);
    }
    else {
        start = DateTime.fromSeconds(epochRange.start).toLocal();
        end = DateTime.fromSeconds(epochRange.end).toLocal();
    }
    return Interval.fromDateTimes(start, end);
}

/**
 * Converts a Luxon Interval into an EpochRange
 * @param {Interval} luxonInterval - The interval to convert
 * @returns {EpochRange} - An EpochRange
 */
export function luxonToEpoch(luxonInterval) {
    return {
        start: luxonInterval.start.toSeconds(),
        end: luxonInterval.end.toSeconds()
    }
}

/**
 * Get the opacity of a bubble given the number of people available for that bubble,
 * the number of people available in the meeting, and the density list of the meeting.
 * @param {number} availabilityCount 
 * @param {number} maxAvailability 
 * @param {number[]} densityList 
 * @returns {number} - The opacity of the bubble.
 */
export function getBubbleOpacity(availabilityCount, maxAvailability, densityList = []) {
    if (maxAvailability === 0) {
        // if there are no people in the grid, show no green
        return 0;
    } else if (maxAvailability === 1) {
        // if there is only one person in the grid, dim the green to make it less overwhelming
        return availabilityCount * 0.75;
    } else {
        // if there is more than one person in the meeting, give the first and second largest densities an opacity boost. (See Karthik's calendar-color github repo for info on this technique)
        let baseOpacity = availabilityCount / maxAvailability;
        // find the availability's location in the densityList
        let densityIndex = densityList.findIndex(value => value === availabilityCount);

        let densityMultiplier;
        if (densityIndex === 0) densityMultiplier = FIRST_DENSITY_WEIGHT;
        else if (densityIndex === 1) densityMultiplier = SECOND_DENSITY_WEIGHT;
        else densityMultiplier = OTHER_DENSITY_WEIGHT;

        return baseOpacity * densityMultiplier;
    }
}