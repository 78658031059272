import React from "react";
import Classess from "./Recipient.module.scss";
import LoginBtn from "../Ui/LoginBtn/LoginBtn";
import getLogo from "../../utilities/getLogo";

const UserLogin = (props) => {
    return (
        <div className={Classess.contentWrap}>
            <h1 className={Classess.Title}>Log In or Sign Up to Submit</h1>
            {/* btn wrap  */}
            <div className={Classess.btnWrap}>
                {/*<GoogleBtn />*/}
                <img src={getLogo()} alt="Overlap" className="img-fluid"/>
                <p>You need to authenticate before submitting your availability.</p>
                <LoginBtn click={props.login}/>
                {/*<button onClick={props.signup} className={`signup-btn--hover`}>Sign up</button>*/}
            </div>
        </div>
    );
};

export default UserLogin;
