import Classes from "./TextWithTitle.module.scss";

export default function TextWithTitle(props) {
    const { className,title,body,fullWidth,textCenter } = props;
    return (
        <div className={`${Classes.container} ${fullWidth ? Classes.fullWidth : ''} ${className} ${textCenter ? Classes.textCenter : ''}`}>
            <div className={Classes.title}>{title}</div>
            <div className={`${textCenter ? Classes.textCenter : ''} ${Classes.body}`}>{body}</div>
        </div>
    );
}