import React from 'react';
import Classes from './LineText.module.scss';

/**
 * This component is text with a line to either side of it.
 * It is used as a divider element between sections.
 * It will fit to the size of its parent container.
 * @param {*} props 
 */

function LineText(props) {
    return (
        <div className={`${Classes.container} ${props.className}`}>
            <div className={Classes.line}></div>
            <div className={Classes.text}>{props.children}</div>
            <div className={Classes.line}></div>
        </div>
    )
}

export default LineText;