import Classes from './ConfirmPopup.module.scss';
import PortalMask from "../../../HOC/PortalMask";

/**
     * props:
     * - title
     * - message
     * - buttonText
     * - buttonIcon (optional)
     * - buttonClass (optional)
     * - onCancel
     * - onConfirm
     */
function ConfirmPopup(props) {

    return (
        <PortalMask hide={props.onCancel}>
            <div className={Classes.popupContainer} onClick={e => e.stopPropagation()}>
                <div className={Classes.title}>
                    {props.title}
                </div>
                <div className={Classes.message}>
                    {props.message}
                </div>
                <div className={Classes.buttonRow}>
                    <div className={`${Classes.button} ${Classes.cancelButton}`} onClick={props.onCancel}>Cancel</div>

                    {props.buttonIcon &&
                        <div className={`${Classes.button} ${Classes.withIcon} ${props.buttonClass ?? Classes.submitButton}`} onClick={props.onConfirm}>
                            <span>{props.buttonText}</span>
                            {props.buttonIcon}
                        </div>
                    }

                    {!props.buttonIcon &&
                        <div className={`${Classes.button} ${props.buttonClass ?? Classes.submitButton}`} onClick={props.onConfirm}>
                            {props.buttonText}
                        </div>
                    }
                </div>
            </div>
        </PortalMask>
    )
}

export default ConfirmPopup;