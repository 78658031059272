import React, { useReducer, useEffect, useState, useRef } from "react";
import Classes from "./scss/RecipientPage.module.scss";
import PortalMask from "../HOC/PortalMask";
import UserLogin from "../components/Recipient/UserLogin";
import Signup from "../components/Recipient/Signup";
import Login from "../components/Recipient/Login";
import { useAuth } from "../context/authContext";
import { constants } from "../firebase";
import { fireApiSuccessEvent } from "../analytics";
import { useNavigate } from "react-router-dom";
import FullPageLoader from "../components/loader/FullPageLoader";
import AvailabilityCard from "../components/AvailabilityCard/AvailabilityCard";
import ConfirmPopup from "../components/Popup/ConfirmPopup/ConfirmPopup";
import Header from "../components/Header/Header";
import { NavLink } from "react-router-dom";
import { FiChevronLeft, FiChevronRight, FiUpload } from "react-icons/fi";
import TitleBubble from "../components/MeetingDetails/TitleBubble";
import useError from "../utilities/error";
import { toast } from 'react-hot-toast';
import SharePopup from "../components/Popup/SharePopup/SharePopup";
import { AnalyticsApiEvent } from "../enum/analyticsEvent";
import { epochToGrid, gridToEpoch } from "../utilities/dataProcessing";
import { Helmet } from "react-helmet";
import useCreateAvailabilityContext from "../components/AvailabilityCard/Subcomponents/useCreateAvailabilityContext";
import { AvailabilityContext } from "../components/AvailabilityCard/Subcomponents/AvailabilityContext";
import DefaultButton from "components/Ui/Button/DefaultButton";

const RecipientPage = (props) => {
    const editMode = props.editMode ?? false;

    const meetingID = (new URLSearchParams(window.location.search)).get("meeting");

    const authContext = useAuth();
    const navigate = useNavigate();
    const error = useError();

    const [meetingInfo, setMeetingInfo] = useState(null);
    const [gridInfo, setGridInfo] = useState(null);
    const availability = useRef([]);
    const [updateAvailabilityPopup, setUpdateAvailabilityPopup] = useState(false);
    const [showSharePopup, setShowSharePopup] = useState(false); // this is the state variable for the share popup [true/false]
    const [expanded, setExpanded] = useState(false); // this is the state variable for the title card expanded state

    useEffect(() => {

        if (authContext.currentUser === null) {
            // if the user is not logged in, redirect to the login page with custom redirect
            let redirectUrl = encodeURIComponent(window.location.pathname + window.location.search);
            navigate(`/login?redirect=${redirectUrl}&from=recipient`);
        }

        async function fetchAll() {

            fetch(`${constants.functionsBaseUrl}/meeting/${meetingID}/overlaps`, {
                headers: {
                    Authorization: `Bearer ${await authContext.currentUser.getIdToken()}`,
                },
            }).then(res => res.json()).then(res => {
                if (res.code === 200) {
                    // convert epoch data to grid data
                    setMeetingInfo(res.data);
                    let gridData = epochToGrid(res.data, 30, authContext.currentUser.uid);
                    availability.current = gridToEpoch(gridData.intervalGrid, gridData.initialSelected);
                    setGridInfo(gridData);
                    return;
                }
                if (res.code === 404) return setMeetingInfo(null);
                error('SUNSET', res.code, "Meeting overlap fetch returned an invalid response.");
            }).catch(err => {
                console.error(err);
                error('WORM', 500, "Failed to fetch meeting overlap.");
            });
        }

        async function runEffect() {
            if (authContext.currentUser) {
                const participantStatus = await fetch(`${constants.functionsBaseUrl}/meeting/${meetingID}/participant/self`, {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${await authContext.currentUser.getIdToken()}`,
                    }
                }).then(res => res.json());

                if (!editMode && participantStatus.code === 200) {
                    return navigate(`/meeting/${meetingID}`);
                }
                fetchAll();
            }

            if (authContext.currentUser === null) fetchAll();
        }

        runEffect();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authContext.currentUser, setMeetingInfo, setGridInfo, meetingID, navigate, editMode]);

    const initialState = {
        show: false,
        signup: false,
        login: false,
        intro: false
    };

    function reducerFun(state, action) {
        switch (action) {
            case "intro":
                return { ...state, intro: true };
            case "login":
                return { ...state, login: true, signup: false, intro: false };
            case "signup":
                return { ...state, login: false, signup: true, intro: false };
            case "show":
                return { ...state, show: !state.show };
            case "reset":
                return { show: false, signup: false, login: false, intro: false };
            default:
                return { ...state };
        }
    }

    const [state, dispatch] = useReducer(reducerFun, initialState);

    async function submit(method) {
        if (editMode) {
            method = "PUT";
        }

        if (authContext.currentUser) {
            fetch(`${constants.functionsBaseUrl}/meeting/${meetingID}/join`, {
                method,
                headers: {
                    Authorization: `Bearer ${await authContext.currentUser.getIdToken()}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ availability: availability.current }),
            }).then(res => res.json()).then(res => {
                if (res.code === 200) {
                    if (method === "PUT") fireApiSuccessEvent(AnalyticsApiEvent.MEETING_AVAILABILITY_EDIT);
                    toast.success("Your availability has been added");
                    navigate(`/meeting/${meetingID}`);
                    return;
                }
                if (res.code === 409) return setUpdateAvailabilityPopup(true)
                error('OCEAN', res.code, "Received invalid response when joining meeting.");
            }).catch(err => {
                console.error(err);
                error('WHALE', 500, "Failed to join meeting.");
            });
        } else dispatch("show");
    }

    function handleTitleCardState() {
        setExpanded(!expanded);
    }

    const availabilityContext = useCreateAvailabilityContext("EDIT", gridInfo);

    useEffect(() => {
        function setValue(value) {
            availability.current = gridToEpoch(gridInfo?.intervalGrid, value);
        }

        setValue(availabilityContext?.selected);
    }, [availabilityContext?.selected, gridInfo?.intervalGrid])

    if (!meetingInfo || !gridInfo) return (
        <>
            <Helmet>
                <title>Loading...</title>
            </Helmet>
            <FullPageLoader message="Overlap is fetching the meeting information for you, It may take few seconds!" />
        </>
    );

    // set a variable for the meeting invite link
    const meetingInvite = `${window.location.origin}/t/invite?meeting=${meetingID}`;

    return (
        <>
            <AvailabilityContext.Provider value={availabilityContext}>
                <Helmet>
                    <title>{meetingInfo.title}</title>
                </Helmet>
                <Header />
                <main className={Classes.noFooterMain}>
                    {/* sign in sign up portal  */}
                    {state.show ? (
                        <PortalMask hide={() => dispatch("show")}>
                            {state.signup === false && state.login === false ? (
                                <UserLogin
                                    login={() => dispatch("login")}
                                    signup={() => dispatch("signup")}
                                />
                            ) : null}
                            {state.signup && <Signup onSuccess={() => dispatch("show")} login={() => dispatch("login")} />}
                            {state.login && <Login onSuccess={() => submit("POST")} signup={() => dispatch("signup")} />}
                        </PortalMask>
                    ) : null}

                    {/* <Signup /> */}
                    {/* <Login /> */}
                    {/* availablity contents  */}
                    <div className={Classes.wrap}>

                        <div className={Classes.topIconBar}>
                            {editMode &&
                                <NavLink to={`/meeting/${meetingID}`} className={`${Classes.backButton} ${Classes.iconButton}`}>
                                    <FiChevronLeft />
                                </NavLink>
                            }

                            <div className={Classes.spacer}></div>

                            <div className={`${Classes.shareButton} ${Classes.iconButton}`} onClick={() => {
                                setShowSharePopup(true);
                            }}><FiUpload /></div>

                        </div>

                        <div className={Classes.titleBubbleContainer}>
                            <TitleBubble
                                meetingInfo={meetingInfo}
                                gridInfo={gridInfo}
                                expanded={expanded}
                                setExpanded={handleTitleCardState}
                            />
                        </div>

                        <div className={Classes.instructionBox}>
                            <div className={Classes.instructionTitle}>
                                {editMode ? "Edit Your Availability" : "Add Your Availability"}
                            </div>
                            <div className={Classes.instructionSubtitle}>
                                Click and Drag
                            </div>
                        </div>

                        {/* week card  */}
                        <div className={Classes.availabilityCardContainer}>
                            <AvailabilityCard
                                data={gridInfo}
                                mode={"EDIT"}
                            />
                        </div>
                    </div>

                    {updateAvailabilityPopup && <ConfirmPopup
                        title={"Update availability"}
                        message={"You are already a participant. Do you wish to update your availability?"}
                        onReject={() => { setUpdateAvailabilityPopup(false); }}
                        onConfirm={() => { submit("PUT") }}
                    />}

                    <div className={Classes.nextButtonContainer}>
                        <DefaultButton
                            text="Done"
                            rightIcon={<FiChevronRight/>}
                            rightLarge={true}
                            disabled={!availability}
                            hasShadow={true}
                            darker={true}
                            onClick={() => {
                                if (availability) {
                                    submit("POST");
                                }
                            }}
                        />
                    </div>

                    {showSharePopup &&
                        <SharePopup
                            shareText={`You’re invited to join me for “${meetingInfo.title}”. Click here to add all times you’re available to meet.`}
                            url={meetingInvite}
                            onClose={() => setShowSharePopup(false)}
                        />
                    }
                </main>
            </AvailabilityContext.Provider>
        </>
    );
};

export default RecipientPage;