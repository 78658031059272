import { useReducer, useContext, createContext } from "react";
import "./scss/CreateAMeeting.scss";
import Classes from "../pages/scss/NewMeetingPage.module.scss";
import MeetingName from "../components/NewMeeting/Name/MeetingName";
import MeetingTime from "../components/NewMeeting/Time/MeetingTime";
import MeetingAvailability from "../components/NewMeeting/Availability/MeetingAvailability";
import FormProgressBar from "../components/NewMeeting/FormProgressBar/FormProgressBar";
import MeetingCreationComplete from "../components/NewMeeting/MeetingCreated/MeetingCreationComplete";
import Header from "../components/Header/Header";
import { applyEpochToGridData, convertMinutesToTimeRange, convertToDateString, epochToGrid, generateBlankEpochData, gridToEpoch } from "../utilities/dataProcessing";
import useCreateAvailabilityContext from "../components/AvailabilityCard/Subcomponents/useCreateAvailabilityContext";
import { AvailabilityContext } from "../components/AvailabilityCard/Subcomponents/AvailabilityContext";
import ConfirmPopup from "../components/Popup/ConfirmPopup/ConfirmPopup";
import { Helmet } from "react-helmet";
import { useUserSettings } from "context/userSettingsContext";
import DefaultButton from "components/Ui/Button/DefaultButton";
import { FiChevronRight } from "react-icons/fi";

const MeetingContext = createContext();

export function useMeetingContext() {
    return useContext(MeetingContext);
}

function validateMeetingName(name, description) {
    // valid if it has at least one match for this regex
    // [^\s]+

    if (!name || !name.match(/[^\s]+/g)) {
        return false;
    }
    return true;
}

function validateMeetingDates(days) {
    if (days.length < 1 || days.length > 7) {
        return false;
    }
    return true;
}

function validateTimeRange(timeRange) {
    if (timeRange[0] > timeRange[1]) {
        return false;
    } else if (timeRange[1] - timeRange[0] < 3 * 60) {
        return false;
    }
    return true;
}

function validateStage(stageId, state) {
    switch (stageId) {
        case 0: return validateMeetingName(state.meetingTitle, state.meetingDescription);
        case 1: return validateMeetingDates(state.selectedDates);
        case 2: return validateTimeRange(state.timeRange);
        default: return true;
    }
}

// This reducer controls how the page responds to various actions
function NewMeetingReducer(state, action) {

    let isValid;

    switch (action.type) {

        case "NEXT_STAGE":
            // if the current stage canProgress, move to the next stage
            if (state.canProgress) {

                let nextPageIsValid = validateStage(state.activeStage + 1, state);
                let showErrors = (state.activeStage + 1 === 1) ? false : !nextPageIsValid;

                if (state.activeStage + 1 === 2) {
                    // if the next page is the availability page, generate the grid data
                    let timeRange = convertMinutesToTimeRange(state.timeRange);
                    let dates = state.selectedDates.map((date) => convertToDateString(date));
                    let interval = 30;
                    let epochData = generateBlankEpochData(dates, timeRange, interval);
                    let gridData = epochToGrid(epochData, 30, '', null, null, action.payload?.defaultAvailability);
                    if (action.payload?.availability) {
                        // get the current state and apply it to the current gridData
                        gridData = applyEpochToGridData(gridData, action.payload.availability);
                    }
                    return {
                        ...state,
                        activeStage: state.activeStage + 1,
                        revealErrors: showErrors,
                        canProgress: nextPageIsValid,
                        gridData,
                    };
                }

                if (state.activeStage + 1 === 3) {
                    // if the next page is the meeting creation complete page, check if there is any availability, unless the show no availability popup is already showing, in which case, continue as normal
                    if (action.payload?.availability.length === 0 && !state.showNoAvailabilityPopup) {
                        // if there is no availability, show the no availability popup
                        return {
                            ...state,
                            showNoAvailabilityPopup: true,
                        }
                    }

                    if (state.showNoAvailabilityPopup) {
                        // if the no availability popup is already showing and we have validated the stage, then we can progress and close the popup
                        return {
                            ...state,
                            activeStage: state.activeStage + 1,
                            revealErrors: showErrors,
                            canProgress: nextPageIsValid,
                            showNoAvailabilityPopup: false,
                        }
                    }
                }

                return {
                    ...state,
                    activeStage: state.activeStage + 1,
                    revealErrors: showErrors,
                    canProgress: nextPageIsValid,
                };
            }
            // otherwise do not progress
            else {
                return state;
            }
        
        case "PREVIOUS_STAGE":
            // if the current stage is the first stage, do not go back
            if (state.activeStage === 0) return state;

            // validate previous page
            let prevPageIsValid = validateStage(state.activeStage - 1, state);

            // otherwise, go back a stage
            return {
                ...state,
                activeStage: state.activeStage - 1,
                revealErrors: !prevPageIsValid,
                canProgress: prevPageIsValid,
            };

        // This action is called whenever the meeting name component is updated
        case "MEETING_NAME_UPDATE":
            // action.payload = {name: string, description: string}
            isValid = validateMeetingName(action.payload.name, action.payload.description);
            return {
                ...state,
                meetingTitle: action.payload.name,
                meetingDescription: action.payload.description,
                revealErrors: !isValid,
                canProgress: isValid,
            };
        
        case "DAYS_UPDATE":
            // action.payload = {dates: string[]}
            isValid = validateMeetingDates(action.payload.dates);
            return {
                ...state,
                selectedDates: action.payload.dates,
                revealErrors: !isValid,
                canProgress: isValid,
            };
        
        case "TIME_RANGE_UPDATE":
            // action.payload = {timeRange: [number, number]}
            isValid = validateTimeRange(action.payload.timeRange);
            if (isValid) {
                // if the time range is valid, update the time range and the current time range

                let timeRange = convertMinutesToTimeRange(state.timeRange);
                let dates = state.selectedDates.map((date) => convertToDateString(date));
                let interval = 30;
                let epochData = generateBlankEpochData(dates, timeRange, interval);
                let gridData = epochToGrid(epochData, 30, '', null, null, action.payload?.defaultAvailability);
                if (action.payload?.availability) {
                    // get the current state and apply it to the current gridData
                    gridData = applyEpochToGridData(gridData, action.payload.availability);
                }

                return {
                    ...state,
                    timeRange: action.payload.timeRange,
                    currentTimeRange: action.payload.timeRange,
                    revealErrors: false,
                    canProgress: true,
                    gridData,
                }
            } else {
                // otherwise, do not update the real time range, just the displayed one, and show errors
                return {
                    ...state,
                    currentTimeRange: action.payload.timeRange,
                    revealErrors: true,
                    canProgress: false,
                }
            }
        
        case "CLOSE_NO_AVAILABILITY_POPUP":
            return {
                ...state,
                showNoAvailabilityPopup: false,
            }

        default:
            return state;
    }
}

const NewMeeting = (_props) => {

    // This section sets up the state and shared information for all the stages
    // Stage names, titles, and components are declared as static variables, and any information which can change is stored in the state reducer
    const stageNames = ["Name", "Days", "Availability"];
    const stageTitles = ["Create a Meeting Availability Poll", "Choose Possible Days", "Add Your Availability"];
    const stageComponents = [<MeetingName/>, <MeetingTime/>, <MeetingAvailability/>];
    const stageCount = stageComponents.length;
    const [state, dispatch] = useReducer(NewMeetingReducer, {
        activeStage: 0, // stores which page is active
        canProgress: false, // controls whether the next button is active
        meetingTitle: "", // stores the meeting title
        meetingDescription: "", // stores the meeting description
        revealErrors: false, // controls whether the current page should reveal errors
        selectedDates: [], // stores the selected dates as a "YYYY-MM-DD" string
        timeRange: [540, 1020], // stores the time range of the user
        currentTimeRange: [540, 1020], // stores the (possibly invalid) time range of the user,
        gridData: null, // stores the grid data for the availability component
        showNoAvailabilityPopup: false, // if true, will show the no availability popup screen
    });

    const serverSettings = useUserSettings();

    const availabilityContext = useCreateAvailabilityContext("EDIT", state.gridData);

    /**
     * Wrapper function for dispatching the next stage
     */
    async function nextStage() {
        let defaultAvailability = null;
        if (await serverSettings.defaultAvailabilityEnabled) {
            defaultAvailability = await serverSettings.defaultAvailability;
        }
        if (state.activeStage === 1 || state.activeStage === 2) {
            dispatch({type: "NEXT_STAGE", payload: {
                availability: availabilityContext ? gridToEpoch(availabilityContext.intervalGrid, availabilityContext.selected) : [],
                defaultAvailability, 
            }});
        } else {
            dispatch({type: "NEXT_STAGE"});
        }
    }

    return (
        <>
            <Helmet>
                <title>New Meeting</title>
            </Helmet>
            <AvailabilityContext.Provider value={availabilityContext}>
                <Header className={'no-mobile'}/>
                <main className="no-header">
                    <MeetingContext.Provider
                        value={{
                            state,
                            dispatch,
                            stageNames,
                            stageTitles,
                            stageCount,
                        }}
                    >
                        <div className={`${Classes.containerWrap} ${state.activeStage === stageCount ? Classes.noBottomPadding : ''}`}>
                            {/* If meeting creation is not yet completed */}
                            {state.activeStage !== stageCount && (
                                <>
                                    <h3 className={Classes.title}>{stageTitles[state.activeStage]}</h3>
                                    {stageComponents[state.activeStage]}

                                    <FormProgressBar
                                        position={state.activeStage}
                                    />

                                    <div className={Classes.nextButtonContainer}>
                                        { (state.activeStage === 0 || state.activeStage === 1) && (
                                            <DefaultButton
                                                disabled={!state.canProgress}
                                                text="Next"
                                                rightIcon={<FiChevronRight/>}
                                                rightLarge={true}
                                                onClick={nextStage}
                                                hasShadow={true}
                                            />
                                        )}
                                        { state.activeStage === 2 && (
                                            <DefaultButton
                                                disabled={!state.canProgress}
                                                text="Submit"
                                                rightIcon={<FiChevronRight/>}
                                                rightLarge={true}
                                                onClick={nextStage}
                                                hasShadow={true}
                                                darker={true}
                                            />
                                        )}
                                    </div>
                                </>
                            )}

                            { state.showNoAvailabilityPopup && 
                                <ConfirmPopup
                                    title="No Availability?"
                                    message="Are you sure you want to submit a time table with no availability? You won't be able to view your overlapping availability with your group."
                                    buttonText="Submit"
                                    onCancel={() => {dispatch({type: "CLOSE_NO_AVAILABILITY_POPUP"})}}
                                    onConfirm={nextStage}
                                />
                            }

                            {/* If meeting creation is completed */}
                            {state.activeStage === stageCount && (
                                <MeetingCreationComplete/>
                            )}
                        </div>
                    </MeetingContext.Provider>
                </main>
            </AvailabilityContext.Provider>
        </>
    );
};

export default NewMeeting;



