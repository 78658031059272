import Header from "../../components/Header/Header";
import React from "react";
import Classes from "../scss/WelcomePage.module.scss";
import { Helmet } from "react-helmet";

const PrivacyPolicy = () => {
    return (
        <>
            <Helmet>
                <title>Privacy Policy</title>
            </Helmet>
            <Header/>
            <main className={Classes.customMain}>
                <div className={Classes.pageContainer}>
                    <iframe
                        src="/legal/privacy-policy.html"
                        title="Privacy Policy"
                        style={{ width: '100%', height: 'calc(100vh - 110px)', border: 'none' }}
                    />
                </div>
            </main>
        </>
    )
}

export default PrivacyPolicy;