import { FiGrid, FiUpload } from 'react-icons/fi';
import RowLinkButton from '../RowLinkButton/RowLinkButton';
import Classes from './ShareMeetingStack.module.scss';
import { canShare, copyToClipboard, openShareMenu } from '../../../utilities/share';
import {fireButtonClickEvent} from "../../../analytics";
import {AnalyticsButtonEvent} from "../../../enum/analyticsEvent";
import OverlapText from 'components/Utilities/OverlapText';

/**
 * Contains the buttons and title for sharing a meeting.
 * Props:
 * - shareText
 * - url (meeting url)
 * - onClick (runs when any of the buttons are clicked. Passes either "copyLink", "QRShare", or "shareVia" as the first argument.)
 */
export default function ShareMeetingStack(props) {

    function onClick(string) {
        if (string === "copyLink") fireButtonClickEvent(AnalyticsButtonEvent.LINK_COPY);
        else if (string === "QRShare") fireButtonClickEvent(AnalyticsButtonEvent.LINK_QR_SHARE);
        else if (string === "shareVia") fireButtonClickEvent(AnalyticsButtonEvent.LINK_SHARE_VIA);

        if (props.onClick) {
            props.onClick(string);
        }
    }

    let linkShareUrl = new URL(props.url);
    linkShareUrl.searchParams.set("utm_source", "link");
    linkShareUrl = linkShareUrl.toString();

    return (
        <div className={Classes.container}>
            
            <div className={Classes.linkBox}>
                <span className={Classes.linkText}>{linkShareUrl}</span>
                <button className={Classes.shareButton} onClick={() => {
                    copyToClipboard(linkShareUrl, "Meeting link copied!");
                    onClick("copyLink");
                }}>
                    <OverlapText size={14} color='white' style={{textWrap: 'nowrap'}}>Copy Link</OverlapText>
                </button>
            </div>
            {/* <RowLinkButton
                text="Copy link"
                icon={<FiLink2/>}
                iconStyle={Classes.copyLinkIcon}
                onClick={() => {
                    copyToClipboard(linkShareUrl, "Meeting link copied!");
                    onClick("copyLink");
                }}
            /> */}
            <RowLinkButton
                text="Share with QR code"
                icon={<FiGrid/>}
                iconStyle={Classes.QRShareIcon}
                onClick={() => {
                    onClick("QRShare");
                }}
            />
            {canShare("Overlap", props.shareText, linkShareUrl) &&
                <RowLinkButton
                    text="Share via..."
                    icon={<FiUpload/>}
                    iconStyle={Classes.shareViaIcon}
                    onClick={() => {
                        openShareMenu("Overlap", props.shareText,linkShareUrl);
                        onClick("shareVia");
                    }}
                />
            }
        </div>
    )
}