import { FiChevronDown, FiSearch } from 'react-icons/fi';
import Classes from './SearchDropDownInput.module.scss';
import { useEffect, useRef, useState } from 'react';
import { useOutsideClick } from '../../../utilities/clickHandling';

/**
 * A custom stylized dropdown input.
 * Props:
 * - disabled: boolean (optional)
 * - options: array of strings
 * - value: string, preferably state
 * - setValue: function, preferably state setter
 * - onChange: function, called when the value should change. Passes clicked value as argument.
 * - closeOnClick: boolean, if true will close when a new value is selected
 */
export default function SearchDropDownInput(props) {

    // props
    const disabled = props.disabled ?? false;

    // close when clicked outside of dropdown
    const dropdownRef = useRef();
    useOutsideClick(dropdownRef, () => setActive(false));

    // state
    const [active, setActive] = useState(false);
    const [search, setSearch] = useState('');

    // set active to false when disabled is change
    useEffect(() => {
        setActive(false);
    }, [disabled])

    // set search to reset whenever active is set to false
    useEffect(() => {
        if (!active) setSearch('');
    }, [active])

    //handler
    function toggleActive(event) {
        event.preventDefault();
        event.stopPropagation();

        if (disabled) return;
        setActive(!active);
    }

    function onChange(event, newValue) {
        event.preventDefault();
        event.stopPropagation();

        if (disabled) return;

        if (props.onChange) {
            props.onChange(newValue);
        } else {
            props.setValue(newValue);
        }

        if (props.closeOnClick === true) {
            setActive(false);
        }
    }

    function searchOnChange(event) {
        setSearch(event.target.value);
    }

    // return disabled form if disabled
    if (disabled) return (
        <div className={`${Classes.inputWrapper} ${disabled ? Classes.disabled : ''}`} ref={dropdownRef}>
            <div>{props.value}</div>
        </div>
    )

    else {
        // filter options
        const filteredOptions = props.options.filter(option => {
            return option.toLowerCase().includes(search.toLowerCase());
        });

        return (
            <div className={`${Classes.inputWrapper} ${active ? Classes.active : ''}`} onClick={toggleActive} ref={dropdownRef}>
                <div>{props.value}</div>
                <div className={Classes.downArrow}><FiChevronDown/></div>

                { active &&
                    <div className={Classes.dropDownWrapper} onClick={(e) => e.stopPropagation()}>
                        <div className={Classes.searchBar}>
                            <FiSearch/>
                            <input className={Classes.searchInput} type="text" placeholder="Search" value={search} onChange={searchOnChange}/>
                        </div>
                        <div className={Classes.optionsWrapper}>
                            <div className={Classes.optionsContainer}>
                                {filteredOptions.map((option, index) => {
                                    return (
                                        <div className={`${Classes.option} ${option === props.value ? Classes.active : ''}`} key={index} onClick={(event) => {onChange(event, option)}}>{option}</div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}