import ConfirmPopup from '../ConfirmPopup/ConfirmPopup';

function LeavePopup(props) {
    /**
     * props:
     * - onCancel
     * - onLeave
     */

    return (
        <ConfirmPopup
            title="Leave meeting?"
            message="Are you sure you want to leave the meeting?"
            buttonText="Leave"
            onCancel={props.onCancel}
            onConfirm={props.onLeave}
        />
    )
}

export default LeavePopup;