import Classes from './iOSToggleSwitch.module.scss';

/**
 * An iOS style toggle switch.
 * 
 * Props:
 * - `checked`: boolean, whether the switch is on or off, should be state.
 * - `setChecked`: function, sets the checked state. Should be a state setter.
 * - `onClick`: function, allows you to manually control the state
 */
export default function IOSToggleSwitch(props) {

    function handleClick(event) {
        event.stopPropagation();
        event.preventDefault();

        if (props.onClick) {
            props.onClick(event);
        }

        if (props.setChecked) {
            props.setChecked(!props.checked);
        }
    }

    return (
        <div className={Classes.toggleSwitch} onClick={handleClick}>
            <input 
                type="checkbox"
                checked={props.checked}
                readOnly
            />
            <i></i>
        </div>
    );
}
