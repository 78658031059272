import ConfirmPopup from '../ConfirmPopup/ConfirmPopup';
import { FiLogOut } from 'react-icons/fi';

function LogoutPopup(props) {
    /**
     * props:
     * - onCancel
     * - onLogout
     */

    return (
        <ConfirmPopup
            title="Log out"
            message="Are you sure you want to log out?"
            buttonText="Log Out"
            buttonIcon={<FiLogOut/>}
            onCancel={props.onCancel}
            onConfirm={props.onLogout}
        />
    )
}

export default LogoutPopup;