import PortalMask from '../../../HOC/PortalMask';
import Classes from './SharePopup.module.scss';
import ShareMeetingStack from '../../Ui/ShareMeetingStack/ShareMeetingStack';
import { useEffect, useState } from 'react';
import InplaceCircle from '../../loader/InplaceCircle/InplaceCircle';
import { FiChevronLeft } from 'react-icons/fi';
var QRCode = require('qrcode');

/**
 * Props:
 * - shareText
 * - url (meeting url)
 * - onClose (runs when the popup should be closed)
 */
export default function SharePopup(props) {

    const [qrMode, setQrMode] = useState(false);
    const [qrCodeUrl, setQrCodeUrl] = useState(null);

    useEffect(() => {
        async function generateQR() {
            let qrShareUrl = new URL(props.url);
            qrShareUrl.searchParams.set("utm_source", "qr");
            qrShareUrl = qrShareUrl.toString();

            let url = await QRCode.toDataURL(qrShareUrl, {width: 600});
            setQrCodeUrl(url);
        }

        if (qrMode) {
            generateQR();
        }
    }, [qrMode, props.url])

    useEffect(() => {
        // if props.qrOnly is true, then set qrMode to true and hide the back button
        if (props.qrOnly === true) {
            setQrMode(true);
        }
    }, [props.qrOnly])

    function close() {
        if (props.onClose) {
            props.onClose();
        }
    }

    function handleButtonClick(buttonName) {
        if (buttonName === "copyLink" || buttonName === "shareVia") {
            close();
        }

        if (buttonName === "QRShare") {
            setQrMode(true);
        }
    }

    if (props.qrOnly && !qrMode) {
        return (<></>);
    }

    return (
        <PortalMask hide={props.onClose}>
            <div className={Classes.cardWrap} onClick={(e) => e.stopPropagation()}>
                {!qrMode && <>
                    <div className={Classes.shareTitle}>Share Meeting</div>
                    <ShareMeetingStack
                        shareText={props.shareText}
                        url={props.url}
                        onClick={handleButtonClick}
                    />
                </>}
                {qrMode &&
                <>
                    <div className={Classes.topRow}>
                        {!props.qrOnly &&
                            <div className={Classes.backButton} onClick={() => setQrMode(false)}>
                                <FiChevronLeft/>
                            </div>
                        }
                        <div className={Classes.qrTitle}>QR Code</div>
                        {!props.qrOnly &&
                            <div className={Classes.spacer}></div>
                        }
                    </div>
                    <div className={Classes.qrContainer}>
                        {!qrCodeUrl &&
                            <InplaceCircle/>
                        }
                        {qrCodeUrl &&
                        <>
                            <img className={Classes.qrCode} src={qrCodeUrl} alt="QR code"/>
                        </>
                        }
                    </div>
                </>
                }
                <div className={Classes.cancelButton} onClick={props.onClose}>
                    Done
                </div>
            </div>
        </PortalMask>
    )
}