import React, { useEffect, useState } from 'react';
import Classes from './BubbleBackground.module.scss';
import { createNoise2D } from 'simplex-noise';
import seedrandom from 'seedrandom';

function normalizeNoise(value, location, center, gridSize) {
    // 50% chance of being white
    if (Math.random() < 0.5) {
        // return 0;
    }

    // Scale noise value from 0 to 3
    let normalized = (value + 1) / 2 * 4 + 0.1;

    if (normalized > 3) {
        normalized = 3;
    }

    // Now, scale to our desired range, 0 to 3.
    // We use a non-linear function to make 0 most common and 3 very rare.
    return Math.floor(normalized);
}

const rng = seedrandom('never gonna give you up'); // you can change the seed to get different results

function generateNoiseMap(width, height, frequency = 0.1) {

    const noise2D = createNoise2D(rng);
    let noiseMap = Array.from({ length: height }, () =>
      Array.from({ length: width }, () => 0)
    );

    const center = [width / 2, height / 2];
  
    for (let y = 0; y < height; y++) {
      for (let x = 0; x < width; x++) {
        let value = noise2D(x * frequency, y * frequency);
        noiseMap[y][x] = normalizeNoise(value, [x, y], center);
      }
    }
    
    return noiseMap;
}

const noiseMap = generateNoiseMap(999, 999, 12);

function BubbleBackground(props) {
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

    useEffect(() => {
        function updateSize() {
            setDimensions({ width: window.innerWidth, height: window.innerHeight });
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    
    // white, light gray, green, dark green
    const colors = ['#ffffff', '#ebf3ef', '#cbe0d8', '#6ead91'];

    const rectSize = [50, 30];

    const rows = Math.ceil(dimensions.height / rectSize[1]);
    const cols = Math.ceil(dimensions.width / rectSize[0]);

    let rowElements = [];

    for (let i = 0; i < rows; i++) {

        let colElements = [];

        for (let k = 0; k < cols; k++) {
            let colorIndex = noiseMap[i][k];

            // get distance from center
            let distance = Math.sqrt(Math.pow(k - cols / 2, 2) + Math.pow(i - rows / 2, 2));

            // get max distance
            let maxDistance = Math.sqrt(Math.pow(cols / 2, 2) + Math.pow(rows / 2, 2));

            // normalize distance
            distance = distance / maxDistance;

            // depending on distance, scale the colorIndex value. The colorIndex should decrease the further away it gets
            // scale based on a non-linear function, drop off more as it gets further away
            colorIndex = Math.floor((colorIndex + 0.9) * Math.pow(1 - distance, 1.1));


            let color = colors[colorIndex];
            colElements.push(
                <div className={Classes.rectangleWrapper}>
                    <div 
                        key={k}
                        className={Classes.rectangle}
                        style={{ backgroundColor: color }}
                    />
                </div>
            );
        }

        rowElements.push(colElements);
    }

    return (
        <>
            <div className={Classes.wrapper}>
                {rowElements.map((cols, index) => {
                    return (
                        <div key={index} className={Classes.row}>
                            {cols}
                        </div>
                    )
                })}
            </div>
            <div className={Classes.gradientWrapper}></div>
        </>
    )
}

export default BubbleBackground;
