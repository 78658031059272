import React, { Fragment } from "react";
import ReactDOM from "react-dom";
import Classess from "./PortalMask.module.scss";

const PortalMask = ({hide, children, noBlur=false}) => {
    const hideHandler = (e) => {
        if (!noBlur) {
            e.stopPropagation();
        }
        hide ? hide() : console.log("hide handler not available");
    };

    return ReactDOM.createPortal(
        <Fragment>
            <div className={Classess.wrap}>
                <div
                    onClick={() => console.log("portal mask")}
                    className={`${Classess.mask} ${noBlur ? Classess.noBlur : ''}`}
                ></div>
                <div
                    onClick={hideHandler}
                    className={Classess.contentWrap}
                >
                        {children}
                </div>
            </div>
        </Fragment>,
        document.getElementById("portal")
    );
};

export default PortalMask;
