import React from 'react';
import Classes from './DropDown.module.scss';

const DropDown = (props) => {
    /**
     * DropDown should be used by enclosing multiple DropDownOption tags inside it.
     */

    return (
        <div className={Classes.dropDownContainer}>
            {props.children}
        </div>
    );
}

export default DropDown;