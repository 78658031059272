import React from "react";
import Classess from "./scss/Meetinglist.module.scss";
// import NewMeetingBtn from "../components/Ui/NewMeetingBtn/NewMeetingBtn";
import MeetingList from "../components/MeetingList/MeetingList";
import Header from "../components/Header/Header";
import { Helmet } from "react-helmet";

const HomeMeetinglistPage = () => {
    return (
        <>  
            <Helmet>
                <title>My Meetings</title>
            </Helmet>
            <Header/>
            <main>
                <div className={Classess.pageWrap}>
                    {/* button wrap  */}
                    {/* <div className={Classess.btnWrap}>
                        <NewMeetingBtn />
                    </div> */}
                    {/* meeting list  */}
                    <MeetingList />
                </div>
            </main>
        </>
    );
};

export default HomeMeetinglistPage;
