
export function selectText(ref) {
    if (ref.current.select) {
        // reference is an input element, select it
    }

    // otherwise use a different method
    else if (document.body.createTextRange) {
        // use the text range feature. This is newer
        const range = document.body.createTextRange();
        range.moveToElementText(ref.current);
        range.select();
    }
    else if (window.getSelection) {
        // use the selection feature. This is older
        const selection = window.getSelection();
        const range = document.createRange();
        range.selectNodeContents(ref.current);
        selection.removeAllRanges();
        selection.addRange(range);
    }
    else {
        // this browser is not supported
        console.warn("Failed to auto-select text in name change input! Browser may not be supported.");
    }
}