import Classes from './FormAlertTemplate.module.scss';
import { FiAlertOctagon } from 'react-icons/fi';

function FormAlertTemplate(props) {

    const icon = props.icon ?? <FiAlertOctagon fontSize={20}/>;

    return (
        <div className={Classes.alertWrapper}>
            <div className={Classes.alertBox} style={{borderColor: props.borderColor, backgroundColor: props.backgroundColor}}>
                <div className={Classes.alertIcon}>{icon}</div>
                <div className={Classes.alertText}>
                    {props.children}
                </div>
            </div>
        </div>
    )
}

export default FormAlertTemplate;