import LaptopPhone from "../assets/images/laptopPhone.svg";
import BubbleStack from "../assets/images/bubbleStack.svg";
import AvailabilityGraphic from "../assets/images/availabilityGraphic.png";
import DarkerGreenGraphic from "../assets/images/darkerGreenGraphic.png";
import Noah from "../assets/images/noah.png";
import Joe from "../assets/images/joe.png";
import Karthik from "../assets/images/karthik.png";
import David from "../assets/images/david.jpeg";
import Isaac from "../assets/images/isaac.png";

export default function getImg(type) {
    if (type === "laptopPhone") {
        return LaptopPhone;
    } else if (type === "bubbleStack") {
        return BubbleStack;
    } else if (type === "availability") {
        return AvailabilityGraphic;
    } else if (type === "darkerGreen") {
        return DarkerGreenGraphic;
    } else if (type === "Noah Hubbard") {
        return Noah;
    } else if (type === "Joseph Johnson") {
        return Joe;
    } else if (type === "Karthik Nedunchezhiyan") {
        return Karthik;
    } else if (type === "David Bootle") {
        return David;
    } else if (type === "Isaac Kim") {
        return Isaac;
    } else {
        return <div>enter valid image type</div>;
    }
}