import BottomPopup from './BottomPopup';
import Classes from './SignInWithGoogleNotAvailabilePopup.module.scss';
import ChromeLogoUrl from '../../../assets/icon/chrome-logo.svg';
import SafariLogoUrl from '../../../assets/icon/Safari_browser_logo.svg';
import { FiChevronRight } from 'react-icons/fi';
import { useRef } from 'react';
// import FormWarning from '../../Ui/FormAlert/FormWarning';

/**
 * Popup that appears if sign in with google is clicked on a browser that doesn't support it. It prompts the user to redirect to a different browser.
 */
export default function SignInWithGoogleNotAvailabilePopup(props) {

    const chromeLink = useRef(null);
    const safariLink = useRef(null);

    function openChrome() {
        chromeLink.current.click();
    }

    function openSafari() {
        safariLink.current.click();
    }

    return (
        <BottomPopup onClose={props.onClose} wrapperClass={Classes.wrapper}>
            <h1 className={Classes.title}>Sign in with Google in...</h1>
            <div className={Classes.optionsList}>
                <div className={Classes.option} onClick={openChrome}>
                    <div className={Classes.iconContainer}>
                        <img className={Classes.icon} src={ChromeLogoUrl} alt="Chrome Logo" />
                    </div>
                    <div className={Classes.text}>Chrome</div>
                    <div className={Classes.arrow}><FiChevronRight/></div>
                </div>
                <div className={Classes.option} onClick={openSafari}>
                    <div className={Classes.iconContainer}>
                        <img className={Classes.icon} src={SafariLogoUrl} alt="Safari Logo" />
                    </div>
                    <div className={Classes.text}>Safari</div>
                    <div className={Classes.arrow}><FiChevronRight/></div>
                </div>
            </div>
            {/* <FormWarning>Sign in with Google is unavailable on this browser</FormWarning> */}
            <a ref={chromeLink} style={{display: 'none'}} href="googlechrome://myoverlap.com/login" target="_blank" rel="noreferrer">Open in Chrome</a>
            <a ref={safariLink} style={{display: 'none'}} href="https://myoverlap.com/login" target="_blank" rel="noreferrer">Open in Safari</a>
        </BottomPopup>
    )
}