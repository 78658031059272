import React from "react";
import Classes from './DefaultButton.module.scss';

/**
 * The default overlap button
 * @param {*} props
 * @param {function|null} props.onClick Callback function when the button is clicked
 * @param {string|null} props.className Allows you to override the default styles with your own
 * @param {string} props.text The text that is displayed on the button
 * @param {JSX.Element|null} props.leftIcon The icon that is displayed on the left side of the button
 * @param {JSX.Element|null} props.rightIcon The icon that is displayed on the right side of the button
 * @param {bool} props.leftLarge Increase the size of the left icon (great for arrows)
 * @param {bool} props.rightLarge Increase the size of the right icon (great for arrows)
 * @param {bool} props.disabled If true, the button is grayed out and unable to be clicked
 * @param {bool} props.hasShadow Controls whether the button has a shadow
 * @param {bool} props.fullWidth Controls whether the button has a width of 100%
 * @param {bool} props.darker Controls whether the button has a darker purple background
 */
const DefaultButton = (props) => {
    return (
        <button onClick={props.onClick} className={`${Classes.button} ${(props.leftIcon || props.rightIcon) ? Classes.withIcon : ''} ${props.disabled ? Classes.disabled : ''} ${props.hasShadow ? Classes.withShadow : ''} ${props.fullWidth ? Classes.fullWidth : ''} ${props.className} ${props.darker ? Classes.darker : ''} ${props.leftLarge ? Classes.shrinkLeftPadding : ''} ${props.rightLarge ? Classes.shrinkRightPadding : ''}`}>
            { props.leftIcon && 
                <div className={`${Classes.icon} ${props.leftLarge ? Classes.large : ''}`}>{props.leftIcon}</div>
            }
            <div className={Classes.text}>{props.text}</div>
            { props.rightIcon &&
                <div className={`${Classes.icon} ${props.rightLarge ? Classes.large : ''}`}>{props.rightIcon}</div>
            }
        </button>
    );
};

export default DefaultButton;
