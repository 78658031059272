import {isLocalhost} from "../src/utilities/environment";


/**
 * More references are available here https://developers.google.com/tag-platform/gtagjs/reference/events
 * */
export const fireEvent = (eventName, eventParams) => {
    if (!isLocalhost) window.gtag("event", eventName, eventParams);
}

/**
 * Punch analytics event for a page view
 **/
export const firePageViewEvent = pagePath => {
    fireEvent('page_view', {
        page_path: pagePath
    });
}

/**
 * Punch analytics event for a successful signup
 **/
export const fireSignUpEvent = method => {
    fireEvent('sign_up', {
        method
    });
}

/**
 * Punch analytics event for a button click action
 **/
export const fireButtonClickEvent = buttonID => {
    fireEvent('select_content', {
        content_type: "button",
        content_id: buttonID
    });
}

/**
 * Punch analytics event for a successful API completion
 **/
export const fireApiSuccessEvent = eventID => {
    fireEvent('select_content', {
        content_type: "api_success",
        content_id: eventID
    });
}