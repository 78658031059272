import react, {useEffect} from "react";
import {auth} from "../firebase";
import {fireSignUpEvent} from "../analytics";
import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    onAuthStateChanged,
    signOut,
    updateProfile,
    sendEmailVerification,
    updateEmail,
    signInWithPopup,
    GoogleAuthProvider,
    FacebookAuthProvider,
    GithubAuthProvider,
    OAuthProvider,
    RecaptchaVerifier
} from "firebase/auth";

const AuthContext = react.createContext();

export function useAuth() {
    return react.useContext(AuthContext);
}

export function AuthProvider({children}) {
    function signUp(email, password) {
        return createUserWithEmailAndPassword(auth, email, password);
    }

    function logIn(email, password) {
        return signInWithEmailAndPassword(auth, email, password);
    }

    function logInUsingGoogle() {
        return signInWithPopup(auth, new GoogleAuthProvider());
    }

    function logInUsingFacebook() {
        const provider = new FacebookAuthProvider();
        provider.addScope('email');
        provider.addScope('public_profile');
        provider.setCustomParameters({
            'display': 'popup'
        });
        return signInWithPopup(auth, provider);
    }

    function logInUsingGithub() {
        return signInWithPopup(auth, new GithubAuthProvider());
    }

    function logInUsingLinkedIn() {
        return signInWithPopup(auth, new OAuthProvider('oidc.linkedin'));
    }

    function updateUserProfile(update_info) {
        return updateProfile(auth.currentUser, update_info);
    }

    function changeEmail(email) {
        return updateEmail(auth.currentUser, email);
    }

    function verifyEmail() {
        return sendEmailVerification(auth.currentUser);
    }

    function recaptchaVerifier(containerID, callback) {
        const recaptchaVerifier = new RecaptchaVerifier(auth, containerID, {
            'size': 'normal', // or 'invisible', depending on your preference
            'callback': (response) => {
                recaptchaVerifier.clear();
                callback(response);
            }
        });

        recaptchaVerifier.render();
    }

    function logOut() {
        return signOut(auth);
    }

    const [currentUser, setCurrentUser] = react.useState(false);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, user => {
            setCurrentUser(user);

            // best place for tracking user
            if (user && (user.metadata.creationTime === user.metadata.lastSignInTime)) {
                // first time user session
                const secondsSinceJoined = ((new Date()).getTime() - (new Date(user.metadata.creationTime)).getTime()) / 1000;
                if (secondsSinceJoined <= 5) {
                    fireSignUpEvent(user.providerData[0].providerId || "unknown");
                }
            }
        });
        return () => {
            unsubscribe();
        };
    }, [setCurrentUser]);

    return (
        <AuthContext.Provider
            value={{
                currentUser,
                setCurrentUser,
                signUp,
                logIn,
                logOut,
                updateUserProfile,
                verifyEmail,
                changeEmail,
                logInUsingGoogle,
                logInUsingFacebook,
                logInUsingGithub,
                logInUsingLinkedIn,
                recaptchaVerifier
            }}>
            {children}
        </AuthContext.Provider>
    );
}