import { FiLogOut } from 'react-icons/fi';
import Classes from './AccountMenu.module.scss';
import LogoutPopup from '../Popup/Presets/LogoutPopup';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useUserSettings } from 'context/userSettingsContext';

export default function AccountMenu(props) {

    // hooks
    const navigate = useNavigate();
    const userSettings = useUserSettings();

    // state
    const [showLogoutAlert, setShowLogoutAlert] = useState(false);

    // handlers
    function editProfile(event) {
        event.stopPropagation();
        navigate('/account/edit');
    }

    function accountSettings(event) {
        event.stopPropagation();
        navigate('/account/settings');
    }

    function editDefaultAvailability(event) {
        event.stopPropagation();
        navigate({
            pathname: "/account/settings/defaultavailability",
            search: createSearchParams({
                'return': "/account"
            }).toString()
        });
    }

    function logOut(event) {
        event.stopPropagation();
        setShowLogoutAlert(true);
    }

    return (
        <>
        <div className={Classes.menuWrapper}>
            <div className={Classes.menuOption} onClick={editProfile}>
                Edit Profile
            </div>
            <div className={Classes.menuOption} onClick={accountSettings}>
                Account Settings
            </div>
            { userSettings.defaultAvailabilityEnabled &&
                <div className={Classes.menuOption} onClick={editDefaultAvailability}>
                    Edit Default Availability
                </div>
            }
            <div className={Classes.menuOption} onClick={logOut}>
                Log Out
                <div className={Classes.menuOptionIcon}>
                    <FiLogOut/>
                </div>
            </div>
        </div>

        {showLogoutAlert && (
            <LogoutPopup
                onCancel={() => setShowLogoutAlert(false)}
                onLogout={() => {
                    navigate('/logout');
                }}
            />
        )}
        </>
    )
}