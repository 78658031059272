import Classes from "./TeamBubble.module.scss";

export default function TeamBubble(props) {
    const { name,role,img } = props
    return (
        <div className={Classes.wrapper}>
            <img className={Classes.image} src={img} alt="" />
            <div className={Classes.name}>{name}</div>
            <div className={Classes.role}>{role}</div>
        </div>
    )
}