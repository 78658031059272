import {Route, Routes, Navigate, useLocation} from "react-router-dom";
import { Fragment, useEffect } from "react";
import { AuthProvider, useAuth } from "./context/authContext";
import { CustomToast } from "./components/Popup/CustomToast/CustomToast";
import PrivateRoute from "./components/Route/PrivateRoute";
import NewMeetingPage from "./pages/NewMeetingPage";
import PublicRoute from "./components/Route/PublicRoute";
import SignUpPage from "./pages/SignUpPage";
import LogInPage from "./pages/LogInPage";
import NotFoundPage from "./pages/NotFoundPage";
import MeetingListPage from "./pages/MeetinglistPage";
import WelcomeHomePage from "./pages/WelcomeHomePage";
import RecipientPage from "./pages/RecipientPage";
import GenericErrorPage from "./pages/GenericErrorPage";
// accounts
import ResetPassword from "./components/Account/ResetPassword";
// import PasswordResetDynamic from "./components/Account/PasswordResetDynamic";
// import ChangeEmail from "./components/Account/ChangeEmail";
// import ChangeName from "./components/Account/ChangeName";
// import ChangePhoneNumber from "./components/Account/ChnagePhoneNumber";
import AccountPage from "./pages/AccountPage";
import AccountEditPage from "./pages/AccountEditPage";
import AccountSettingsPage from "./pages/AccountSettingsPage";
import LogOutPage from "./pages/LogOutPage";
import Dashboard from "./pages/Dashboard";
import Footer from "./components/Footer/Footer";
import MeetingOverview from "./pages/MeetingOverview";
import ChangePassword from "./pages/ChangePassword";
import PrivacyPolicy from "./pages/legal/privacyPolicy";
import DataSubjectAccessRequestForm from "./pages/legal/dataSubjectAccessRequestForm";
import DeletionPolicy from "./pages/legal/deletionPolicy";
import {firePageViewEvent} from "./analytics";
import InvitePage from "./pages/InvitePage";
import PleaseUseDifferentBrowserPage from "pages/PleaseUseDifferentBrowserPage";
import DefaultAvailability from "components/Account/DefaultAvailability";
import { UserSettingsProvider } from "context/userSettingsContext";
import AboutUsPage from "pages/AboutUsPage";

function App() {
    const location = useLocation();

    const allowedDomains = ["overlap-dev-ae015.web.app", "www.myoverlap.com", "dev.myoverlap.com", "localhost"];

    if (!allowedDomains.includes(window.location.hostname)) return window.location.replace("https://www.myoverlap.com");
    if (window.location.hostname === "overlap-dev-ae015.web.app") return window.location.replace("https://dev.myoverlap.com");

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        const pathname = (new RegExp("^/meeting/")).test(location.pathname) ? "/meeting" : location.pathname;
        firePageViewEvent(pathname);
    }, [location]);

    function LandingRedirect() {
        // return a navigate redirect to /home if logged in, else /welcome
        const authContext = useAuth();

        if (authContext.currentUser) {
          return <Navigate to="/home" replace />;
        } else {
          return <Navigate to="/welcome" replace />;
        }
    }

    const RedirectToInvite = () => {

        // Extract the query string from the current location
        const queryString = location.search;
        // Redirect to the /invite path, preserving any query parameters
        return <Navigate to={`/invite${queryString}`} replace/>;
    };

    return (
        <Fragment>
            {/* <main className="main-section flex-grow-1"> */}
            <AuthProvider>
                <UserSettingsProvider>
                    <Routes>
                        <Route element={<PrivateRoute />}>
                            <Route path="/" element={<LandingRedirect/>} />
                            <Route
                                path="/newmeeting"
                                element={<NewMeetingPage />}
                            />
                            <Route
                                path="/meeting/:meetingId"
                                element={<MeetingOverview />}
                            />
                            <Route path="/editmeeting" element={<RecipientPage editMode={true}/>}/>
                            <Route path="/account">
                                <Route index element={<AccountPage />} />
                                <Route path="edit" element={<AccountEditPage/>}/>
                                <Route path="settings">
                                    <Route index element={<AccountSettingsPage/>}/>
                                    <Route path="changepassword" element={<ChangePassword/>}/>
                                    <Route path="defaultavailability" element={<DefaultAvailability/>}/>
                                </Route>
                            </Route>
                            <Route path="/home" element={<MeetingListPage />} />
                            <Route path="/dashboard" element={<Dashboard />} />
                            <Route path="/*" element={<NotFoundPage />} />
                        </Route>
                        <Route element={<PublicRoute />}>
                            <Route path="/welcome" element={<WelcomeHomePage />} />
                            <Route path="/signup" element={<SignUpPage />} />
                            <Route path="/login" element={<LogInPage />} />
                            <Route path="/passreset" element={<ResetPassword />} />
                        </Route>
                        <Route path="/aboutus" element={<AboutUsPage />} />
                        <Route path="/invite" element={<InvitePage />} />
                        <Route path="/t/invite" element={<RedirectToInvite />} />
                        <Route path="/logout" element={<LogOutPage />} />
                        <Route path="/error" element={<GenericErrorPage/>} />
                        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                        <Route path="/deletion-policy" element={<DeletionPolicy />} />
                        <Route path="/data-access-request-form" element={<DataSubjectAccessRequestForm />} />
                        <Route path="/instagram-redirect" element={<PleaseUseDifferentBrowserPage client="Instagram"/>} />
                    </Routes>
                    <Footer />
                </UserSettingsProvider>
            </AuthProvider>
            <CustomToast/>
        </Fragment>
    );
}

export default App;
