import Classes from './PositionedContainer.module.scss';

/**
 * A container that is positioned in a specific location on the page.
 * Location can be set to `north`, `south`, `east`, `west`, `center`, `northwest`, `northeast`, `southwest`, `southeast`.
 */
export default function PositionedContainer({ location, children }) {

    return (
        <div className={`${Classes.fullPageContainer} ${Classes[location]}`}>
            {children}
        </div>
    )
}