import React, { useRef } from 'react';
import Classes from './TitleBubble.module.scss';
import {FiChevronDown, FiUsers, FiChevronUp} from "react-icons/fi";
import PersonItem from './PersonItem';

const TitleBubble = ({meetingInfo, gridInfo, expanded, setExpanded}) => {

    // stop the bubble from expanding when the user is dragging the mouse
    const mouseMoveDistance = useRef(0);

    // when the mouse is pressed down, record the position
    function onMouseDown(event) {
        mouseMoveDistance.current = 0;
    }

    // when the mouse is released, check if the mouse has moved
    function onMouseUp(event) {
        if (mouseMoveDistance.current < 5) {
            setExpanded(!expanded);
        }
    }

    // when the mouse is moved, record the distance
    function onMouseMove(event) {
        mouseMoveDistance.current += Math.sqrt(event.movementX^2 + event.movementY^2);
    }

    // if meetingInfo is not set, then return an error message inside the bubble
    if (!gridInfo) {
        return(
            <div className={Classes.titleBubble} style={{color: 'red'}}>
                meetingInfo prop is missing for this component
            </div>
        )
    }

    // otherwise return the component
    return (
        <div className={Classes.titleBubble} onMouseDown={onMouseDown} onMouseUp={onMouseUp} onMouseMove={onMouseMove}>

            <div className={Classes.topRow}>
                {/* User Count */}
                <div className={Classes.userCount}>
                    <span className={Classes.userCountText}>{gridInfo.members.length}</span>
                    <FiUsers className={Classes.userIcon}/>
                </div>

                {/* Meeting title */}
                <div className={`${Classes.titleText} ${expanded ? Classes.expanded : ''}`}>{meetingInfo.title}</div>

                {/* Expand button */}
                { !expanded &&
                    <div className={Classes.expandButton}><FiChevronDown/></div>
                }
                { expanded &&
                    <div className={Classes.expandButton}><FiChevronUp/></div>
                }
            </div>

            { expanded &&
                <>  
                    { meetingInfo.description && 
                        <div className={Classes.descriptionText}>
                            {meetingInfo.description}
                        </div>
                    }

                    <div className={Classes.peopleList}>
                        {gridInfo.members.map((user, index) => {
                            return (
                                <div className={Classes.person} key={user.uid}>
                                    <PersonItem user={user} leader={user.role === 0}/>
                                </div>
                            )
                        })}
                    </div>
                </>
            }

        </div>
    )
}

export default TitleBubble;