import { useState } from "react";

export default function ProfilePicture({ src, alt, style, className }) {

    const DEFAULT_PROFILE_PIC = 'https://upload.wikimedia.org/wikipedia/commons/2/2c/Default_pfp.svg';

    let [profileUrl, setProfileUrl] = useState(src);

    return (
        <img className={className} src={profileUrl} onError={() => {
            setProfileUrl(DEFAULT_PROFILE_PIC);
        }} alt={alt} style={style}/>)
}