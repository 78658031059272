import React from "react";
import Classes from "./ResourceFooter.module.scss";
import {Link} from "react-router-dom";
import getLogo from "../../utilities/getLogo";

const WelcomeHomePage = () => {
    return (
        <footer className={Classes.footer}>
            <div className={Classes.footerContainer}>
                <div className={Classes.footerLogo}>
                    <img src={getLogo()} alt="Overlap Logo"/>
                </div>

                <div className={Classes.footerCategory}>
                    <h3>Downloads</h3>
                    <ul>
                        <li><a href="https://chromewebstore.google.com/detail/overlap/olemdcaphgcpdbhbbjfgdpfhgommhmmk" target={"_blank"} rel="noopener noreferrer">Chrome Extension</a></li>
                    </ul>
                </div>

                <div className={Classes.footerCategory}>
                    <h3>Connect</h3>
                    <ul>
                        <li><Link to={"/login"} target={"_blank"}>Sign up for free</Link></li>
                        <li><a href="mailto:karthik@myoverlap.com" target={"_blank"} rel="noopener noreferrer">Contact us</a></li>
                    </ul>
                </div>

                <div className={Classes.footerCategory}>
                    <h3>Resources</h3>
                    <ul>
                        <li><a href={"https://blogs.myoverlap.com/blogs"} target={"_blank"} rel="noopener noreferrer">Blogs</a></li>
                        <li><a href={"https://blogs.myoverlap.com/faq"} target={"_blank"} rel="noopener noreferrer">FAQ</a></li>
                        <li><a href={"https://blogs.myoverlap.com/release-notes"} target={"_blank"} rel="noopener noreferrer">Release Notes</a></li>
                        <li><a href={"https://blogs.myoverlap.com/roadmap"} target={"_blank"} rel="noopener noreferrer">Road Map</a></li>
                    </ul>
                </div>

                <div className={Classes.socialMedia}>
                    <a href="https://www.instagram.com/overlap_app/" target="_blank" rel="noreferrer">
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" alt="instagram" aria-label="instagram" aria-hidden="true" dimension="base">
                            <circle cx="16" cy="16" r="15.5" stroke="#000"></circle>
                            <g>
                                <path
                                    d="M16 8c2.174 0 2.445.008 3.298.048.852.04 1.432.174 1.942.372a3.9 3.9 0 011.418.922c.406.4.721.884.922 1.418.198.51.332 1.09.372 1.942.038.853.048 1.124.048 3.298s-.008 2.445-.048 3.298c-.04.852-.174 1.432-.372 1.942a3.907 3.907 0 01-.922 1.418c-.4.406-.884.721-1.418.922-.51.198-1.09.332-1.942.372-.853.038-1.124.048-3.298.048s-2.445-.008-3.298-.048c-.852-.04-1.432-.174-1.942-.372a3.911 3.911 0 01-1.418-.922 3.924 3.924 0 01-.922-1.418c-.198-.51-.332-1.09-.372-1.942C8.01 18.445 8 18.174 8 16s.008-2.445.048-3.298c.04-.852.174-1.432.372-1.942.2-.534.515-1.018.922-1.418.4-.407.884-.721 1.418-.922.51-.198 1.09-.332 1.942-.372C13.555 8.01 13.826 8 16 8zm0 4a4 4 0 100 8 4 4 0 000-8zm5.2-.2a1 1 0 10-2 0 1 1 0 002 0zM16 13.6a2.4 2.4 0 110 4.801 2.4 2.4 0 010-4.801z"
                                    fill="#000"></path>
                            </g>
                            <defs>
                                <clipPath>
                                    <path fill="#fff" transform="translate(4 4)" d="M0 0h24v24H0z"></path>
                                </clipPath>
                            </defs>
                        </svg>
                    </a>
                    <a href="https://www.youtube.com/@OverlapAppSocial" target="_blank" rel="noreferrer">
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" alt="youtube" aria-label="youtube" aria-hidden="true" dimension="base">
                            <circle cx="16" cy="16" r="15.5" stroke="#000"></circle>
                            <path
                                d="M25.877 12.147c0-1.67-1.229-3.013-2.747-3.013A134.439 134.439 0 0016.836 9h-.668c-2.138 0-4.239.037-6.295.134-1.514 0-2.743 1.35-2.743 3.02A54.386 54.386 0 007 16.12a56.73 56.73 0 00.126 3.967c0 1.67 1.229 3.025 2.743 3.025 2.16.1 4.376.145 6.629.141 2.256.007 4.465-.037 6.628-.141 1.518 0 2.747-1.355 2.747-3.025.089-1.325.13-2.646.126-3.971a54.535 54.535 0 00-.122-3.968zM14.683 19.76v-7.293l5.381 3.645-5.381 3.648z"
                                fill="#000"></path>
                        </svg>
                    </a>
                    <a href="https://www.linkedin.com/company/myoverlap/" target="_blank" rel="noreferrer">
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" alt="linkedin" aria-label="linkedin" aria-hidden="true" dimension="base">
                            <circle cx="16" cy="16" r="15.5" stroke="#000"></circle>
                            <path
                                d="M10.552 12.42a1.778 1.778 0 100-3.556 1.778 1.778 0 000 3.556zM13.958 13.734h2.946v1.35s.8-1.6 2.975-1.6c1.94 0 3.548.956 3.548 3.87V23.5h-3.053v-5.4c0-1.72-.918-1.908-1.617-1.908-1.451 0-1.7 1.252-1.7 2.132v5.176h-3.1v-9.765zM9.002 13.734h3.1v9.765h-3.1v-9.765z"
                                fill="#0A0B0C"></path>
                            <path
                                d="M10.552 12.42a1.778 1.778 0 100-3.556 1.778 1.778 0 000 3.556zM13.958 13.734h2.946v1.35s.8-1.6 2.975-1.6c1.94 0 3.548.956 3.548 3.87V23.5h-3.053v-5.4c0-1.72-.918-1.908-1.617-1.908-1.451 0-1.7 1.252-1.7 2.132v5.176h-3.1v-9.765zM9.002 13.734h3.1v9.765h-3.1v-9.765z"
                                fill="#0A0B0C"></path>
                        </svg>
                    </a>
                    <a href="https://twitter.com/OverlapAppOffl" target="_blank" rel="noreferrer">
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" alt="twitter" aria-label="twitter" aria-hidden="true" dimension="base">
                            <circle cx="16" cy="16" r="15.5" stroke="#000"></circle>
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M22.661 13.076c.006.15.008.3.008.45 0 4.544-3.197 9.791-9.043 9.791-1.796 0-3.466-.572-4.873-1.547.249.028.502.048.758.048 1.489 0 2.86-.552 3.948-1.479-1.39-.02-2.565-1.022-2.97-2.384a2.906 2.906 0 001.437-.062c-1.455-.313-2.551-1.703-2.551-3.372v-.041c.429.252.92.409 1.44.429-.853-.62-1.414-1.676-1.414-2.869 0-.626.156-1.22.431-1.73 1.567 2.085 3.91 3.454 6.552 3.597a3.749 3.749 0 01-.082-.783c0-1.901 1.423-3.441 3.179-3.441.914 0 1.74.416 2.319 1.083a5.967 5.967 0 002.02-.83 3.398 3.398 0 01-1.4 1.9 5.96 5.96 0 001.827-.538 6.793 6.793 0 01-1.586 1.778z"
                                  fill="#0A0B0C"></path>
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M22.661 13.076c.006.15.008.3.008.45 0 4.544-3.197 9.791-9.043 9.791-1.796 0-3.466-.572-4.873-1.547.249.028.502.048.758.048 1.489 0 2.86-.552 3.948-1.479-1.39-.02-2.565-1.022-2.97-2.384a2.906 2.906 0 001.437-.062c-1.455-.313-2.551-1.703-2.551-3.372v-.041c.429.252.92.409 1.44.429-.853-.62-1.414-1.676-1.414-2.869 0-.626.156-1.22.431-1.73 1.567 2.085 3.91 3.454 6.552 3.597a3.749 3.749 0 01-.082-.783c0-1.901 1.423-3.441 3.179-3.441.914 0 1.74.416 2.319 1.083a5.967 5.967 0 002.02-.83 3.398 3.398 0 01-1.4 1.9 5.96 5.96 0 001.827-.538 6.793 6.793 0 01-1.586 1.778z"
                                  fill="#0A0B0C"></path>
                        </svg>
                    </a>
                    <a href="https://www.facebook.com/profile.php?id=100089799133809" target="_blank" rel="noreferrer">
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" alt="facebook" aria-label="facebook" aria-hidden="true" dimension="base">
                            <circle cx="16" cy="16" r="15.5" stroke="#000"></circle>
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M7 16c0 4.45 3.25 8.15 7.5 8.9l.05-.04-.05-.01V18.5h-2.25V16h2.25v-2c0-2.25 1.45-3.5 3.5-3.5.65 0 1.35.1 2 .2V13h-1.15c-1.1 0-1.35.55-1.35 1.25V16h2.4l-.4 2.5h-2v6.35l-.05.01.05.04c4.25-.75 7.5-4.45 7.5-8.9 0-4.95-4.05-9-9-9s-9 4.05-9 9z"
                                  fill="#0A0B0C"></path>
                        </svg>
                    </a>
                </div>
            </div>
            <div className={Classes.footerContainer}>
                <div className={Classes.legalNotice}>
                    <Link to={"/privacy-policy"} target={"_blank"}>Privacy Policy</Link>
                    {/*<Link to={"/deletion-policy"} target={"_blank"}>Deletion Policy</Link>*/}
                    <Link to={"/data-access-request-form"} target={"_blank"}>Data Access Request</Link>
                    <a href={"https://overlap.statuspage.io/"} target={"_blank"} rel="noreferrer">Status</a>
                </div>
            </div>
        </footer>
    );
};

export default WelcomeHomePage;
