import React, {useState, useEffect} from "react";
import Classes from "./MeetingTime.module.scss";
import Calender from "./Calender/Calender";
import CalendarJs from "calendar-js";
import {useMeetingContext} from "../../../pages/NewMeetingPage";

const MeetingTime = (props) => {
    const context = useMeetingContext();

    // set month list
    const [monthList, setMonthList] = useState([]);

    // generate calender month
    useEffect(() => {
        const date = new Date();
        // set current month details
        let thisYear = date.getFullYear();
        let thisMonth = date.getMonth();

        const totalMonthsToDisplay = 12;

        setMonthList(() => {
            const monthRecord = [];

            while (monthRecord.length < totalMonthsToDisplay) {
                monthRecord.push(CalendarJs().of(thisYear, thisMonth++));

                if (thisMonth === 12) {
                    thisMonth = 0;
                    ++thisYear;
                }
            }

            return monthRecord;
        });
    }, []);

    // day selection handler
    const daySelectHandler = (selectedDate) => {
        const dates = context.state.selectedDates;

        // check if selected date is already in the list
        const oldIndex = dates.indexOf(selectedDate);
        // if not in the list, add it
        if (oldIndex === -1) {
            dates.push(selectedDate);
        }
        // if in the list, remove it
        else {
            dates.splice(oldIndex, 1);
        }
        // sort the dates
        dates.sort((d1, d2) => (new Date(d1)) - (new Date(d2)));
        context.dispatch({type: "DAYS_UPDATE", payload: {
            dates
        }})
        // valueHandler({...stageContext.values, dates});
    };

    return (
        <div className={`${Classes.contentCard} ${context.state.revealErrors ? Classes.invalid : ''}`}>
            <Calender
                monthList={monthList}
                // start={start}
                // end={end}
                selectedDates={context.state.selectedDates}
                daySelectHandler={daySelectHandler}
                error={context.state.revealErrors ? "Please select at least 1 and no more than 7 days." : null}
            />
        </div>
    )

    // return (
    //     <div className={`${Classes.ContentCard}`}>
    //         <div className={`${Classes.SecTitleWrap}`}>
    //             <h3 className={`${Classes.SecTitle}`}>Date Range</h3>
    //             <p className={`${Classes.SecText} mb-0 `}>
    //                 Select dates and times that you’d like for your group to
    //                 pick from
    //             </p>
    //         </div>
    //         <Row className={`${Classes.CalenderTimeRow} g-lg-0`}>
    //             <Col xs={12} lg={6} className={Classes.CalnderCol}>
    //                 <h1 className="d-none d-lg-block text-center text-lg-20 text-capitalize text-dark-gray mb-lg-16">
    //                     date range
    //                 </h1>
    //                 <Calender
    //                     monthList={monthList}
    //                     // start={start}
    //                     // end={end}
    //                     selectedDates={stageContext.values.dates}
    //                     daySelectHandler={daySelectHandler}
    //                     error={errorObj.dates}
    //                 />
    //             </Col>
    //             <Col xs={12} lg={6} className={Classes.TimeRangeCol}>
    //                 <TimeRange
    //                     error={errorObj.timeRange}
    //                     timeRange={stageContext.values.timeRange}
    //                     onTimeChange={(timeRange) => {
    //                         valueHandler({...stageContext.values, timeRange});
    //                     }}
    //                 />
    //             </Col>
    //         </Row>
    //     </div>
    // );
};

export default MeetingTime;
