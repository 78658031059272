import Classes from 'pages/scss/PleaseUseDifferentBrowserPage.module.scss';
import getLogo from 'utilities/getLogo';
import overlapExample from "assets/images/overlapExample.png";

export default function PleaseUseDifferentBrowserPage({ client }) {
    return (
            <div className={Classes.pageContainer}>

                <div className={Classes.titleText}>
                    <span>Welcome to</span>
                    <img src={getLogo()} alt="Overlap logo"/>
                </div>

                <div className={Classes.textContainer}>
                    <div className={`${Classes.pageText} ${Classes.message}`}><b>Please go to <u>myoverlap.com</u> in your favorite browser to get started.</b></div>
                    <div className={`${Classes.pageText} ${Classes.message}`}>We cannot guarantee that all features will work on {client}'s browser.</div>
                </div>

                <div className={Classes.imageContainer}>
                    <img src={overlapExample} alt="Overlap logo"/>
                </div>
            </div>
    );
}