import React, {useEffect, useState} from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import getLogo from "../utilities/getLogo";
import Classes from "./scss/LoginSignUp.module.scss";
import "./scss/FormsPage.scss";
import { Link } from "react-router-dom";
import { useAuth } from "../context/authContext";
// import PortalMask from "../HOC/PortalMask";
import Header from "../components/Header/Header";
import {FiChevronRight, FiInfo} from "react-icons/fi";
import useError from "../utilities/error";
import { useVerify, verifyAll } from "../utilities/verification";
import FormAlert from "../components/Ui/FormAlert/FormAlert";
import { Helmet } from "react-helmet";

const SignUpPage = () => {
    const authContext = useAuth();
    const [searchParams] = useSearchParams();
    const error = useError();
    const [alertMessage, setAlertMessage] = useState(null);
    const [recaptchaSuccess, setRecaptchaSuccess] = useState(false);

    // const [attemptedSignup, setAttemptedSignup] = useState(false);

    const from = searchParams.get("from") ?? 'default';
    const redirectUrl = searchParams.get('redirect') ?? '/home';

    // create verification objects for form elements
    const [firstNameRef, firstNameField] = useVerify();
    const [lastNameRef, lastNameField] = useVerify();
    const [emailRef, emailField] = useVerify();
    const [passwordRef, passwordField] = useVerify();
    const [passwordConfirmRef, passwordConfirmField] = useVerify();

    // set verification parameters
    firstNameField.notEmpty("First Name is required.");
    lastNameField.notEmpty("Last Name is required.");
    emailField.notEmpty("Email is required.").validEmail("Invalid email address.");
    passwordField.notEmpty("Password is required.").minimumLength(6, "Password must be at least 6 characters.");
    passwordConfirmField.notEmpty("Password confirmation is required.").matchesField(passwordRef, "Passwords do not match.");

    const fields = [firstNameField, lastNameField, emailField, passwordField, passwordConfirmField];

    async function submitHandler(e) {
        e.preventDefault();

        if (!recaptchaSuccess) return;

        // verify all fields
        // eslint-disable-next-line no-unused-vars
        let [verificationSucceeded, errorMessages] = await verifyAll(fields);

        if (!verificationSucceeded) {
            // show alert message
            setAlertMessage(errorMessages[0]);
            return;
        }

        // hide alert message
        setAlertMessage(null);

        try {
            await authContext.signUp(emailField.value, passwordField.value);
            await authContext.updateUserProfile({
            displayName: `${firstNameField.value} ${lastNameField.value}`.trim(),
            });
        } catch (e) {
            if (e.code === 'auth/email-already-in-use') {
                emailField.setInvalidState('Email is already in use.');
                return;
            }

            console.error(e);
            error('SCALPEL', 500, 'An error occurred while attempting to sign up a new user.');
        }
    }

    useEffect(() => {
        authContext.recaptchaVerifier('recaptcha-container', (_response) => {
            setRecaptchaSuccess(true);
        });
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Helmet>
                <title>Sign Up for Overlap</title>
            </Helmet>
            <Header/>
            <main>
                <div className={Classes.formWrap}>
                    <div>
                        <div className={Classes.logoWrap}>
                            Welcome to
                            <img className={Classes.Logo} src={getLogo()} alt="Overlap Logo"/>
                        </div>
                    </div>
                    <div className={Classes.formContainer}>
                        <Form className={Classes.loginForm} onSubmit={submitHandler}>
                            <Form.Group>
                                <Row className=" gx-3 mb-3 ">
                                    <Col>
                                        <Form.Control
                                            className={`round-element bg-light-gray-btn border-0 ${Classes.formElement}`}
                                            placeholder="First Name"
                                            type="text"
                                            name="firstName"
                                            ref={firstNameRef}
                                            title={firstNameField.errorMessage}
                                        />
                                        {/* {firstNameField.invalid ? (
                                            <p className=" alert">{firstNameField.errorMessage}</p>
                                        ) : null} */}
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            className={`round-element bg-light-gray-btn border-0 ${Classes.formElement}`}
                                            placeholder="Last Name"
                                            type="text"
                                            name="lastName"
                                            ref={lastNameRef}
                                            title={lastNameField.errorMessage}
                                        />
                                        {/* {lastNameField.invalid ? (
                                            <p className=" alert">{lastNameField.errorMessage}</p>
                                        ) : null} */}
                                    </Col>
                                </Row>
                                <Form.Group className=" mb-3 ">
                                    <Form.Control
                                        className={`round-element bg-light-gray-btn border-0 ${Classes.formElement}`}
                                        name="email"
                                        type="email"
                                        placeholder="Email"
                                        autoComplete="email"
                                        ref={emailRef}
                                        title={emailField.errorMessage}
                                    />
                                    {/* {emailField.invalid ? (
                                        <p className=" alert">{emailField.errorMessage}</p>
                                    ) : null} */}
                                </Form.Group>
                                <Form.Group className=" mb-3 ">
                                    <Form.Control
                                        className={`round-element bg-light-gray-btn border-0 ${Classes.formElement}`}
                                        type="password"
                                        autoComplete="new-password"
                                        placeholder="Password"
                                        name="password1"
                                        ref={passwordRef}
                                        title={passwordField.errorMessage}
                                    />
                                    {/* {passwordField.invalid ? (
                                        <p className=" alert">{passwordField.errorMessage}</p>
                                    ) : null} */}
                                </Form.Group>
                                <Form.Group className=" mb-3 ">
                                    <Form.Control
                                        className={`round-element bg-light-gray-btn border-0 ${Classes.formElement}`}
                                        type="password"
                                        autoComplete="new-password"
                                        placeholder="Re-Enter Password"
                                        name="password2"
                                        ref={passwordConfirmRef}
                                        title={passwordConfirmField.errorMessage}
                                    />
                                    {/* {passwordConfirmField.invalid ? (
                                        <p className=" alert">{passwordConfirmField.errorMessage}</p>
                                    ) : null} */}
                                </Form.Group>
                            </Form.Group>
                            { alertMessage !== null &&
                                <Form.Group>
                                    <FormAlert>
                                        {alertMessage}
                                    </FormAlert>
                                </Form.Group>
                            }
                            <Form.Group className=" submit-wrap d-flex justify-content-end">
                                {recaptchaSuccess && <button type="submit" className={`${Classes.loginButton}`}>
                                    Sign Up{" "}
                                    <FiChevronRight fontSize={20}/>
                                </button>}
                            </Form.Group>
                        </Form>
                        {!recaptchaSuccess && <div id="recaptcha-container"></div> }
                    </div>
                </div>
                {/* sign up form portal  */}
                <div className={Classes.signUpTextContainer}>
                    Already have an account?
                    <Link to={`/login?from=${from}&redirect=${encodeURIComponent(redirectUrl)}`} className={Classes.signUpButton}>Log In</Link>
                </div>

                { from === "recipient" &&
                    <div className={Classes.popupContainer}>
                        <div className={Classes.popup}>
                            <FiInfo fontSize={20}/>
                            <div className={Classes.popupText}>
                                Please Sign In or Sign Up to Continue
                            </div>
                        </div>
                    </div>
                }
            </main>
        </>
    );
};

export default SignUpPage;

// const firstnameRef = useRef(),
    //     lastnameRef = useRef(),
    //     emailRef = useRef(),
    //     passwordRef = useRef(),
    //     passwordConfirmRef = useRef();

    // async function submitHandler(formData) {
    //     setAttemptedSignup(true);
    //     console.log(formData);
    //     // e.preventDefault();
    //     // const firstname = firstnameRef.current.value,
    //     //     lastname = lastnameRef.current.value,
    //     //     email = emailRef.current.value,
    //     //     password = passwordRef.current.value,
    //     //     passwordConfirm = passwordConfirmRef.current.value;

    //     // if (!firstname) return alert("first name cannot be empty!");
    //     // if (!email) return alert("email address cannot be empty!");
    //     // if (!password) return alert("password cannot be empty!");

    //     // if (password !== passwordConfirm)
    //         // return alert("passwords do not match!");
    //     // TODO : punch loader
    //     try {
    //         // TODO: Use the user APIs from backend instead of firebase because here is a possible race condition that could skip "updateUserProfile" operation.
    //         //       Javascript's event loop architecture preventing this race condition so, I keeping this for future task.
    //         // await authContext.signUp(email, password);
    //         // await authContext.updateUserProfile({
    //         // displayName: `${firstname} ${lastname}`.trim(),
    //         // });
    //         // update with formik data
    //         await authContext.signUp(formData.email, formData.password1);
    //         await authContext.updateUserProfile({
    //         displayName: `${formData.firstName} ${formData.lastname}`.trim(),
    //         });
    //     } catch (e) {
    //         console.error(e);
    //         error('SCALPEL', 500, 'An error occurred while attempting to sign up a new user.');
    //     }
    //     // TODO : release loader
    // }