import {useState} from "react";
import Classes from "./ResetPassword.module.scss";
import {constants} from "../../firebase";
import FormAlert from "../Ui/FormAlert/FormAlert";
import {Link} from "react-router-dom";
import Header from "../Header/Header";
import useError from "../../utilities/error";
import { FiCheckCircle } from "react-icons/fi";
import { useVerify, verifyAll, getErrorMessageList } from '../../utilities/verification';
import { Helmet } from "react-helmet";

const ResetPassword = () => {

    // define utility functions
    const error = useError();
    const [errorMessages, setErrorMessages] = useState([]);

    const [emailRef, emailField] = useVerify();

    const [emailSent, setEmailSent] = useState(false)

    emailField.notEmpty("Please enter a valid email address").validEmail("Email is invalid.");

    function updateErrorMessageList() {
        setErrorMessages(getErrorMessageList([emailField]));
    }

    async function resetPassword(e) {
        e.preventDefault();

        let [verificationSuccess, ] = await verifyAll([emailField]);
        if (!verificationSuccess) {
            updateErrorMessageList();
            return;
        }

        const email = emailField.value
        if (!email) return alert("email cannot be empty!");

        //Set email sent to true which changes view to checkmark. This is done even before request because we don't really want people to know if the email they typed in exists or not
        setEmailSent(true);
        fetch(`${constants.functionsBaseUrl}/users/password_reset`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({email: email}),
        }).then(res => res.json()).then(res => {
            // setEmailSent(true);
            if (res.code === 200) {
                // console.log("SUCCESS", res)
                return;
            }
            error('POMEGRANATE', res.code, "Password reset returned an invalid response.");
        }).catch(err => {
            // setEmailSent(true);
            console.error(err);
            error('APPLE', 500, "An error occurred when resetting passwword.");
        });
    }

    return (
        <>
            <Helmet>
                <title>Reset Password</title>
            </Helmet>
            <Header/>
            <main>
                <div className={Classes.Wrap}>
                    { !emailSent ?
                        <>
                            <h1 className={Classes.PageTitle}>Reset Password</h1>
                            <p className={Classes.PageDescription}>Enter your email and we’ll send instructions on how to reset your password</p>
                            {/* form  */}
                            <form>
                                <div className={Classes.FormWrap}>
                                    <label>Recovery Email</label>
                                    <input ref={emailRef} type="email" placeholder="email123@email.com"/>
                                </div>
                                { errorMessages.length > 0 &&
                                    <div style={{marginTop: '16px'}}>
                                        <FormAlert>
                                            {errorMessages[0]}
                                        </FormAlert>
                                    </div>
                                }
                                <div className={Classes.SubmitWrap}>
                                    <Link>
                                        <button onClick={resetPassword} type="submit" className={`${Classes.SendEmailButton}`}>
                                            Send Email
                                        </button>
                                    </Link>
                                    <Link to={"/login"} className={Classes.Return}>
                                        Return to Login
                                    </Link>
                                </div>
                            </form>
                        </>
                    : 
                        <>
                                <div className={Classes.PageTitle}>Recovery Email Sent</div>
                                <div className={Classes.PageDescription}>Follow the instructions on the email to reset your password</div>
                                {/* form  */}
                                <FiCheckCircle className={Classes.checkIcon}/>
                                <div className={Classes.SubmitWrap}>
                                    <Link to={"/login"} className={Classes.Return}>
                                        Return to Login
                                    </Link>
                                </div>
                        </>
                    }
                </div>
            </main>
        </>
    );
};

export default ResetPassword;
