import React from "react";
import Styles from "./GoogleBtn.module.scss";
import LongButton from "../../Button/LongButton";
import {FcGoogle} from "react-icons/fc";
import classNames from "classnames";

const GoogleBtn = (props) => {
    const { click, className, ...rest } = props;
    return (
        <LongButton 
            {...rest} 
            click={click}
            className={classNames("d-flex align-items-center justify-content-center bg-white",
                        Styles.googleBtn,
                        className
                    )}
        >
            {" "}
            <FcGoogle className=" me-2" size={20}/> Sign in with Google
        </LongButton>
    );
};

export default GoogleBtn;
