import Header from "../components/Header/Header";
import Classes from './scss/NotFoundPage.module.scss';
import BubbleBackground from '../components/Ui/BubbleBackground/BubbleBackground';
import { Link } from "react-router-dom";

const InvalidMeetingPage = () => {
    return (
        <>
            <Header/>
            <BubbleBackground/>
            <main className={Classes.customMain}>
                <div className={Classes.pageWrapper}>
                    <div className={Classes.contentWrapper}>
                        <div className={Classes.title}>Oops!</div>
                        <div className={Classes.message}>That meeting no longer exists. Try making a new meeting from the home page.</div>
                        <Link to="/">
                            <div className={Classes.homeButton}>
                                Go Home
                            </div>
                        </Link>
                    </div>
                </div>
            </main>
        </>
    )
}

export default InvalidMeetingPage