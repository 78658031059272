import Classes from './UserInfo.module.scss';
import { useAuth } from "../../context/authContext";
import { FiChevronLeft, FiEdit2, FiX } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { useEffect, useRef, useState } from 'react';
import { constants } from '../../firebase';
import { useOutsideClick } from '../../utilities/clickHandling';
import { selectText } from '../../utilities/textTools';

/**
 * Contains the profile picture and user name of the current user.
 * Part of the account page.
 * Includes an edit mode.
 * 
 * Props:
 * - editMode: displays the edit icons on the name and profile image
 * - showBackButton: displays a back button in the top left corner
 * - backUrl: the url to go back to when the back button is clicked
 */
export default function UserInfo(props) {

    const DEFAULT_PROFILE_PIC = 'https://upload.wikimedia.org/wikipedia/commons/2/2c/Default_pfp.svg';

    // get props
    const editMode = props.editMode ?? false;
    const showBackButton = props.showBackButton ?? false;
    const backUrl = props.backUrl ?? "/account";

    // hooks
    const authContext = useAuth();
    const currentUser = authContext.currentUser;
    const navigate = useNavigate();
    const displayNameRef = useRef();

    // state
    const [isEditingName, setIsEditingName] = useState(false);
    const [profileUrl, setProfileUrl] = useState(currentUser.photoURL ?? DEFAULT_PROFILE_PIC);

    // handlers
    function backButtonClicked(event) {
        event.stopPropagation();
        if (!showBackButton) return;
        navigate(backUrl);
    }

    function handleProfilePictureClick(event) {
        event.stopPropagation();
        if (!editMode) return;
        toast.dismiss();
        toast.error("Sorry, this feature is still in progress.", {
            duration: 2500,
        });
    }

    function handleNameChange(event) {
        event.stopPropagation();
        if (!editMode) return;
        setIsEditingName(true);
    }

    function handleNameChangeClosed(event) {

        // update name in database
        submit();
        setIsEditingName(false);
    }

    useEffect(() => {
        if (isEditingName) {
            displayNameRef.current.innerHTML = currentUser.displayName;
            selectText(displayNameRef);
        }
    }, [isEditingName, displayNameRef, currentUser.displayName])

    useOutsideClick(displayNameRef, () => {
        handleNameChangeClosed();
    })

    async function submit() {
        if (!isEditingName) return;
        // remove all html tags from the input
        displayNameRef.current.innerHTML = displayNameRef.current.innerHTML.replace(/(<([^>]+)>)/gi, "");
        if (displayNameRef.current.innerHTML === currentUser.displayName) return;
        let valid = (displayNameRef.current.innerHTML !== '');
        if (!valid) {
            toast.dismiss();
            toast.error('New name is invalid.');
            return;
        };

        let response = await fetch(`${constants.functionsBaseUrl}/users/self/name`, {
            'method': 'PUT',
            headers: {
                'Authorization': `Bearer ${await authContext.currentUser.getIdToken()}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "displayName": displayNameRef.current.innerHTML
            })
        })

        if (!response.ok) {
            toast.error('Failed to update name.');
            
        } else {
            toast.success('Success!');
            
            window.location.replace('/account');
        }
        
    }

    useEffect(() => {
        setProfileUrl(currentUser.photoURL ?? DEFAULT_PROFILE_PIC);
    }, [currentUser.photoURL]);

    // render
    return (
        <>
        <div className={Classes.topWrap}>

            {showBackButton &&
                <div className={Classes.backButton} onClick={backButtonClicked}>
                    <FiChevronLeft/>
                </div>
            }

            <div className={Classes.profileImgContainer} onClick={handleProfilePictureClick}>
                { profileUrl && 
                    <img src={profileUrl} alt="Profile" onError={() => {
                        setProfileUrl(DEFAULT_PROFILE_PIC);
                    }}/>
                }
                {/* {editMode &&
                    <div className={Classes.editButtonContainer}>
                        <FiEdit2 className={Classes.editButton}/>
                    </div>
                } */}
            </div>
            <div className={`${Classes.userName} ${editMode ? Classes.editMode : ''}`} onClick={handleNameChange}>
                <div
                    onBlur={handleNameChangeClosed}
                    ref={displayNameRef}
                    autoFocus={true}
                    className={isEditingName ? Classes.userNameChangeInput : `${Classes.hidden} ${Classes.userNameChangeInput}`}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            e.preventDefault();
                            handleNameChangeClosed(e);
                        }
                    }}
                    contentEditable={isEditingName}
                >{currentUser.displayName}</div>
                {!isEditingName && 
                    currentUser.displayName
                }
                { editMode && !isEditingName && <FiEdit2/> }
                { editMode && isEditingName && <FiX
                    onClick={(e) => {
                        e.stopPropagation();
                        handleNameChangeClosed(e);
                    }}
                /> }
            </div>
        </div>
        </>
    )
}