import React from 'react';
import Classes from './PersonItem.module.scss';
import ProfilePicture from 'components/Utilities/ProfilePicture';

const PersonItem = (props) => {
    
    const available = props.available ?? true;
    return (
        <div className={`${Classes.personContainer} ${available ? '' : Classes.unavailable}`}>
            <ProfilePicture className={Classes.profilePicture} src={props.user.profileUrl} alt="profile icon" />
            <span className={Classes.nameContainer}>
                <span className={Classes.personName}>{props.user.displayName}</span>
                {props.leader && 
                    <span className={Classes.leaderTag}>Leader</span>
                }
            </span>
        </div>
    )
};

export default PersonItem;