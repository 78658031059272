import Classes from "./TextBox.module.scss"

export default function TextBox(props) {
    const { title,children } = props;
    return (
        <div className={Classes.boxContainer}>
            <div className={Classes.title}>{title}</div>
            <div className={Classes.childrenContainer}>
                {children}
            </div>
        </div>
    );
}