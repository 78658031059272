import React from 'react';
import Classes from './DropDownOption.module.scss';

const DropDownOption = (props) => {
    /**
     * DropDownOption should be used inside DropDown tag.
     * 
     * It has the following required props:
     * - text: the text to be displayed for the option [string]
     * - icon: the icon associated with the option [jsx element]
     * 
     * It has the following optional props:
     * - enabled: if false, the option will be disabled [boolean]
     * - onClick: function that will be called when the option is clicked. [function]
     * - hidden: if true, the option will not be displayed [boolean]
     * - className: passthrough for class name
     */

    const text = props.text;
    const icon = props.icon;

    const enabled = props.enabled ?? true;
    const hidden = props.hidden ?? false;

    // if one of the required props is not provided, return an error
    if (!text || !icon) {
        return (
            <div className={Classes.errorContainer}>
                MISSING REQUIRED PROPS
            </div>
        )
    }

    // return nothing if not visible
    if (hidden) {
        return <></>
    }

    function onClickHandler(event) {
        if (enabled) {
            props.onClick(event);
        }
    }

    return (
        <div className={`${Classes.optionContainer} ${enabled ? '' : Classes.disabled} ${props.className}`} onClick={onClickHandler} style={props.style}>
            <div className={Classes.text}>{props.text}</div>
            <div className={Classes.icon}>
                {icon}
            </div>
        </div>
    );
}

export default DropDownOption;