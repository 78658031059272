import { useEffect } from "react";

/**
 * This custom hook will execute the callback function when it detects a click on the base of the document that was not stopped from propagating.
 * Use it to detect clicks outside of a page component.
 * @param {Function} callback 
 */
export function useUnhandledClick(callback) {

    useEffect(() => {
        // this will execute when the component mounts
        function onClick(_) {
            callback();
        }

        document.addEventListener("click", onClick)

        // this will be executed when the component is unmounted
        return () => {
            document.removeEventListener("click", onClick)
        }
    }, [callback])
}

/**
 * This custom hook will execute the callback function when it detects a click outside of the referenced element that was not stopped from propagating.
 * Use it to detect clicks outside of a specific component.
 * @param {React.RefObject} ref
 * @param {Function} callback 
 */
export function useOutsideClick(ref, callback) {

    useEffect(() => {
        // this will execute when the component mounts
        function onClick(event) {
            // execute the callback if the click was outside of the referenced element
            if (ref.current && !ref.current.contains(event.target)) {
                callback();
            }
        }
        
        window.addEventListener("click", onClick);

        // this will be executed when the component is unmounted
        return () => {
            window.removeEventListener("click", onClick);
        }
    }, [ref, callback])
}

/**
 * This custom hook will execute the callback function when it detects a pointerUp outside of the referenced element that was not stopped from propagating.
 * Use it to detect pointerUp outside of a specific component.
 * @param {*} ref 
 * @param {*} callback 
 */
export function useOutsidePointerUp(ref, callback) {
    useEffect(() => {
        // this will execute when the component mounts
        function onPointerUp(event) {
            // execute the callback if the click was outside of the referenced element
            if (ref.current && !ref.current.contains(event.target)) {
                callback();
            }
        }

        window.addEventListener("pointerUp", onPointerUp);

        // this will be executed when the component is unmounted
        return () => {
            window.removeEventListener("pointerUp", onPointerUp);
        }
    })
}