import firebase from "firebase/compat/app";
import {getAuth} from "firebase/auth";
import {getToken, getMessaging, onMessage} from "firebase/messaging";
import {isNotificationSupported, requestNotificationPermission} from "./utilities/notification";
import {isLocalhost} from "./utilities/environment";
// import {initializeAppCheck, ReCaptchaV3Provider} from  "firebase/app-check";

const app = firebase.initializeApp({
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID
});

if (isLocalhost) window.self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;

// initializeAppCheck(app, {
//     provider: new ReCaptchaV3Provider(
//         "6Ldxlx0pAAAAANckepRmshYHkYj_zclrVowFgmI0"
//     ),
//     isTokenAutoRefreshEnabled: true
// });

export const auth = getAuth(app);
export const constants = {
    functionsBaseUrl: process.env.REACT_APP_FUNCTIONS_BASE_URL
};

// push notification registration
export const saveMessagingDeviceToken = async (authToken) => {
    if (!(await isNotificationSupported()) || !(await requestNotificationPermission())) return;

    const msgApp = getMessaging(app);

    const fcmToken = await getToken(msgApp, {vapidKey: process.env.REACT_APP_VAPID_KEY});

    onMessage(msgApp, (message) => {
        new Notification(message.data.title, { body: message.data.body, icon: "/favicon.ico" });
    });

    if (fcmToken) {
        fetch(`${constants.functionsBaseUrl}/users/self/messaging_token`, {
            method: "PUT",
            headers: {
                Authorization: `Bearer ${authToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({token: fcmToken}),
        });
    }
}

export default app;