import { useRef, useMemo, useState } from 'react';

class VerificationObject {
    #params;
    #fieldRef;
    #errorMessage;
    #setErrorMessage;
    #errorMessageRef;
    #invalid;
    #setInvalid;
    #invalidClass;

    constructor(fieldRef, errorMessage, setErrorMessage, errorMessageRef, invalid, setInvalid, invalidClass) {
        this.#params = {}; // contains information about what types of verification to do
        this.#fieldRef = fieldRef;
        this.#errorMessage = errorMessage;
        this.#setErrorMessage = setErrorMessage;
        this.#errorMessageRef = errorMessageRef;
        this.#invalid = invalid;
        this.#setInvalid = setInvalid;
        this.#invalidClass = invalidClass;
    }

    /**
     * The value of the form element.
     */
    get value() {
        return this.#fieldRef.current.value;
    }

    /**
     * The error message state associated with the current form element and the state of the form element. Assign this to rendered elements.
     * @type {string}
     */
    get errorMessage() {
        return this.#errorMessage;
    }
    set errorMessage(message) {
        this.#errorMessageRef.current = message;
        this.#setErrorMessage(message);
    }

    /**
     * The error message reference associated with the current form element. Use this to get the current error message directly, regardless of rendering state.
     * @type {string} The error message.
     * @readonly
     */
    get errorMessageReferenced() {
        return this.#errorMessageRef.current;
    }

    /**
     * Whether the field is currently invalid. It is not recommended to set this value directly.
     * @type {boolean}
     */
    get invalid() {
        return this.#invalid;
    }
    set invalid(val) {
        this.#setInvalid(val);
    }

    /**
     * Sets the field to a valid state.
     */
    setValidState() {
        this.#fieldRef.current.classList.remove(this.#invalidClass ?? "invalid");
        this.errorMessage = null;
        this.invalid = false;
    }

    /**
     * Sets the field to an invalid state.
     * @param {string} message The error message to display.
     */
    setInvalidState(message) {
        this.#fieldRef.current.classList.add(this.#invalidClass ?? "invalid");
        this.errorMessage = message;
        this.invalid = true;
    }

    /**
     * The field may not be empty.
     * @param {string} message The message to display if the field is empty.
     * @returns {VerificationObject} The VerificationObject instance.
     */
    notEmpty(message = "This field cannot be empty!") {
        this.#params.notEmpty = {
            "message": message
        }
        return this;
    }

    /**
     * The field must be a valid email address.
     * @param {string} message The message to display if the email is invalid.
     * @returns {VerificationObject} The VerificationObject instance.
     */
    validEmail(message = "Please enter a valid email address!") {
        this.#params.validEmail = {
            "message": message
        }
        return this;
    }

    validPhone(message = "Please enter a valid phone number!") {
        this.#params.validPhone = {
            "message": message
        }
        return this;
    }

    /**
     * The field must match the value of another field.
     * @param {React.MutableRefObject} fieldRef The reference to the other field.
     * @param {string} message The message to display if the fields do not match.
     */
    matchesField(fieldRef, message = "The fields do not match!") {
        this.#params.matchesField = {
            "fieldRef": fieldRef,
            "message": message
        }
        return this;
    }

    /**
     * The field must have a minimum length.
     * @param {number} length The minimum length.
     */
    minimumLength(length, message = `The field must be at least ${length} characters long.`) {
        this.#params.minimumLength = {
            "length": length,
            "message": message
        }
        return this;
    }
    
    /**
     * Takes a callback function. Verification will fail if the callback returns false.
     * @param {function} callbackFunction The function to run. Can return a promise. Will be passed the value of the field when run.
     * @param {*} message 
     */
    async callbackIsTrue(callbackFunction, message = "The callback function failed.") {
        this.#params.callbackIsTrue = {
            "function": callbackFunction,
            "message": message
        }
        return this;
    }

    /**
     * Verifies the field based on the rules set. Will apply invalid if verifiation fails, and will remove the class if verification succeeds. Note that this function is not chainable.
     * @returns {Promise<boolean>} True if the verification succeeds and false if it fails.
     */
    async verify() {
        let field = this.#fieldRef.current;

        // if the field reference is null, then the field is not mounted
        // warn the user and return true so that the form can be submitted
        if (field.current === null) {
            console.warn("The field reference for verification is null. Did you forget to apply the ref to the field?");
            return true;
        }

        // notEmpty
        if (this.#params.notEmpty) {
            if (field.value === "") {
                this.setInvalidState(this.#params.notEmpty.message);
                return false;
            }
        }

        // validEmail
        if (this.#params.validEmail) {
            let emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(field.value) && field.value !== "") {
                this.setInvalidState(this.#params.validEmail.message)
                return false;
            }
        }

        // validPhone
        if (this.#params.validPhone) {
            let phoneRegex = /^([+][0-9]{1,3}(-[0-9]{1,3})?)?[-\s.]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;
            if (!phoneRegex.test(field.value) && field.value !== "") {
                this.setInvalidState(this.#params.validPhone.message);
                return false;
            }
        }

        // matchesField
        if (this.#params.matchesField) {
            let otherField = this.#params.matchesField.fieldRef.current;
            if (field.value !== otherField.value) {
                this.setInvalidState(this.#params.matchesField.message);
                return false;
            }
        }

        // minimumLength
        if (this.#params.minimumLength) {
            if (field.value.length < this.#params.minimumLength.length) {
                this.setInvalidState(this.#params.minimumLength.message);
                return false;
            }
        }

        // callbackIsTrue
        if (this.#params.callbackIsTrue) {
            let result = await this.#params.callbackIsTrue.function(this.#fieldRef.current.value);
            if (!result) {
                this.setInvalidState(this.#params.callbackIsTrue.message);
                return false;
            }
        }

        // all checks passed, reset to valid and return true
        this.setValidState();
        return true;
    }
}

/**
 * A hook that makes it much easier to verify a field. The hook returns a list. Use the hook once per field. The first object is a ref that should be applied to the field. The second object is a VerificationObject instance. The VerificationObject instance has methods that can be chained to add rules to the field. The verify method of the VerificationObject instance should be called when the form is submitted. If the verify method returns false, then the field is invalid and the error message can be accessed from the errorMessage property of the VerificationObject instance. The invalid class will automatically be applied if the verification fails, and will automatically be removed if the verification succeeds. Whether the component is currently invalid can be accessed through the invalid property.
 * @param {string} invalidClass - A class to apply if the input is invalid.
 * @returns {[React.MutableRefObject, VerificationObject]} A list. The first object is a ref that should be applied to the field. The second object is a VerificationObject instance.
 */
function useVerify(invalidClass) {
    // create ref for the field
    const fieldRef = useRef(null);
    const errorMessageRef = useRef("");
    const [errorMessage, setErrorMessage] = useState("");
    const [invalid, setInvalid] = useState(false);

    // create verification object
    // make sure it is memoized, otherwise it will be recalculated every time the page rerenders.
    const verificationObject = useMemo(() => new VerificationObject(fieldRef, errorMessage, setErrorMessage, errorMessageRef, invalid, setInvalid, invalidClass), [fieldRef, errorMessage, setErrorMessage, errorMessageRef, invalid, setInvalid, invalidClass]);

    // return the ref and the verification object
    return [fieldRef, verificationObject];
}

/**
 * Verifies all of the fields in the list. Returns a promise that resolves to a list. The first value of the list will be true if verification succeeded and false if it failed. The second value will be null if verification succeeded and a list of error messages if it failed.
 * @param {VerificationObject[]} verificationObjectList The list of verification objects to verify.
 * @returns {Promise<[boolean, string[]?]>} A promise that resolves to a list. The first value of the list will be true if verification succeeded and false if it failed. The second value will be null if verification succeeded and a list of error messages if it failed.
 */
async function verifyAll(verificationObjectList) {
    // start by assuming all fields are valid
    let allValid = true;
    let errorMessages = [];

    for (let v of verificationObjectList) {
        let valid = await v.verify();
        if (!valid) {
            allValid = false;
            errorMessages.push(v.errorMessageReferenced);
        }
    }

    return [allValid, allValid ? null : errorMessages];
}

/**
 * Returns a list of error messages from a list of verification objects. The list will be empty if there are no errors.
 * @param {VerificationObject[]} verificationObjects List of verification objects to watch.
 * @returns {string[]} A list of error messages from a list of verification objects. The list will be empty if there are no errors.
 */
function getErrorMessageList(verificationObjects) {
    let errorMessageList = [];
    for (let v of verificationObjects) {
        let message = v.errorMessageReferenced;
        if (message !== null) {
            errorMessageList.push(message);
        }
    }
    return errorMessageList;
}

export {useVerify, verifyAll, getErrorMessageList};