import { useContext } from 'react';
import Classes from './AvailabilityCard.module.scss';

import { AvailabilityContext } from './Subcomponents/AvailabilityContext';
import AvailabilityHeader from './Subcomponents/AvailabilityHeader';
import AvailabilityTimeColumn from './Subcomponents/AvailabilityTimeColumn';
import AvailabilityGrid from './Subcomponents/AvailabilityGrid';
import AvailabilityLegend from './Subcomponents/AvailabilityLegend';
import { getBubbleOpacity } from 'utilities/dataProcessing';

/**
 * Props:
 * - mode: "OVERVIEW" | "EDIT"
 * - data: GridData object
 * - onSelectedChange: function(selected) called when the selected bubbles change
 * - onActiveChange: function(active) called when the active bubble changes
 */
export default function AvailabilityCard(props) {

    // PROPS
    const { mode, data } = props; // should be "EDIT" or "OVERVIEW"

    const availabilityContext = useContext(AvailabilityContext);

    // DEBUG make raw data available to the window for debugging
    window.overlapData = data;

    if (!availabilityContext) {
        return (
            <div className={Classes.warningBox}>
                <h2>No AvailabilityContext Detected!</h2>
                <p>In the parent component please use the <code>AvailabilityContext.Provider</code> component and the <code>useCreateAvailabilityContext()</code> hook to create an availability context wrapper. You can then use the context functions to interact with and control the grid.</p>
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start'}}>
                <code style={{textAlign: 'left'}}>{`const availabilityContext = useCreateAvailabilityContext();`}</code>
                <br/>
                <code style={{textAlign: 'left'}}>{`return (`}</code>
                <code style={{textAlign: 'left'}}>&nbsp;&nbsp;&nbsp;&nbsp;{`<AvailabilityContext.Provider value={availabilityContext}>`}</code>
                <code style={{textAlign: 'left'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{`/**Page code **/`}</code>
                <code style={{textAlign: 'left'}}>&nbsp;&nbsp;&nbsp;&nbsp;{`</AvailabilityContext.Provider>`}</code>
                <code style={{textAlign: 'left'}}>{`);`}</code>
                </div>
            </div>
        )
    }

    return (
        <div className={Classes.availabilityCard}>
            <AvailabilityLegend mode={mode} maxOpacity={getBubbleOpacity(data.maxAvailability, data.maxAvailability, data.densityList)} maxAvailability={data.maxAvailability}/>
            <AvailabilityHeader headers={data.headers} timeColumnRef={availabilityContext.timeColumnRef}/>
            <div className={Classes.row}>
                <div className={Classes.timeColumn} ref={availabilityContext.timeColumnRef}>
                    <AvailabilityTimeColumn times={data.times}/>
                </div>
                <div className={`${Classes.grid} ${mode === "EDIT" ? Classes.edit : ''}`} ref={availabilityContext.gridRef}>
                    <AvailabilityGrid bubbles={data.bubbles}/>
                </div>
            </div>
        </div>
    )
}